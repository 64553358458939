import React from "react";
import { AiOutlineArrowRight } from "react-icons/ai";
const  ContainsArea = (props) => {
  const Arrow_down = "https://techrowth.s3.eu-north-1.amazonaws.com/images/IZB/arrow_down.png";

  return (
    <section
      className="page-contains-area"
      style={{ backgroundColor: "#FFF5E7" }}
    >
      <div className="container my-3">
        <div className="row">
          <div className="col-xl-12">
            <div className="page-contains-box">
              <div className="page-contains-btn">
                <ul className="navigation clearfix scroll-nav">
                  <li className="current">
                    <a href="#benefits">
                      <img src={Arrow_down} className="me-1" />
                      Benefits
                    </a>
                  </li>
               
                 
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContainsArea;
