import React, { useState } from "react";
import Breadcrumb from "../../Components/Breadcrumb/Breadcrumb";
import AllAcountsSection from "../../Components/AllAccountsSection/AllAcountsSection";


import { useEffect } from "react";

const FAQS = () => {
  const BackgroundBrundcrumb = "https://techrowth.s3.eu-north-1.amazonaws.com/images/IZB/BackgroundBrundcrumb.png";
const bgimg = "https://techrowth.s3.eu-north-1.amazonaws.com/images/IZB/IZB+Website+Skin/IZB+Save+Plus+Account/image_6.png";
const Faqbg = "https://techrowth.s3.eu-north-1.amazonaws.com/images/IZB/IZB+Website+Skin/Home_Page/FAQbg.png";
const horn = "https://techrowth.s3.eu-north-1.amazonaws.com/images/IZB/IZB+Website+Skin/FAQS/public-relation.png";
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Breadcrumb Heading="Feedback / Complaint" img={BackgroundBrundcrumb} />
      <div className="container">
        <div className="text-center my-5">
          <h1 >PROCEDURE FOR LODGEMENT OF COMPLAINTS/SUGGESTION</h1>
        
        </div>
 
       <h5>In our continuous endeavor to serve you better and with a view to resolve your grievance / complaint, if any, relating to the services provided by us, we advise that you can now lodge your letter outlining your grievance / complaint / suggestion in a box titled “Customer Complaints/Suggestion Box” kept in the banking hall or send it by mail to us.</h5><br/>
      <h5>Alternatively, you can directly approach the Branch Manager with your letter of grievance and we would ensure that your grievance is resolved and you would be another satisfied customer.</h5><br/>
      <h5>You are free to lodge your complaint/suggestion with our Head Office at the following address :</h5><br/>
      <h5>Office of the Assistant General Manager</h5>
      <h5>Indo Zambia Bank Ltd</h5>
      <h5>Head Office</h5>
      <h5>Plot 6907, Cairo Road</h5>
      <h5>P.O Box 35411</h5>
      <h5>Lusaka.</h5><br/>
      <h5>We are committed to continuously improve our services and we assure you that we would take all possible steps to resolve your grievance/complaint on TOP PRIORITY basis within a maximum period of seven (7) days from the date of receipt of your grievance complaint.</h5>
<br/>
<br/>
      
      </div>
   

      <AllAcountsSection />
    </>
  );
};

export default FAQS;
