import React from "react";
import Slide1 from "./Slide1";
import Slide2 from "./Slide2";
import Slider from "react-slick";


const IndoBankingSlider = () => {
  const ArrowLeft = "https://techrowth.s3.eu-north-1.amazonaws.com/images/IZB/IZB+Website+Skin/LeftArrow.png";
const ArrowRight = "https://techrowth.s3.eu-north-1.amazonaws.com/images/IZB/IZB+Website+Skin/RightArrow.png";
  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <img
        src={ArrowLeft}
        className={className}
        style={{
          ...style,
          display: "block",
          width: "50px",
          height: "50px",
          left: "40px",
          zIndex: 1,
        }}
        onClick={onClick}
      />
    );
  }
  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <img
        src={ArrowRight}
        className={className}
        style={{
          ...style,
          display: "block",
          width: "50px",
          height: "50px",
          right: "40px",
        }}
        onClick={onClick}
      />
    );
  }
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  return (
    <Slider {...settings}>
      <div>
        <Slide1 />
      </div>
      <div>
        <Slide2 />
      </div>
    </Slider>
  );
};

export default IndoBankingSlider;
