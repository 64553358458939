import React, { useState } from "react";
import { BsBank } from "react-icons/bs";
import { Link } from "react-router-dom";
import "../Hero/Hero.css";

import Slider from "react-slick";
const SavePlusAccountComponent = ({
  heading,
  subHeading,
  content,

  btnTxt,
  link,
  logo,
  showBtn,
}) => {
  const textColor = {
    color: "#949292",
  };

  const ArrowLeft = "https://techrowth.s3.eu-north-1.amazonaws.com/images/IZB/IZB+Website+Skin/LeftArrow.png";
  const ArrowRight = "https://techrowth.s3.eu-north-1.amazonaws.com/images/IZB/IZB+Website+Skin/RightArrow.png";
  const img1 = "https://techrowth.s3.eu-north-1.amazonaws.com/images/image.png";
const img2 = "https://techrowth.s3.eu-north-1.amazonaws.com/images/image2.png";
const img3 = "https://techrowth.s3.eu-north-1.amazonaws.com/images/image3.png";
    function SamplePrevArrow(props) {
      const { className, style, onClick } = props;
      return (
        <img
          src={ArrowLeft}
          className={className}
          style={{
            ...style,
            display: "block",
            width: "50px",
            height: "50px",
            left: "40px",
            zIndex: 1,
          }}
          onClick={onClick}
        />
      );
    }
    function SampleNextArrow(props) {
      const { className, style, onClick } = props;
      return (
        <img
          src={ArrowRight}
          className={className}
          style={{
            ...style,
            display: "block",
            width: "50px",
            height: "50px",
            right: "40px",
          }}
          onClick={onClick}
        />
      );
    }
    const settings = {
      autoplay: true,
      autoplaySpeed: 3000,
      speed: 300,
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
    };
  
  return (
    <section className="overview-area SaveAccountSection">
      <div className="container">
        <div className="row">
          <div className="col-md-5 col-xl-5">
            <div className="overview-content-box-one">
            <Slider {...settings}>
      <div>
      <img src={img1} className="ps-0" width="100%" alt="" />

      </div>
      <div>
      <img src={img2} className="ps-0" width="100%" alt="" />

      </div>
      <div>
      <img src={img3} className="ps-0" width="100%" alt="" />

      </div>
    </Slider>
            </div>
          </div>
          <div className="col-xl col-md ">
            <div className="overview-content-box-two h-100 d-flex flex-column justify-content-center">
              <div className="inner-title">
                <h5>{subHeading}</h5>
                <h2 style={{ color: "#484848" }}>{heading}</h2>
              </div>
              <div className="text">
                <p style={textColor}>{content}</p>
              </div>
              {showBtn ?? (
                <Link to="/ContactForm">
                  <button
                    className="btn btn-danger text-white fs-5"
                    style={{ backgroundColor: "#9E1B1E" }}
                  >
                    {logo ?? (
                      <BsBank className="me-4 mb-1 text-white" size={25} />
                    )}
                    {btnTxt}
                  </button>
                </Link>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SavePlusAccountComponent;
