import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Modal from 'react-modal';
import DoMore from '../../Components/DoMore/DoMore'
import Hero from '../../Components/Hero/Hero'
import EmptySpace from '../../Components/Hero/EmptySpace'
import AtmLocatinos from '../../Components/AtmLocations/AtmLocatinos'
import SavePlusAccount from '../../Components/SavePlusAccount/SavePlusAccountComponent'
import AllAcountsSection from '../../Components/AllAccountsSection/AllAcountsSection'
import IndoBankingSlider from '../../Components/IndoBankingSlider/IndoBankingSlider'
import AdvisorsVideo from "../../Components/AdvisorsVideo/AdvisorsVideo";
import AllAccounts from "../../Components/AllAccounts/AllAccounts";
import ContactForm from "../../Components/ContactForm/ContactForm";
import CustomerCare from "../../Components/CustomerCare/CustomerCare";
import OurStores from "../../Components/OurStories/OurStores";
import Slide1 from '../../Components/IndoBankingSlider/Slide2'
import NavAccordion from '../../Components/Navbar/NavAccordion'
import './Homepage.css';
const Homepage = () => {
  const img = "https://techrowth.s3.eu-north-1.amazonaws.com/images/IZB/IZB+Website+Skin/Home_Page/Image_3Short.png"
const img1 = "https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/20x4-Invitation-to-Tender-(1).jpg"
const img2 = "https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/clear.svg"

 const accountContent = "Exclusively designed savings account to inculcate savings habits in parents and guardians for their children. This savings account offers attractive interest rates and other benefits to realize your savings goals with other value added features such as IZB Contactless Visa Card, SMS Alert, Mobile and Internet Banking facility."
 const [isImageModalOpen, setImageModalOpen] = useState(true);

 const style = {}
 useEffect(() => {
  window.scrollTo(0, 0)
}, [])
 return (
    <React.Fragment>
    <Hero/>
    <EmptySpace/>
    <DoMore/>
    {/* <NavAccordion/> */}
    <AtmLocatinos/>
    <SavePlusAccount heading="Save Plus Account" subHeading="Secure your Childs Futures with our" content={accountContent} img={img} btnTxt="Open an Account" link="/SavePlusAccount"/>
    <IndoBankingSlider/>
    <AdvisorsVideo />
      <AllAccounts />
      <ContactForm />
      <CustomerCare />
      <OurStores />

      {/* <Modal
        isOpen={isImageModalOpen}
        onRequestClose={() => setImageModalOpen(false)}
        contentLabel="Image Popup"
        style={{
          overlay: {
            zIndex: 1000, // Adjust this value as needed
          },
        }}
      >
        <button
          className="close-button" // You can style this button with CSS
          onClick={() => setImageModalOpen(false)}
        
        >
          
            <img className='thejas' alt="" src={img2} />
            
        </button>
        <Link to="/Tenderforfitoutworks">
        <img src={img1} alt="Popup" style={{ zIndex: 1001 }} />
        </Link>
      </Modal> */}
      
    </React.Fragment>
  )
}

export default Homepage