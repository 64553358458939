


import React,{useRef} from "react";
import { useEffect } from "react";
import Breadcrumb1 from "../../Components/Breadcrumb1/Breadcrumb1";
import AllAcountsSection from "../../Components/AllAccountsSection/AllAcountsSection";


// import BackgroundBrundcrumb from "../../assets/images/IZB/BackgroundBrundcrumb.png";
import {
  AiOutlineArrowLeft,
  AiOutlineArrowRight,
  AiOutlineShareAlt,
} from "react-icons/ai";

import { Link } from "react-router-dom";
import { CiClock1 } from "react-icons/ci";
import { BiMessageDetail } from "react-icons/bi";
const TenderInvitations = () => {
const BackgroundBrundcrumb = "https://techrowth.s3.eu-north-1.amazonaws.com/images/IZB/BackgroundBrundcrumb.png";
const storie1 = "https://techrowth.s3.eu-north-1.amazonaws.com/images/11.png";
const storie2 = "https://techrowth.s3.eu-north-1.amazonaws.com/images/12.png";
const storie3 = "https://techrowth.s3.eu-north-1.amazonaws.com/images/12.png";
const storie51 = "https://techrowth.s3.eu-north-1.amazonaws.com/1312.png";
const storie11 = "https://techrowth.s3.eu-north-1.amazonaws.com/images/IZB-TENDER-BRANCH-FITOUT-PRESS-AD-25X4-RVS-V2.png";
const storie4 = "https://techrowth.s3.eu-north-1.amazonaws.com/images/13.png";
const storie5 = "https://techrowth.s3.eu-north-1.amazonaws.com/images/14.png";
const arrowwreport = "https://techrowth.s3.eu-north-1.amazonaws.com/report2023.png";
const arroww = "https://techrowth.s3.eu-north-1.amazonaws.com/images/chevron_right.svg";
const storie5551 = "https://techrowth.s3.eu-north-1.amazonaws.com/images/Tendorforconsultancy1.png";
const storie4111 = "https://techrowth.s3.eu-north-1.amazonaws.com/images/New/file123.png";
const storie21 = "https://techrowth.s3.eu-north-1.amazonaws.com/q3twge.jpg";
const page1 = "https://techrowth.s3.eu-north-1.amazonaws.com/Propertiesforsale.png";
const arrowwreport1 = "https://techrowth.s3.eu-north-1.amazonaws.com/izb/vendor2.jpg";
const arrowwreport2 = "https://techrowth.s3.eu-north-1.amazonaws.com/izb/vendor1.jpg";
const arrowwreport3 = "https://techrowth.s3.eu-north-1.amazonaws.com/invitation3.jpg";
const arrowwreport4 = "https://techrowth.s3.eu-north-1.amazonaws.com/invitation4.jpg";
const arrowreport5 = "https://techrowth.s3.eu-north-1.amazonaws.com/izb/vendor.pdf";


const arrowreport6 = "https://techrowth.s3.eu-north-1.amazonaws.com/izb/3.jpg";
const arrowreport7 = "https://techrowth.s3.eu-north-1.amazonaws.com/izb/vendor4.jpg";


  const sliderRef = useRef(null);
  console.log(sliderRef.current);
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>


    
<Breadcrumb1 Heading="Tender Invitation For Recruitment & Management Of Agents, Services Of IZB Wallet Banking" Heading1 ="Indo Zambia Bank  |  July, 2024" img={BackgroundBrundcrumb} />

<div className="text-center" style={{ margin: "3rem" }}>
  <div className="">
  <div> <img src={arrowwreport2} className="img-fluid image-row-report" alt="Image 1" style={{ flex: "1", order: "1",margin: "0 0.5rem"}} /></div>
 
    <div> <img src={arrowwreport1} className="img-fluid image-row-report" alt="Image 2" style={{ flex: "1", order: "2", margin: "0 0.5rem" }} /></div>
   <div> <img src={arrowreport6} className="img-fluid image-row-report" alt="Image 2" style={{ flex: "1", order: "2",margin: "0 0.5rem"}} /></div>
   
    <div><img src={arrowreport7} className="img-fluid image-row-report" alt="Image 3" style={{ flex: "1", order: "3",margin: "0 0.5rem" }} /></div>
    {/* <div><img src={arrowwreport4} className="img-fluid" alt="Image 4" style={{ flex: "1",order: "4", margin: "0 0.5rem" }} /></div> */} 
   
  </div> 
  
  <p className="mt-4">Indo Zambia Bank Limited has set aside funds to outsource the management of agents and targeted acquisition of customers. The Bank now invites sealed bids from eligible bidders to recruit, onboard, Brand and manage agents. The vendors will also sale the Indo Wallet and the Indo Eezy account</p>
  <a href="https://techrowth.s3.eu-north-1.amazonaws.com/izb/vendor.pdf">
    <h5 style={{ fontWeight: "500", color: "#9E1B1E", textAlign: "center" }}>
      <br/>
    Click here to download the Pdf
    </h5>
  </a>
  
</div>
      <section>
      <div className="container">
        <div className="row">
          <div className="container">
            <div className="heading ">
              <h2 className="text-left" style={{color:"#9E1B1E",fontWeight:"450"}}>More to Explore</h2>
             
            </div>
            <div
              className="py-4"
              style={{ float: "right"}}
            >
         
            </div>
          </div>
          <div className="col-xl-4 col-lg-4">
            <div
              className="single-blog-style1 wow fadeInUp"
              data-wow-delay="00ms"
              data-wow-duration="1500ms"
            >
                <a href="/Workerscopensation">
              <div className="img-holder">
                <div className="inner">
                  <img src={storie21} alt="" />
           
                    <div className="overlay-icon"></div>
              
                </div>
              </div>
              </a>










              
              <div className="text-holder">
                <h3 className="blog-title mt-4">
             
                  <a>IZB - Workers Compensation Fund Control Board Zambia E-Workers Smart Pay Launch
</a><br/>

                  <span style={{fontSize: "15px", color: "gray"}}>October 20, 2023</span>
                </h3>
                <p className="" style={{marginBottom:"0rem"}}>
                Indo Zambia Bank is proud to announce the launch of the provision of the E-Workers on our INDO Internet Banking platform .This INDO Net Banking platform ...
                </p><br/>
                <Link
                to="/Workerscopensation">
             <h5 style={{fontWeight:"500", color:"#9E1B1E", textAlign:"right"}}>Read More <img src={arroww} alt="" width="8%"/></h5></Link>
             <hr style={{ color:"#9E1B1E", margin:"0rem 0"}}/>
            
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-lg-4">
            <div
              className="single-blog-style1 wow fadeInUp"
              data-wow-delay="00ms"
              data-wow-duration="1500ms"
            >
           <a href="/Indopremierbanking">
              <div className="img-holder">
                <div className="inner">
                  <img src={storie5} alt="" />
            
                    <div className="overlay-icon"></div>
                
                </div>
              </div>
              </a>
              <div className="text-holder">
                <h3 className="blog-title mt-4">
                  <a>Introducing Indo Premier Banking Suite - Tailored To Meet Your Banking Needs</a><br/>
                  <span style={{fontSize: "15px", color: "gray"}}>July 15, 2023</span>
                </h3>
                <p className="" style={{marginBottom:"0rem"}}>
                We cordially invite you to the Indo Premier Banking Suite. 
At Indo Zambia Bank (IZB), we understand and appreciate that you, our valued customers ...
                </p><br/>
                <Link
                to="/Indopremierbanking">
             <h5 style={{fontWeight:"500", color:"#9E1B1E", textAlign:"right"}}>Read More <img src={arroww} alt="" width="8%"/></h5></Link>
             <hr style={{ color:"#9E1B1E", margin:"0rem 0"}}/>
            
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-lg-4">
            <div
              className="single-blog-style1 wow fadeInUp"
              data-wow-delay="00ms"
              data-wow-duration="1500ms"
            >
             <a href="/Officiallaunchofizbhq">
              <div className="img-holder">
                <div className="inner">
                  <img src={storie2} alt="" />
           
                    <div className="overlay-icon"></div>
              
                </div>
              </div>
              </a>
              <div className="text-holder">
                <h3 className="blog-title mt-4">
                  <a>The Official Launch Of The New Indo House Corporate Head Office</a><br/>
                  <span style={{fontSize: "15px", color: "gray"}}>July 6, 2023</span>
                </h3>
                <p className="" style={{marginBottom:"0rem"}}>
                The Board, Management and Staff of the Indo Zambia Bank wish to extend their sincere gratitude to the President of the Republic of Zambia ...
                </p><br/>
                <Link
                to="/Officiallaunchofizbhq">
             <h5 style={{fontWeight:"500", color:"#9E1B1E", textAlign:"right"}}>Read More <img src={arroww} alt="" width="8%"/></h5></Link>
             <hr style={{ color:"#9E1B1E", margin:"0rem 0"}}/>
            
              </div>
            </div>
          </div>
  
         
        </div>
      </div>
    </section>

    
      <AllAcountsSection />
    </>
  );
};

export default TenderInvitations;
