import React from "react";
import { Link } from "react-router-dom";

import MobileStoreButton from "react-mobile-store-button";
import "../Hero/Hero.css";

const Slide1 = () => {
  const IndoBanking = "https://techrowth.s3.eu-north-1.amazonaws.com/images/IZB/IZB+Website+Skin/Home_Page/IndoBanking1.png";
const img1 = "https://techrowth.s3.eu-north-1.amazonaws.com/images/IZB/IZB+Website+Skin/Home_Page/Icons/24-7.png";
const Logo = "https://techrowth.s3.eu-north-1.amazonaws.com/images/New/Group+402.png";
const Logo1 = "https://techrowth.s3.eu-north-1.amazonaws.com/images/New/Group+403.png";
const MobileBanking = "https://techrowth.s3.eu-north-1.amazonaws.com/images/IZB/MobileBanking.jpg";
  const textColor = {
    color: "#9E1B1E",
  };
  const iosUrl =
    "https://apps.apple.com/us/app/izb-retail-banking/id1507943733";
  const androidUrl =
    "https://play.google.com/store/apps/details?id=com.izb&hl=en&gl=US";

  return (
    <section className="overview-area">
      <img src={MobileBanking} className="d-md-none d-sm-block" alt="" />
      <div
        className="container p-5 d-none d-sm-block"
        style={{ backgroundColor: "#FFF5E7" }}
      >
        <div className="row">
          <div className="col-xl-6 col-md-6">
            <div className="overview-content-box-two h-100 d-flex flex-column d-none d-sm-block">
              <div className="inner-title ps-5">
                <h2 style={textColor}>INDO MOBILE BANKING</h2>
                <div className="d-flex align-items-center gap-3 my-3">
                  <img src={img1} className="" width={50} alt="" />
                  <h5 style={textColor}>Anytime, Anywhere.</h5>
                </div>
                <p>
                  Indo Mobile Banking gives you access to your account on your
                  mobile/tab device. Perform your banking tasks from the palm of
                  your hand, from anywhere and at anytime!
                </p>
                <h3 className="py-3" style={textColor}>
                  USSD Dial *232#
                </h3>
              </div>
              <div
                className="d-flex align-items-center "
                style={{ paddingLeft: "35px" }}
              >
            <Link to={androidUrl} target="_blank" >
                    <img src={Logo} width={150} style={{textDecoration:"none", border:"none"}} />
                  </Link>
                    <Link to={iosUrl} target="_blank">
                    <img src={Logo1} width={150} />
                  </Link>
              </div>
            </div>
          </div>
          <div className=" col-md-6 col-xl-6 position-relative">
            <div className="overview-content-box-one position-relative">
              <img
                src={IndoBanking}
                className="position-absolute SliderImg d-none d-sm-block"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Slide1;
