import React, { useEffect } from "react";
import Breadcrumb from "../../Components/Breadcrumb/Breadcrumb";
import AllAcountsSection from "../../Components/AllAccountsSection/AllAcountsSection";
import { AiFillCaretRight } from "react-icons/ai";
import SavePlusAccountComponent from "../../Components/SavePlusAccount/SavePlusAccountComponent1";
import ContainsArea from "../../Components/ContainsArea/ContainsArea";
import AdvisorsVideo from "../../Components/AdvisorsVideo/AdvisorsVideo1";
import AllAccounts from "../../Components/AllAccounts/AllAccounts";
import ContactForm from "../../Components/ContactForm/ContactForm";
import CustomerCare from "../../Components/CustomerCare/CustomerCare";
import OurStores from "../../Components/OurStories/OurStores";
import "./PremierBanking.css";

const PremierBanking = () => {
const percent = "https://techrowth.s3.eu-north-1.amazonaws.com/images/New/1.png";
const percent1 = "https://techrowth.s3.eu-north-1.amazonaws.com/images/New/2.png";
const percent2 = "https://techrowth.s3.eu-north-1.amazonaws.com/images/New/3.png";
const percent3 = "https://techrowth.s3.eu-north-1.amazonaws.com/images/New/4.png";
const percent4 = "https://techrowth.s3.eu-north-1.amazonaws.com/images/New/5.png";
const percent5 = "https://techrowth.s3.eu-north-1.amazonaws.com/images/New/6.png";
const percent6 = "https://techrowth.s3.eu-north-1.amazonaws.com/images/New/7.png";
const percent7 = "https://techrowth.s3.eu-north-1.amazonaws.com/images/New/10.svg";
const percent8 = "https://techrowth.s3.eu-north-1.amazonaws.com/images/New/9.svg";
const percent9 = "https://techrowth.s3.eu-north-1.amazonaws.com/images/New/10.svg";
const percent61 = "https://techrowth.s3.eu-north-1.amazonaws.com/images/New/online-payment+1.png";





const BackgroundBrundcrumb = "https://techrowth.s3.eu-north-1.amazonaws.com/images/New/Banner_image.png";

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const accountContent =
    "At Indo Zambia Bank (IZB), we understand and appreciate that you, our valued customers, require banking solutions tailored to your unique needs and supported by a personalized customer experience. At our Indo Premier Banking Suite, you will enjoy exclusive benefits that meet your needs.";
  return (
    <>
      <Breadcrumb Heading="Indo Premier Banking" img={BackgroundBrundcrumb} />
      <SavePlusAccountComponent
        heading="Indo Premier Banking"
        subHeading="Know About"
        content={accountContent}
        btnTxt="Open an Account"
      
      />
      {/* <ContainsArea /> */}
      <section
        id="benefits"
        class="benefits-style2-area"
        className="choose-style1-area"
      >
        <div className="container pt-4">
          <div className="text-center my-5">
            <h1 style={{ color: "#484848" }}>
            Our Indo Premier Banking A/C Benefits
            </h1>
            <p style={{ color: "#484848" }}>
            Your Gateway to Infinite Possibilities
            </p>
          </div>
          <div className="d-flex justify-content-center">
            <ul className="row choose-style1__content">
              <li className="col-xl-3 col-lg-3 single-choose-style1-colum text-center">
                <div className="single-choose-style1">
                  <div className="icon">
                    <img src={percent} alt="" />
                  </div>
                  <div className="text">
                    <h5>Exclusive access to the new state of the art Indo Premier Banking Suite</h5>
                  </div>
                </div>
              </li>
       
       
              <li className="col-xl-3 col-lg-3 single-choose-style1-colum text-center">
                <div className="single-choose-style1">
                  <div className="icon">
                    <img src={percent2} alt="" />
                  </div>
                  <div className="text">
                    <h5>A Dedicated Private Banker to address all your banking needs</h5>
                  </div>
                </div>
              </li>
              <li className="col-xl-3 col-lg-3 single-choose-style1-colum text-center">
                <div className="single-choose-style1">
                  <div className="icon">
                    <img src={percent5} alt="" />
                  </div>
                  <div className="text">
                    <h5>Unlimited free ATM withdrawals on IZB ATMs</h5>
                  </div>
                </div>
              </li>
              <li className="col-xl-3 col-lg-3 single-choose-style1-colum text-center">
                <div className="single-choose-style1">
                  <div className="icon">
                    <img src={percent3} alt="" />
                  </div>
                  <div className="text">
                    <h5>A free personalized VISA Infinite Debit Card</h5>
                  </div>
                </div>
              </li>
              <li className="col-xl-3 col-lg-3 single-choose-style1-colum text-center">
                <div className="single-choose-style1">
                  <div className="icon">
                    <img src={percent4} alt="" />
                  </div>
                  <div className="text">
                    <h5>
                    24/7 access to digital platforms via Indo Net Banking, IZB Retail Mobile Banking App
                    </h5>
                  </div>
                </div>
              </li>
              <li className="col-xl-3 col-lg-3 single-choose-style1-colum text-center">
                <div className="single-choose-style1">
                  <div className="icon">
                    <img src={percent1} alt="" />
                  </div>
                  <div className="text">
                    <h5>Access to Credit facilities such as Overdraft, Personal Loan, Indo Home Loan, Indo Vehicle Loan and Many more.</h5>
                  </div>
                </div>
              </li>
       
              <li className="col-xl-3 col-lg-3 single-choose-style1-colum text-center">
                <div className="single-choose-style1">
                  <div className="icon">
                    <img src={percent6} alt="" />
                  </div>
                  <div className="text">
                    <h5>
                    Waiver on monthly account maintenance fees if minimum deposit balance of K200,000 is maintained throughout the month.
                    </h5>
                  </div>
                </div>
              </li>
              <li className="col-xl-3 col-lg-3 single-choose-style1-colum text-center">
                <div className="single-choose-style1">
                  <div className="icon">
                    <img src={percent61} alt="" />
                  </div>
                  <div className="text">
                    <h5>
                    VISA embedded benefits
                    </h5>
                  </div>
                </div>
              </li>
              <li className="col-xl-3 col-lg-3 single-choose-style1-colum text-center">
  
              </li>
            </ul>
          </div>
        </div>
      </section>










    <div className="container">
      <h6 style={{color:"#767676"}}>For more information, </h6>
      <h6 style={{color:"#767676"}}>Please visit our Indo Premier Banking Suite located at</h6>

<div className="d-flex" style={{padding:"2vw", flexDirection:"row"}}>
      <img src={percent7} style={{marginRight:"2vw", width:"2rem"}} alt="" /> 
      <h6>Indo House on Plot no. 1213/1214 Corner of Great East Road and 
Addis Ababa Road, Rhodes Park, Lusaka.</h6>
</div>
<h6 style={{color:"#767676"}}>Or Please visit our Indo Premier Banking Suite located in Kitwe at </h6>
<div className="d-flex" style={{padding:"2vw", flexDirection:"row"}}>
      <img src={percent7} style={{marginRight:"2vw", width:"2rem"}} alt="" /> 
      <h6>ECL Mall, Shop number 21, Freedom Way, Kitwe.</h6>
</div>

<hr style={{color:"#767676", marginBottom:"3rem"}}/>
<h6 style={{color:"#767676"}}>Alternatively,  </h6>
      <h6 style={{color:"#767676"}}>Please contact us via any of the following channels:</h6><br/>
      <h6 style={{color:"#767676"}}>Indo Premier Suite - Lusaka</h6>
     


     <div className="d-flex flex-column flex-md-row" style={{ padding: "2vw", gap: "1rem" }}>
     <div className="d-flex mobile-width" style={{ flexDirection:"row"}}>
         <img src={percent8} style={{marginRight:"1vw", width: "1rem" }} alt="" />
         <h6 style={{textAlign:"left"}}>Tel: +260 211 220051/220052/220061</h6>
       </div>
       <div className="d-flex mobile-width" style={{ flexDirection:"row"}}>
         <img src={percent8} style={{marginRight:"1vw", width: "1rem" }} alt="" />
         <h6 style={{textAlign:"left"}}>Email: mpremier@izb.co.zm </h6>
       </div>
     </div>
     {/* <div className="d-flex flex-column flex-md-row test" style={{ padding: "0vw 2vw", gap: "1rem" }}>
     <div className="d-flex mobile-width" style={{ flexDirection:"row"}}>
         <img src={percent9} style={{marginRight:"1vw", width: "1rem" }} alt="" />
         <h6 style={{textAlign:"left"}}>Email: mpremier@izb.co.zm</h6>
       </div>
       <div className="d-flex mobile-width" style={{ flexDirection:"row"}}>
         <img src={percent9} style={{marginRight:"1vw", width: "1rem" }} alt="" />
         <h6 style={{textAlign:"left"}}>IZB Call Centre Email: callcenter@izb.co.zm </h6>
       </div>
     </div>
      */}

     <h6 style={{color:"#767676"}}>Indo Premier Banking Suite - ECL Kitwe </h6>
     


     <div className="d-flex flex-column flex-md-row" style={{ padding: "2vw", gap: "1rem" }}>
     <div className="d-flex mobile-width" style={{ flexDirection:"row"}}>
         <img src={percent8} style={{marginRight:"1vw", width: "1rem" }} alt="" />
         <h6 style={{textAlign:"left"}}>Tel: +260 212 224579/224580</h6>
       </div>
       <div className="d-flex mobile-width" style={{ flexDirection:"row"}}>
         <img src={percent8} style={{marginRight:"1vw", width: "1rem" }} alt="" />
         <h6 style={{textAlign:"left"}}>Email: mpremierecl@izb.co.zm</h6>
       </div>
     </div>
     <div className="d-flex flex-column flex-md-row test" style={{ padding: "0vw 2vw", gap: "1rem" }}>
     <div className="d-flex mobile-width" style={{ flexDirection:"row"}}>
         <img src={percent9} style={{marginRight:"1vw", width: "1rem" }} alt="" />
         <h6 style={{textAlign:"left"}}>IZB Call Centre (Toll Free line): 8002</h6>
       </div>
       <div className="d-flex mobile-width" style={{ flexDirection:"row"}}>
         <img src={percent9} style={{marginRight:"1vw", width: "1rem" }} alt="" />
         <h6 style={{textAlign:"left"}}>IZB Call Centre Email: callcenter@izb.co.zm </h6>
       </div>
     </div> 
     





{/* <div className="d-flex flex-column flex-md-row" style={{ padding: "2vw", gap: "1rem" }}>
<div className="d-flex mobile-width" style={{ flexDirection:"row"}}>
    <img src={percent8} style={{marginRight:"1vw", width: "1rem" }} alt="" />
    <h6 style={{textAlign:"left"}}>IZB Call Centre (Toll Free line): 8002</h6>
  </div>
  <div className="d-flex mobile-width" style={{ flexDirection:"row"}}>
    <img src={percent8} style={{marginRight:"1vw", width: "1rem" }} alt="" />
    <h6 style={{textAlign:"left"}}>IZB Call Centre Email: callcenter@izb.co.zm</h6>
  </div>
</div> */}
{/* <div className="d-flex flex-column flex-md-row test" style={{ padding: "0vw 2vw", gap: "1rem" }}>
<div className="d-flex mobile-width" style={{ flexDirection:"row"}}>
    <img src={percent9} style={{marginRight:"1vw", width: "1rem" }} alt="" />
    <h6 style={{textAlign:"left"}}>Email: mpremier@izb.co.zm</h6>
  </div>
  <div className="d-flex mobile-width" style={{ flexDirection:"row"}}>
    <img src={percent9} style={{marginRight:"1vw", width: "1rem" }} alt="" />
    <h6 style={{textAlign:"left"}}>IZB Call Centre Email: callcenter@izb.co.zm </h6>
  </div>
</div> */}













    </div>

      <AdvisorsVideo />
      <AllAccounts />
      <ContactForm />
      <CustomerCare />
      <OurStores />
    </>
  );
};

export default PremierBanking;
