import React from "react";
import Breadcrumb from "../../Components/Breadcrumb/Breadcrumb";

import { useEffect } from "react";
import AllAcountsSection from "../../Components/AllAccountsSection/AllAcountsSection";

const IndoFastServe = () => {
  const img = "https://techrowth.s3.eu-north-1.amazonaws.com/images/IZB/IZB+Website+Skin/Personal+Acounts/21.jpg";
const bgimg = "https://techrowth.s3.eu-north-1.amazonaws.com/images/IZB/IZB+Website+Skin/Indo+Fast+Serve/3459071.jpg";
const fastServe = "https://techrowth.s3.eu-north-1.amazonaws.com/images/IZB/IZB+Website+Skin/Indo+Fast+Serve/indo-fast-serve_png.png";
const cashWithdrawal = "https://techrowth.s3.eu-north-1.amazonaws.com/images/IZB/IZB+Website+Skin/Indo+Fast+Serve/Icon/money-withdrawal.png";
const CashDeposit = "https://techrowth.s3.eu-north-1.amazonaws.com/images/IZB/IZB+Website+Skin/Indo+Fast+Serve/Icon/deposit.png";
const BalanceEnquiry = "https://techrowth.s3.eu-north-1.amazonaws.com/images/IZB/IZB+Website+Skin/Indo+Fast+Serve/Icon/calculator.png";
const BackgroundBrundcrumb = "https://techrowth.s3.eu-north-1.amazonaws.com/images/IZB/IZB+Website+Skin/Indo+Fast+Serve/Image_1.png";
const school = "https://techrowth.s3.eu-north-1.amazonaws.com/images/IZB/IZB+Website+Skin/Icon_Create.png";
const booth = "https://techrowth.s3.eu-north-1.amazonaws.com/images/IZB/IZB+Website+Skin/Indo+Fast+Serve/booth-01+1.png";
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Breadcrumb Heading="Indo Fast Serve" img={BackgroundBrundcrumb} />
      <section className="overview-area">
        <div className="container">
          <div className="row">
            <div className=" col-md-4 col-xl-4">
              <div className="overview-content-box-one">
                <img src={img} width="100%" alt="" />
              </div>
            </div>
            <div className="col-xl-6 col-md-6 ">
              <div className="overview-content-box-two h-100 d-flex flex-column justify-content-center">
                <div className="inner-title">
                  <h5>Know More</h5>
                  <h2>Indo Fast Serve</h2>
                </div>
                <div className="text">
                  <p>
                    Indo Fast Serve Agency Banking gives you the convenience to
                    access banking services near you. Transfer money, deposit or
                    withdraw cash and much more by visiting your nearest IZB
                    Fast Serve Agent!
                  </p>

                  <p>
                    • Easy to use - Just walk to the nearest IZB Fast Serve
                    Agent.
                    <br /> • Time-Saving - No queues to know account activity or
                    fund transfers <br />• Secure – Confirm all transactions
                    with a 4-digit One Time Password
                    <br /> • Convenient – Access banking services near you.
                  </p>
                </div>
                {/* <button className="btn btn-danger w-50 text-white fs-5" style={{backgroundColor:"#9E1B1E"}}>
                <BsBank className="me-4 mb-1 text-white" size={25}/>
                Open Your Account
              </button> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        id="eligibility"
        class="eligibility-area"
        style={{ backgroundColor: "#FFF5E7" }}
      >
        <div className="container-fluid">
          <div className="sec-title text-center">
            <h3>Below are the basics on how to become and Indo fast serve agent:
</h3>
            {/* <div className="sub-title">
              <p>Basic documents required for opening a savings account.</p>
            </div> */}
          </div>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-12 col-lg-12">
                {/*Start Single Documents Box*/}
                <div
                  className="single-documents-box h-100"
                  style={{ backgroundColor: "#9E1B1E" }}
                >
                  <ul>
                    <li>
                      <div className="inner-title">
                       
                        <h2 className="text-white">
                        Individuals Agents:
                        </h2>
                      </div>
                    </li><br/>
                    <li>
                      <div className="inner-title">
                        <p className="text-white">
                        Fill in an individual Indo Zambia Bank account form (with supporting KYC) :

                        </p>
                      </div>
                    </li>
                    
                    <li>
                      <div className="inner-title">
                        <p className="text-white" style={{marginLeft:"1rem", textAlign:"left"}}>
                        ▪ Two passport sized photographs
                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="inner-title">
                        <p className="text-white" style={{marginLeft:"1rem", textAlign:"left"}}>
                        ▪ Copy of National Registration Card, Valid Driver’s License and Valid Passport in case of Foreign Nationals

                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="inner-title">
                        <p className="text-white" style={{marginLeft:"1rem", textAlign:"left"}}>
                        ▪ Valid Residency or Investments Permit for Foreign Nationals

                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="inner-title">
                        <p className="text-white" style={{marginLeft:"1rem", textAlign:"left"}}>
                        ▪ Proof of residence i.e. latest utility bill e.g. Water, Electricity,
Telephone etc and confirmation from introducer

                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="inner-title">
                        <p className="text-white" style={{marginLeft:"1rem", textAlign:"left"}}>
                        ▪ Introduction by Employers or Reputable Organization and Existing Account holder

                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="inner-title">
                        <p className="text-white" style={{marginLeft:"1rem", textAlign:"left"}}>
                        ▪ TPIN
                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="inner-title">
                        <p className="text-white">
                        Fill in an Agency Banking Application form

                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="inner-title">
                        <p className="text-white">
                        Read and sign off the terms and conditions form

                        </p>
                      </div>
                    </li><br/>
                    <li>
                      <div className="inner-title">
                        <p className="text-white">
                        Requirements:
                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="inner-title">
                        <p className="text-white" style={{marginLeft:"1rem", textAlign:"left"}}>
                        ▪ Mobile phone

                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="inner-title">
                        <p className="text-white" style={{marginLeft:"1rem", textAlign:"left"}}>
                        ▪ K500 float
                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="inner-title">
                        <p className="text-white" style={{marginLeft:"1rem", textAlign:"left"}}>
                        ▪ Inspection of working premises

                        </p>
                      </div>
                    </li><br/><br/>
                    <li>
                      <div className="inner-title">
                       
                        <h2 className="text-white">
                        Corporate Agent:

                        </h2>
                      </div>
                    </li><br/>
                    <li>
                      <div className="inner-title">
                        <p className="text-white">
                        Fill in an corporate Indo Zambia Bank account form (with supporting KYC) :
                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="inner-title">
                        <p className="text-white" style={{marginLeft:"1rem", textAlign:"left"}}>
                        ▪ Certificate of registration (Enterprise, churches etc.) or Certificate of Incorporation (Co.)

                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="inner-title">
                        <p className="text-white" style={{marginLeft:"1rem", textAlign:"left"}}>
                        ▪ Latest Form 3 From Pacra

                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="inner-title">
                        <p className="text-white" style={{marginLeft:"1rem", textAlign:"left"}}>
                        ▪ Articles of Association (Company), Constitutions or By-laws(NGOs, Churches etc.)

                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="inner-title">
                        <p className="text-white" style={{marginLeft:"1rem", textAlign:"left"}}>
                        ▪ Resolution of Board of Director to open Account (company) and Extract of Minutes (NGOs, churches, etc)

                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="inner-title">
                        <p className="text-white" style={{marginLeft:"1rem", textAlign:"left"}}>
                        ▪ Share Capital Certificate (Company)

                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="inner-title">
                        <p className="text-white" style={{marginLeft:"1rem", textAlign:"left"}}>
                        ▪ Identification document of the Directors passport size photographs, proof of residence and copy of NRC/valid passport for each
Introductory letter from a good current account holder which has been in operation for six months

                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="inner-title">
                        <p className="text-white" style={{marginLeft:"1rem", textAlign:"left"}}>
                        ▪ Copy of latest annual Returns to the Registrar of Companies
Utility bill Water, Electricity, Telephone, etc. in the name of the company of Lease Agreement on the physical premises of the company.

                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="inner-title">
                        <p className="text-white" style={{marginLeft:"1rem", textAlign:"left"}}>
                        ▪ Resident Permit for foreign nationals or Investment permit

                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="inner-title">
                        <p className="text-white" style={{marginLeft:"1rem", textAlign:"left"}}>
                        ▪ TPIN Certificate

                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="inner-title">
                        <p className="text-white" >
                       Fill in an Agency Banking Application form

                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="inner-title">
                        <p className="text-white">
                       Read and sign off the terms and conditions form

                        </p>
                      </div>
                    </li><br/>
                    <li>
                      <div className="inner-title">
                        <p className="text-white">
                        Requirements :
                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="inner-title">
                        <p className="text-white" style={{marginLeft:"1rem", textAlign:"left"}}>
                        ▪ Mobile phone
                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="inner-title">
                        <p className="text-white" style={{marginLeft:"1rem", textAlign:"left"}}>
                        ▪ K500 float
                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="inner-title">
                        <p className="text-white" style={{marginLeft:"1rem", textAlign:"left"}}>
                        ▪ Inspection of working premises
                        </p>
                      </div>
                    </li>
                  </ul>
                </div>
                {/*End Single Documents Box*/}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div
          className="container-fluid"
          style={{
            backgroundImage: `url(${bgimg})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            overflow: "hidden",
          }}
        >
          <div className="row">
            <div className="col-md-6">
              <img
                src={fastServe}
                width="70%"
                className="mx-auto d-block mt-5"
                alt=""
              />
              <div className="content my-5">
                <h3
                  style={{ color: "rgb(158, 27, 30)" }}
                  className="text-center"
                >
                  Why use Indo Fast Serve
                </h3>
                <div className=" d-flex justify-content-around text-center mt-4">
                  <div>
                    <img src={cashWithdrawal} width={60} alt="" />
                    <p>Cash Withdrawal</p>
                  </div>

                  <div className="">
                    <img src={CashDeposit} width={60} alt="" />
                    <p>Cash Deposit</p>
                  </div>
                  <div>
                    <img src={BalanceEnquiry} width={60} alt="" />
                    <p>Balance Enquiry</p>
                  </div>
                  <div>
                    <img src={school} width={60} alt="" />
                    <p>School Payments</p>
                  </div>
                </div>
                <h3
                  style={{ color: "rgb(158, 27, 30)" }}
                  className="text-center mt-5 mb-4"
                >
                  Requirements
                </h3>
                <div className="d-flex flex-column align-items-center gap-4">
                  <button
                    className="btn btn-danger w-50 text-white fs-5"
                    style={{ backgroundColor: "#9E1B1E" }}
                  >
                    Active IZB bank account
                  </button>
                  <button
                    className="btn btn-danger w-50 text-white fs-5"
                    style={{ backgroundColor: "#9E1B1E" }}
                  >
                    NRC or valid ID
                  </button>
                  <button
                    className="btn btn-danger w-50 text-white fs-5"
                    style={{ backgroundColor: "#9E1B1E" }}
                  >
                    Registered mobile number
                  </button>
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <img src={booth} alt="" />
            </div>
          </div>
        </div>
      </section>
      <AllAcountsSection />
    </>
  );
};

export default IndoFastServe;
