import React, { useEffect } from "react";
import Breadcrumb from "../../Components/Breadcrumb/Breadcrumb";
import AllAcountsSection from "../../Components/AllAccountsSection/AllAcountsSection";
import SavePlusAccountComponent from "../../Components/SavePlusAccount/SavePlusAccountComponent";
import AdvisorsVideo from "../../Components/AdvisorsVideo/AdvisorsVideo";
import AllAccounts from "../../Components/AllAccounts/AllAccounts";
import ContactForm from "../../Components/ContactForm/ContactForm";
import CustomerCare from "../../Components/CustomerCare/CustomerCare";
import OurStores from "../../Components/OurStories/OurStores";
import { AiFillCaretRight, AiOutlineRight } from "react-icons/ai";

import bgimg from "../../assets/images/IZB/IZB Website Skin/IZB Save Plus Account/image_6.png";
import { AiOutlineArrowRight } from "react-icons/ai";

const PrivilegeCurrentAcc = () => {
  const BackgroundBrundcrumb = "https://techrowth.s3.eu-north-1.amazonaws.com/images/IZB/IZB+Website+Skin/IZB+Privilege+Current+Account/Image_1.png";
const img1 = "https://techrowth.s3.eu-north-1.amazonaws.com/images/IZB/IZB+Website+Skin/IZB+Privilege+Current+Account/Image_2.png";
const Arrow_down = "https://techrowth.s3.eu-north-1.amazonaws.com/images/IZB/arrow_down.png";
const EmailAlert = "https://techrowth.s3.eu-north-1.amazonaws.com/images/IZB/IZB+Website+Skin/IZB+Save+Plus+Account/Icons/alert.png";
const monitor = "https://techrowth.s3.eu-north-1.amazonaws.com/images/IZB/IZB+Website+Skin/IZB+Classic+Savings+Account/Icons/monitor.png";
const statement = "https://techrowth.s3.eu-north-1.amazonaws.com/images/IZB/IZB+Website+Skin/IZB+Privilege+Current+Account/Icons/bank-statement.png";
const assets = "https://techrowth.s3.eu-north-1.amazonaws.com/images/IZB/IZB+Website+Skin/Classic+Current+Account/icons/assets.png";
const money = "https://techrowth.s3.eu-north-1.amazonaws.com/images/IZB/IZB+Website+Skin/Classic+Current+Account/icons/transfer-money.png";
const cheque1 = "https://techrowth.s3.eu-north-1.amazonaws.com/images/IZB/IZB+Website+Skin/Classic+Current+Account/icons/cheque.png";
const overdraft = "https://techrowth.s3.eu-north-1.amazonaws.com/images/IZB/IZB+Website+Skin/Classic+Current+Account/icons/overdraft.png";
const certificate = "https://techrowth.s3.eu-north-1.amazonaws.com/images/IZB/IZB+Website+Skin/Classic+Current+Account/icons/guarantee-certificate.png";
const rent = "https://techrowth.s3.eu-north-1.amazonaws.com/images/IZB/IZB+Website+Skin/Classic+Current+Account/icons/rent.png";
const termLoan = "https://techrowth.s3.eu-north-1.amazonaws.com/images/IZB/IZB+Website+Skin/Classic+Current+Account/icons/term-loan.png";
const padloack = "https://techrowth.s3.eu-north-1.amazonaws.com/images/IZB/IZB+Website+Skin/Classic+Current+Account/icons/padlock.png";
const currency = "https://techrowth.s3.eu-north-1.amazonaws.com/images/IZB/IZB+Website+Skin/Classic+Current+Account/icons/foreign-currency-exchange.png";
const cheque2 = "https://techrowth.s3.eu-north-1.amazonaws.com/images/IZB/IZB+Website+Skin/IZB+Privilege+Current+Account/Icons/cheque.png";
const creditCard = "https://techrowth.s3.eu-north-1.amazonaws.com/images/IZB/IZB+Website+Skin/Classic+Current+Account/icons/credit-card.png";
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const accountContent =
    "This is a business current account for individuals, businesses, parastatals, limited companies, partnership firms, trusts, association of offering Premier business banking solutions and services designed specifically to meet business operation needs including concessions on service charges.";
  return (
    <>
      <Breadcrumb
        Heading="IZB Privilege Current Account"
        img={BackgroundBrundcrumb}
        show={true} btnTxt="Open an Account"
      />
      <SavePlusAccountComponent
        heading="IZB Privilege Current Account"
        subHeading="Know About"
        content={accountContent}
        img={img1}
        btnTxt="Open an Account"
      />

      <section
        className="page-contains-area"
        style={{ backgroundColor: "#FFF5E7" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="page-contains-box">
                <div className="page-contains-btn">
                  <ul className="navigation clearfix scroll-nav">
                  <li className="current">
                      <a href="#benefits">
                        <img src={Arrow_down} alt="" className="me-1" />
                        Benefits
                      </a>
                    </li>
            
               
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        id="benefits"
        class="documents-area"
    
      >
        <div className="container">
        <div className="container pt-4">
          <div className="text-center my-5">
            <h1 style={{ color: "#484848" }}>
              Our IZB Privilege Current Account Benefits
            </h1>
            <p style={{ color: "#484848" }}>
              We help businesses and customers achieve more.
            </p>
          </div>
          <div className="d-flex justify-content-center">
            <ul className="row choose-style1__content">
              <li className="col-xl-3 col-lg-3 single-choose-style1-colum text-center">
                <div className="single-choose-style1">
                  <div className="icon">
                    <img src={monitor} alt="" />
                  </div>
                  <div className="text">
                    <h5>Internet Banking facility</h5>
                  </div>
                </div>
              </li>
              <li className="col-xl-3 col-lg-3 single-choose-style1-colum text-center">
                <div className="single-choose-style1">
                  <div className="icon">
                    <img src={cheque2} alt="" />
                  </div>
                  <div className="text">
                    <h5> Cheque book facility</h5>
                  </div>
                </div>
              </li>
              <li className="col-xl-3 col-lg-3 single-choose-style1-colum text-center">
                <div className="single-choose-style1">
                  <div className="icon">
                    <img src={EmailAlert} alt="" />
                  </div>
                  <div className="text">
                    <h5>SMS alert facility</h5>
                  </div>
                </div>
              </li>
              <li className="col-xl-3 col-lg-3 single-choose-style1-colum text-center">
                <div className="single-choose-style1">
                  <div className="icon">
                    <img src={statement} alt="" />
                  </div>
                  <div className="text">
                    <h5>Free E-statement facility</h5>
                  </div>
                </div>
              </li>
              <li className="col-xl-3 col-lg-3 single-choose-style1-colum text-center">
                <div className="single-choose-style1">
                  <div className="icon">
                    <img src={currency} alt="" />
                  </div>
                  <div className="text">
                    <h5>Foreign exchange services</h5>
                  </div>
                </div>
              </li>

              <li className="col-xl-3 col-lg-3 single-choose-style1-colum text-center">
                <div className="single-choose-style1">
                  <div className="icon">
                    <img src={overdraft} alt="" />
                  </div>
                  <div className="text">
                    <h5>Overdraft</h5>
                  </div>
                </div>
              </li>
              <li className="col-xl-3 col-lg-3 single-choose-style1-colum text-center">
                <div className="single-choose-style1">
                  <div className="icon">
                    <img src={certificate} alt="" />
                  </div>
                  <div className="text">
                    <h5>Bank guarantee facility</h5>
                  </div>
                </div>
              </li>
              <li className="col-xl-3 col-lg-3 single-choose-style1-colum text-center">
                <div className="single-choose-style1">
                  <div className="icon">
                    <img src={rent} alt="" />
                  </div>
                  <div className="text">
                    <h5>Lease rentals discounting</h5>
                  </div>
                </div>
              </li>

              <li className="col-xl-3 col-lg-3 single-choose-style1-colum text-center">
                <div className="single-choose-style1">
                  <div className="icon">
                    <img src={cheque1} alt="" />
                  </div>
                  <div className="text">
                    <h5>Bank certified cheques</h5>
                  </div>
                </div>
              </li>
              <li className="col-xl-3 col-lg-3 single-choose-style1-colum text-center">
                <div className="single-choose-style1">
                  <div className="icon">
                    <img src={assets} alt="" />
                  </div>
                  <div className="text">
                    <h5>Asset financing</h5>
                  </div>
                </div>
              </li>
              <li className="col-xl-3 col-lg-3 single-choose-style1-colum text-center">
                <div className="single-choose-style1">
                  <div className="icon">
                    <img src={termLoan} alt="" />
                  </div>
                  <div className="text">
                    <h5>Term loans</h5>
                  </div>
                </div>
              </li>
              <li className="col-xl-3 col-lg-3 single-choose-style1-colum text-center">
                <div className="single-choose-style1">
                  <div className="icon">
                    <img src={creditCard} alt="" />
                  </div>
                  <div className="text">
                    <h5>Letters of credit</h5>
                  </div>
                </div>
              </li>
              <li className="col-xl-3 col-lg-3 single-choose-style1-colum text-center">
                <div className="single-choose-style1">
                  <div className="icon">
                    <img src={money} alt="" />
                  </div>
                  <div className="text">
                    <h5>
                      Remittances <br></br> (RTGS/DDACC)
                    </h5>
                  </div>
                </div>
              </li>
              <li className="col-xl-3 col-lg-3 single-choose-style1-colum text-center">
                <div className="single-choose-style1">
                  <div className="icon">
                    <img src={padloack} alt="" />
                  </div>
                  <div className="text">
                    <h5>Investment in securities</h5>
                  </div>
                </div>
              </li>
              <li></li>
            </ul>
          </div>
        </div>
        </div>
</section>
        {/* <section
        id="benefit"
        class="documents-area"
        style={{
          backgroundImage: `url(${bgimg})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
          <div className="text-center">
            <h1 className="text-white">
              IZB privilege Current A/C Service Charges
            </h1>
            <p className="text-white">
              Your money is making money for you & Your Family.
            </p>
          </div>
          <div className="row">
            <div className="col-xl-12">
              <div className="job-list-table-box">
                <div className="table-outer">
                  <table className="job-list-table">
                    <thead className="header">
                      <tr>
                        <th>SL. No</th>
                        <th>Product Name</th>
                        <th>IZB Forex Current Account</th>
                      </tr>
                    </thead>
                    <tbody className="text-white">
                      <tr>
                        <td className="department text-white">
                          <h3>1.</h3>
                        </td>
                        <td className="location">
                          <p>Target Groups</p>
                        </td>
                        <td className="location">
                          <p>
                            High net worth customers i.e. Individual/ Business
                            accounts, Parastatals, Limited Companies,
                            Partnership firms, Proprietorship firms, Trusts,
                            Association ofpersons registered societies.
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td className="department text-white">
                          <h3>2.</h3>
                        </td>
                        <td className="location">
                          <p>Account Opening Requirement</p>
                        </td>
                        <td className="location">
                          <p>KYC requirement as stipulated</p>
                        </td>
                      </tr>
                      <tr>
                        <td className="department">
                          <h3>3.</h3>
                        </td>
                        <td className="location">
                          <p>Type of Account</p>
                        </td>
                        <td className="location">
                          <p>California</p>
                        </td>
                      </tr>
                      <tr>
                        <td className="department">
                          <h3>4.</h3>
                        </td>
                        <td className="location">
                          <p>Rate of Interest</p>
                        </td>
                        <td className="location">
                          <p>6% p.a payable twice-yearly</p>
                        </td>
                      </tr>
                      <tr>
                        <td className="department">
                          <h3>5.</h3>
                        </td>
                        <td className="location">
                          <p>Maintenance Charges</p>
                        </td>
                        <td className="location">
                          <p>Free</p>
                        </td>
                      </tr>
                      <tr>
                        <td className="department">
                          <h3>6.</h3>
                        </td>
                        <td className="location">
                          <p>Maximum Balance</p>
                        </td>
                        <td className="location">
                          <p>N/A</p>
                        </td>
                      </tr>
                      <tr>
                        <td className="department">
                          <h3>7.</h3>
                        </td>
                        <td className="location">
                          <p>Minimum Balance Requirment</p>
                        </td>
                        <td className="location">
                          <p>K1,000/-</p>
                        </td>
                      </tr>
                      <tr>
                        <td className="department">
                          <h3>8.</h3>
                        </td>
                        <td className="location">
                          <p>Minimum balance Charge</p>
                        </td>
                        <td className="location">
                          <p>K25/-</p>
                        </td>
                      </tr>
                      <tr>
                        <td className="department">
                          <h3>9.</h3>
                        </td>
                        <td className="location">
                          <p>Cheque Book</p>
                        </td>
                        <td className="location">
                          <p>N/A</p>
                        </td>
                      </tr>
                      <tr>
                        <td className="department">
                          <h3>10.</h3>
                        </td>
                        <td className="location">
                          <p>SMS Alert</p>
                        </td>
                        <td className="location">
                          <p>Free</p>
                        </td>
                      </tr>
                      <tr>
                        <td className="department">
                          <h3>11.</h3>
                        </td>
                        <td className="location">
                          <p>Monthly Statement</p>
                        </td>
                        <td className="location">
                          <p>Free once permonth</p>
                        </td>
                      </tr>
                      <tr>
                        <td className="department">
                          <h3>12.</h3>
                        </td>
                        <td className="location">
                          <p>Duplicate/Interim Statement</p>
                        </td>
                        <td className="location">
                          <p>K50 /- per page</p>
                        </td>
                      </tr>
                      <tr>
                        <td className="department">
                          <h3>13.</h3>
                        </td>
                        <td className="location">
                          <p>Transaction Fee</p>
                        </td>
                        <td className="location">
                          <p>Free</p>
                        </td>
                      </tr>
                      <tr>
                        <td className="department">
                          <h3>14.</h3>
                        </td>
                        <td className="location">
                          <p>E-statement</p>
                        </td>
                        <td className="location">
                          <p>Available-Free</p>
                        </td>
                      </tr>
                      <tr>
                        <td className="department">
                          <h3>15.</h3>
                        </td>
                        <td className="location">
                          <p> Closure of account</p>
                        </td>
                        <td className="location">
                          <p>Free</p>
                        </td>
                      </tr>
                      <tr>
                        <td className="department">
                          <h3>16.</h3>
                        </td>
                        <td className="location">
                          <p>Cash Deposits (At any IZB Branch)</p>
                        </td>
                        <td className="location">
                          <p>Free</p>
                        </td>
                      </tr>
                      <tr>
                        <td className="department">
                          <h3>17.</h3>
                        </td>
                        <td className="location w-50">
                          <p>
                            Cash Withdrawal Charges (at any IZB branch) Note:
                            Savings account holders who do not qualify for Debit
                            card such as churches, NGO’s, etc are exempted from
                            this service charge.
                          </p>
                        </td>
                        <td className="location">
                          <p>
                            <AiFillCaretRight className="me-1" />
                            Free once per month thereafterK50/- within ATM limit
                            i.e.K20,000.00
                          </p>
                          <p>
                            <AiFillCaretRight className="me-1" />
                            Free above ATM Limit
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td className="department">
                          <h3>18.</h3>
                        </td>
                        <td className="location">
                          <p>Issuance of Deposit Book</p>
                        </td>AiFillCaretRightimg
                        <td className="location">
                          <p>Free</p>
                        </td>
                      </tr>
                      <tr>
                        <td className="department">
                          <h3>19.</h3>
                        </td>
                        <td className="location">
                          <h5 className="text-white my-2">
                            IZB Visa Chip Debit Card-Charges
                          </h5>
                          <p>
                            <AiFillCaretRight className="me-1" />
                            Issuance charge
                          </p>
                          <p>
                            <AiFillCaretRight className="me-1" />
                            Monthly Fee
                          </p>
                          <p>
                            <AiFillCaretRight className="me-1" />
                            Renewal of Card Charges
                          </p>
                          <p>
                            <AiFillCaretRight className="me-1" />
                            Card Replacement / Uncollected card beyond 90 days
                          </p>
                          <div className="location ps-2">
                            <p>
                              <AiOutlineRight className="me-1" />
                              Classic
                            </p>
                            <p>
                              <AiOutlineRight className="me-1" />
                              Gold
                            </p>
                            <p>
                              <AiOutlineRight className="me-1" />
                              Platinum
                            </p>
                          </div>
                          <p>
                            <AiFillCaretRight className="me-1" />
                            PIN Regeneration
                          </p>
                          <h5 className="text-white my-2">IZB ATMs</h5>
                          <p>
                            <AiFillCaretRight className="me-1" />
                            Cash Withdrawals (in Kwacha){" "}
                          </p>
                          <p>
                            <AiFillCaretRight className="me-1" />
                            Balance Enquiry
                          </p>
                          <p>
                            <AiFillCaretRight className="me-1" />
                            Mini Statements
                          </p>
                          <h5 className="text-white my-2">
                            Other Bank's VISA enabled ATMS in Zambia
                          </h5>
                          <p>
                            <AiFillCaretRight className="me-1" />
                            Cash Withdrawal (In kwacha)
                          </p>
                          <p>
                            <AiFillCaretRight className="me-1" />
                            Balance Enquiry
                          </p>

                          <h5 className="text-white my-2">
                            {" "}
                            Other Banks VISA enabled ATMs (abroad)
                          </h5>
                          <p>
                            <AiFillCaretRight className="me-1" />
                            Cash Withdrawals
                          </p>
                          <p>
                            <AiFillCaretRight className="me-1" />
                            Balance Enquiry
                          </p>
                        </td>
                        <td className="location">
                          <h4 className="text-white my-2">
                            <span>&nbsp;</span>
                          </h4>
                          <h4 className="text-white my-2">
                            <span>&nbsp;</span>
                          </h4>
                          <p>Free </p>
                          <p>Free</p>
                          <p>Free</p>
                          <p>
                            <span>&nbsp;</span>
                          </p>
                          <div className="location">
                            <p>K100/- per card</p>
                            <p>K125/- per card</p>
                            <p>K150/- per card</p>
                          </div>
                          <p>Free </p>
                          <h4 className="text-white my-2">
                            {" "}
                            <span>&nbsp;</span>
                          </h4>
                          <p>K8/-</p>
                          <p>Free</p>
                          <p>K3/-</p>
                          <h4 className="text-white my-2">
                            <span>&nbsp;</span>
                          </h4>
                          <p>K15/-</p>
                          <p>K3/-</p>

                          <h4 className="text-white my-2">
                            <span>&nbsp;</span>
                          </h4>
                          <p>K35/-</p>
                          <p>K10/-</p>
                          <p>
                            Free, however, if the matter goes for arbitration,
                            appropriate fees shall apply on a cost recovery
                            basis.
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td className="department">
                          <h3>20.</h3>
                        </td>
                        <td className="location">
                          <p>Return of cheques</p>
                        </td>
                        <td className="location">
                          <p>N/A</p>
                        </td>
                      </tr>
                      <tr>
                        <td className="department">
                          <h3>21.</h3>
                        </td>
                        <td className="location">
                          <p>Stop Payment Instructions</p>
                        </td>
                        <td className="location">
                          <p>N/A</p>
                        </td>
                      </tr>
                      <tr>
                        <td className="department">
                          <h3>22.</h3>
                        </td>
                        <td className="location">
                          <h5 className="text-white my-2">
                            Remittances (kwacha)
                          </h5>
                          <p>
                            <AiFillCaretRight className="me-1" />
                            Banker's cheque
                          </p>
                          <p>
                            <AiFillCaretRight className="me-1" />
                            Cancellation of Banker's cheque
                          </p>
                          <p>
                            <AiFillCaretRight className="me-1" />
                            Issuance of duplicate Banker's chques in lieu of
                            lost cheque
                          </p>
                          <p>
                            <AiFillCaretRight className="me-1" />
                            Cancelling of lost Banker's cheque{" "}
                          </p>

                          <h5 className="text-white my-2">
                            Other remittances{" "}
                          </h5>
                          <p>
                            <AiFillCaretRight className="me-1" />
                            Within IZB branches{" "}
                          </p>
                          <p>
                            <AiFillCaretRight className="me-1" />
                            RTGS – Manual{" "}
                          </p>
                          <p>
                            <AiFillCaretRight className="me-1" />
                            RTGS – Electronic{" "}
                          </p>
                          <p>
                            <AiFillCaretRight className="me-1" />
                            DDACC – Manual{" "}
                          </p>

                          <p>
                            <AiFillCaretRight className="me-1" />
                            DDACC – Electronic{" "}
                          </p>

                          <h5 className="text-white my-2">
                            Transfer of funds on closure of account{" "}
                          </h5>
                        </td>
                        <td className="location">
                          <h5 className="text-white my-2">
                            <span>&nbsp;</span>
                          </h5>
                          <p>K100/- plus VAT</p>
                          <p>K50/-</p>
                          <p>K75/-</p>
                          <p>K75/-</p>

                          <h5 className="text-white my-2">
                            Other remittances{" "}
                          </h5>
                          <p>Free</p>
                          <p>K55/- per transaction</p>
                          <p>K40/- per transaction</p>

                          <p>K25/- per transaction</p>
                          <p>K10/- per transaction</p>

                          <p className="pt-2 ">K50/- per transaction </p>
                        </td>
                      </tr>
                      <tr>
                        <td className="department">
                          <h3>23.</h3>
                        </td>
                        <td className="location">
                          <p>Balance confirmation charges</p>
                        </td>
                        <td className="location">
                          <p>K200/- per certificate</p>
                        </td>
                      </tr>
                      <tr>
                        <td className="department">
                          <h3>24.</h3>
                        </td>
                        <td className="location">
                          <p>
                            Authentication/Authorisation/Verificationof other
                            documents. <br /> (only if closed within last 6
                            months)
                          </p>
                        </td>
                        <td className="location">
                          <p>
                            K50/- per document for confirmation/
                            authorization/verification of documents
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td className="department">
                          <h3>25.</h3>
                        </td>
                        <td className="location">
                          <h5 className="text-white my-2">
                            Standing Order Charges
                          </h5>
                          <p>
                            <AiFillCaretRight className="me-1" />
                            Setting up charges
                          </p>
                          <p>
                            <AiFillCaretRight className="me-1" />
                            Processing (internal) intra-bank
                          </p>
                          <p>
                            <AiFillCaretRight className="me-1" />
                            Processing (external within Zambia) inter-bank
                          </p>
                          <p>
                            <AiFillCaretRight className="me-1" />
                            Amendment
                          </p>
                        </td>
                        <td className="location">
                          <h5 className="text-white my-2">
                            <span>&nbsp;</span>
                          </h5>
                          <p>K50/-</p>
                          <p>K50/- per transaction</p>
                          <p>K25/- per transaction</p>
                          <p>Free</p>
                        </td>
                      </tr>
                      <tr>
                        <td className="department">
                          <h3>26.</h3>
                        </td>
                        <td className="location">
                          <p>Dormant Account</p>
                        </td>
                        <td className="location">
                          <p>Free</p>
                        </td>
                      </tr>
                      <tr>
                        <td className="department">
                          <h3>27.</h3>
                        </td>
                        <td className="location">
                          <p>
                            Reactivation of Account <br />
                            (only if closed within last 6 months)
                          </p>
                        </td>
                        <td className="location">
                          <p>Free</p>
                        </td>
                      </tr>
                      <tr>
                        <td className="department">
                          <h3>28.</h3>
                        </td>
                        <td className="location">
                          <p>Photocopies at customer’s request</p>
                        </td>
                        <td className="location">
                          <p>K 10/- per page</p>
                        </td>
                      </tr>
                      <tr>
                        <td className="department">
                          <h3>29.</h3>
                        </td>
                        <td className="location">
                          <p>
                            Request for retrieval of paid cheque, deposit slip,
                            transfer request or any Bank record.
                          </p>
                        </td>
                        <td className="location">
                          <p>K 200/- per instrument</p>
                        </td>
                      </tr>
                      <tr>
                        <td className="department">
                          <h3>30.</h3>
                        </td>
                        <td className="location">
                          <p>Reference letter</p>
                        </td>
                        <td className="location">
                          <p>K50/- per letter</p>
                        </td>
                      </tr>
                      <tr>
                        <td className="department">
                          <h3>31.</h3>
                        </td>
                        <td className="location">
                          <p>NAPSA Online Payment</p>
                        </td>
                        <td className="location">
                          <p>Free</p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className="row" id="eligibility">
            <div className="col-xl-12">
              <div className="job-list-table-box">
                <h3 className="text-white mb-3">Remittances (Kwacha)</h3>
                <div className="table-outer">
                  <table className="job-list-table">
                    <thead className="header text-center">
                      <tr>
                        <th>SL. No</th>
                        <th>Product Name</th>
                        <th>IZB Forex Current Account</th>
                      </tr>
                    </thead>
                    <tbody className="text-white">
                      <tr>
                        <td className="department">
                          <h3>1.</h3>
                        </td>
                        <td className="location">
                          <h5 className="text-white my-2">
                            Remittances (kwacha)
                          </h5>
                          <p>
                            <AiFillCaretRight className="me-1" />
                            Banker's cheque
                          </p>
                          <p>
                            <AiFillCaretRight className="me-1" />
                            Cancellation of Banker's cheque
                          </p>
                          <p>
                            <AiFillCaretRight className="me-1" />
                            Issuance of duplicate Banker's chques in lieu of
                            lost cheque
                          </p>
                          <p>
                            <AiFillCaretRight className="me-1" />
                            Cancelling of lost Banker's cheque{" "}
                          </p>

                          <h5 className="text-white my-2">
                            Other remittances{" "}
                          </h5>
                          <p>
                            <AiFillCaretRight className="me-1" />
                            Within IZB Branches
                          </p>
                          <p>
                            <AiFillCaretRight className="me-1" />
                            Other Banks (RTGS)
                          </p>
                          <p>
                            <AiFillCaretRight className="me-1" />
                            DDACC
                          </p>
                          <p>
                            <AiFillCaretRight className="me-1" />
                            Transfer of funds on closure of account
                          </p>

                          <h5 className="text-white my-2">
                            Transfer of funds on closure of account{" "}
                          </h5>
                        </td>
                        <td className="location">
                          <h5 className="text-white my-2">
                            <span>&nbsp;</span>
                          </h5>
                          <p>
                            K100/- plus VAT ( Free 3 cheques per month (Non
                            commutation) for IZB-PRV only )
                          </p>
                          <p>K50/-</p>
                          <p>K75/-</p>
                          <p>K100/-</p>

                          <h5 className="text-white my-2">
                            Other remittances{" "}
                          </h5>
                          <p>Free</p>

                          <p>K40/- per transaction</p>
                          <p>K25/- per transaction</p>
                          <p>K50/- per transaction</p>

                          <p className="pt-2 ">K50/- per Transfer </p>
                        </td>
                      </tr>
                      <tr>
                        <td className="department text-white">
                          <h3>2.</h3>
                        </td>
                        <td className="location">
                          <p>Balance confirmation charges</p>
                        </td>
                        <td className="location">
                          <p>K150/- per certificate</p>
                        </td>
                      </tr>
                      <tr>
                        <td className="department">
                          <h3>3.</h3>
                        </td>
                        <td className="location">
                          <p>
                            Authentication/ Authorisation/ Verification of other
                            documents
                          </p>
                        </td>
                        <td className="location">
                          <p>
                            K50/- per document for confirmation/ authorization
                            /verification of documents
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td className="department">
                          <h3>4.</h3>
                        </td>
                        <td className="location">
                          <h5 className="text-white">Standing Order Charges</h5>
                          <p>
                            <AiFillCaretRight className="me-1" />
                            Setting up charges
                          </p>
                          <p>
                            <AiFillCaretRight className="me-1" />
                            Processing (internal) intra-bank
                          </p>
                          <p>
                            <AiFillCaretRight className="me-1" />
                            Processing (external) inter-bank
                          </p>
                          <p>
                            <AiFillCaretRight className="me-1" />
                            Amendment
                          </p>
                        </td>
                        <td className="location">
                          <h5>&nbsp;</h5>
                          <p>K50/-</p>
                          <p>K20/- per transaction</p>
                          <p>K40/- per transaction</p>
                          <p>K50/- per transaction</p>
                        </td>
                      </tr>
                      <tr>
                        <td className="department">
                          <h3>5.</h3>
                        </td>
                        <td className="location">
                          <p>Dormant Account</p>
                        </td>
                        <td className="location">
                          <p>Free</p>
                        </td>
                      </tr>
                      <tr>
                        <td className="department">
                          <h3>6.</h3>
                        </td>
                        <td className="location">
                          <p>Reactivation of Account (only if closed within last 6 months)</p>
                        </td>
                        <td className="location">
                          <p>N/A</p>
                        </td>
                      </tr>
                      <tr>
                        <td className="department">
                          <h3>7.</h3>
                        </td>
                        <td className="location">
                          <p>Photocopies at customer’s request</p>
                        </td>
                        <td className="location">
                          <p>K10/- per page</p>
                        </td>
                      </tr>
                      <tr>
                        <td className="department">
                          <h3>8.</h3>
                        </td>
                        <td className="location">
                          <p>Request for retrieval of paid cheques</p>
                        </td>
                        <td className="location">
                          <p>K100/- per page</p>
                        </td>
                      </tr>
                      <tr>
                        <td className="department">
                          <h3>9.</h3>
                        </td>
                        <td className="location">
                          <p>Drawal against un-cleared effects</p>
                        </td>
                        <td className="location">
                          <p>1.5% (min. K200/-)</p>
                        </td>
                      </tr>
                      <tr>
                        <td className="department">
                          <h3>10.</h3>
                        </td>
                        <td className="location">
                          <p>Trancers</p>
                        </td>
                        <td className="location">
                          <p>K150/-</p>
                        </td>
                      </tr>
                      <tr>
                        <td className="department">
                          <h3>11.</h3>
                        </td>
                        <td className="location">
                          <p>Status Report/ Bankers enquiry/ Reference letter</p>
                        </td>
                        <td className="location">
                          <p>K150/- per enquiry</p>
                        </td>
                      </tr>
                      <tr>
                        <td className="department">
                          <h3>12.</h3>
                        </td>
                        <td className="location">
                          <p>Search Fees</p>
                        </td>
                        <td className="location">
                          <p>K150 /-</p>
                        </td>
                      </tr>
                      <tr>
                        <td className="department">
                          <h3>13.</h3>
                        </td>
                        <td className="location">
                          <p>ZRA Online Tax Payment</p>
                        </td>
                        <td className="location">
                          <p>K25/- per certificate</p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
       
      </section> */}
       <AdvisorsVideo />
      <AllAccounts />
      <ContactForm />
      <CustomerCare />
      <OurStores />
    </>
  );
};

export default PrivilegeCurrentAcc;
