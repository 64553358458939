import React from "react";
import Breadcrumb2 from "../../Components/Breadcrumb2/Breadcrumb2";
import AllAcountsSection from "../../Components/AllAccountsSection/AllAcountsSection";
import { AiOutlineArrowRight, AiOutlineArrowUp } from "react-icons/ai";
import "./Career.css";
import CustomerCare from "../../Components/CustomerCare/CustomerCare";
import OurStores from "../../Components/OurStories/OurStores";
import { AiFillCaretRight, AiOutlineRight } from "react-icons/ai";
import { useEffect, useState } from "react";
import axios from 'axios';
import { Link } from "react-router-dom";
const Career = ({question,answer}) => {
  const BackgroundBrundcrumb = "https://techrowth.s3.eu-north-1.amazonaws.com/images/Forex_Savings_Banner.png";
const img1 = "https://techrowth.s3.eu-north-1.amazonaws.com/images/Forex_Savings.png";
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [show, setShow] = useState(false)
  const [show1, setShow1] = useState(false)
  const [show2, setShow2] = useState(false)
  const [show3, setShow3] = useState(false)
  const [show4, setShow4] = useState(false)
  const [show5, setShow5] = useState(false)
  const [show6, setShow6] = useState(false)
  const [rates, setRates] = useState(null);
  const [currentDate, setCurrentDate] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const fetchForexRates = async () => {
      const appId = 'c1018d855f1e40499a81ba0738bd09f0'; // Replace with your actual App ID
      const url = `https://openexchangerates.org/api/latest.json?app_id=${appId}`;
  
      try {
        const response = await axios.get(url);
        const { rates } = response.data;
        setRates(rates);
      } catch (error) {
        console.error('Error fetching forex rates:', error);
      }
    };
  
    fetchForexRates();
  }, []);

  useEffect(() => {
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', timeZone: 'Africa/Lusaka' };
    const currentDate = new Date().toLocaleDateString(undefined, options);
    setCurrentDate(currentDate);
  }, []);

  const accountContent =
    "Savings accounts can be opened for individuals in US Dollar/Pound Sterling/South African Rand/Euro. Cheque book facility is an option available. With this product, we also offer VISA Chip & Pin Debit Card facility, which is ideal for businessment/employees travelling frequently outside Zambia, who can draw money from any of the VISA enabled ATMs around the world.";
  return (
    <>
      <Breadcrumb2
        Heading="EMPLOYMENT AND CAREER DEVELOPMENT OPPORTUNITIES"
        img={BackgroundBrundcrumb}
        show={true}
      
      />

      <p className="mx-5 my-5"><strong>Indo Zambia Bank Limited, a bank which has been operating in Zambia since 1984 and has a branch network of 38
      branches and agencies across the country, has exciting
      career opportunities for individuals seeking to grow in the
      banking and financial services sector.</strong></p>





<h3 className="my-3 mx-5" style={{border:"1px solid", padding:"1vw", color:"white",background:"rgb(158, 27, 30)"}}>TRAINEE PROGRAMS</h3>
<h4 className="my-3 mx-5" style={{ color: "#9E1B1E", textAlign:"left" }}> MANAGEMENT TRAINEES PROGRAM </h4>
          <div className="d-flex mx-5">
  <div className="izb">
    <h5 className="mt-3 mx-5" style={{ color: "black" }}>
      Job Purpose
    </h5>
    <p className="mx-5">
    This role is a Management Trainee program for university
graduates with either a Master’s Degree or Post Graduate
qualification who wish to develop a career within the banking
profession. The successful candidates will be exposed to our
different functions as part of the bank’s Talent Development
Program.
    </p>
  </div>
 <Link to="https://docs.google.com/forms/d/e/1FAIpQLSemCgIBK3k50tEuHLdnNW3j9QCrHVgknnHeKmX6cJGFWwzHkw/viewform" target="_blank">
  <button
    className="btn btn-link"
    style={{ border: "1px solid #9e1b1e", backgroundColor: "#9e1b1e", textDecoration: "none", color: "#fff", margin: "1vw", height:"2.5vw" }}
  >
    Apply Now
  </button>
  </Link>
</div>



          <div className="container">
          <div className="mt-3">
            <div className="col-xl-6">
              <div className="faq-style1__content">
                <ul className="accordion-box">
                <li className="accordion block active-block">
      <div className="acc-btn active">
        <div className="icon-outer">
            { show? <AiOutlineArrowRight/> : <AiOutlineArrowUp />

            }
        </div>
        <h3 onClick={() => setShow(!show)} style={{textAlign:"left"}}>Qualifications and Experience for Finance Management
Trainees (X3)</h3>
      </div>
      <div className="acc-content current" style={{textAlign:"left"}}>
       {show && <p className="px-4">
       
       <ul style={{listStyleType:"disc"}}>
        <li>•	 ACCA, ZICA, BA Finance with a distinction or merit from
any recognized university</li>
        <li>•	 Master’s degree or equivalent in Finance will be an added
advantage.</li>
<li>•	 Relevant Finance professional certifications will be an added
advantage.</li>
<li>•	 Candidates must be able and willing to work from any
location where IZB has presence.</li>
<li>•	 Candidates should be aged between 20 and 27 years.</li>


        

    </ul>
        </p>}
      </div>
    </li>
                </ul>
              </div>
            </div>
       
          </div>
     


          <div className="mt-3">
            <div className="col-xl-6">
              <div className="faq-style1__content">
                <ul className="accordion-box">
                <li className="accordion block active-block">
      <div className="acc-btn active">
        <div className="icon-outer">
            { show? <AiOutlineArrowRight/> : <AiOutlineArrowUp />

            }
        </div>
        <h3 onClick={() => setShow1(!show1)} style={{textAlign:"left"}}>Qualifications and Experience for Credit Management
Trainees (X4)</h3>
      </div>
      <div className="acc-content current" style={{textAlign:"left"}}>
       {show1 && <p className="px-4">
       
       <ul style={{listStyleType:"disc"}}>
        <li>•	 Business Degree with a distinction or merit from any
recognized university.</li>
        <li>•	 Master’s degree or business qualifications will be an added
advantage</li>
        <li>•	 Relevant Credit professional certifications will be an added
advantage.</li>
        <li>•	 Candidates must be able and willing to work from any
location where IZB has presence.</li>
        <li>•	 Candidates should be aged between 20 and 27 years. </li>

     



    </ul>
        </p>}
      </div>
    </li>
                </ul>
              </div>
            </div>
       
          </div>




          <div className="mt-3">
            <div className="col-xl-6">
              <div className="faq-style1__content">
                <ul className="accordion-box">
                <li className="accordion block active-block">
      <div className="acc-btn active">
        <div className="icon-outer">
            { show? <AiOutlineArrowRight/> : <AiOutlineArrowUp />

            }
        </div>
        <h3 onClick={() => setShow2(!show2)} style={{textAlign:"left"}}>Qualifications and Experience for Information Technology
Management Trainees (X3)</h3>
      </div>
      <div className="acc-content current" style={{textAlign:"left"}}>
       {show2 && <p className="px-4">
       
       <ul style={{listStyleType:"disc"}}>
        
        
        
<li>•	 Degree in Engineering, IT or an equivalent qualification with
a distinction or merit from any recognized university.</li>
<li>•	 Master’s degree will be an added advantage.</li>
<li>•	 Relevant IT professional certifications will be an added
advantage.</li>
<li>•	 Candidates must be able and willing to work from any location where IZB has presence.</li>
<li>•	 Candidates should be aged between 20 and 27 years.</li>
    </ul>
        </p>}
      </div>
    </li>
                </ul>
              </div>
            </div>
       
          </div>



          <div className="mt-3">
            <div className="col-xl-6">
              <div className="faq-style1__content">
                <ul className="accordion-box">
                <li className="accordion block active-block">
      <div className="acc-btn active">
        <div className="icon-outer">
            { show? <AiOutlineArrowRight/> : <AiOutlineArrowUp />

            }
        </div>
        <h3 onClick={() => setShow3(!show3)} style={{textAlign:"left"}}>Qualifications and Experience for Compliance Management
Trainees (X2)</h3>
      </div>
      <div className="acc-content current" style={{textAlign:"left"}}>
       {show3 && <p className="px-4">
       
       <ul style={{listStyleType:"disc"}}>
       
       <li>•	 Business Degree with a distinction or merit from any
recognized university.</li>
<li>•	 Master’s degree or business qualifications will be an added
advantage.</li>
<li>•	 Relevant professional certifications such as Association
of Certified Compliance Professionals and Associates or
Association of Certified Anti – Money Laundering Specialist
will be an added advantage.</li>
<li>•	 Candidates must be able and willing to work from any
location where IZB has presence.</li>
<li>•	 Candidates should be aged between 20 and 27 years.</li>
        

    </ul>
        </p>}
      </div>
    </li>
                </ul>
              </div>
            </div>
       
          </div>




          <div className="mt-3">
            <div className="col-xl-6">
              <div className="faq-style1__content">
                <ul className="accordion-box">
                <li className="accordion block active-block">
      <div className="acc-btn active">
        <div className="icon-outer">
            { show? <AiOutlineArrowRight/> : <AiOutlineArrowUp />

            }
        </div>
        <h3 onClick={() => setShow4(!show4)} style={{textAlign:"left"}}>Qualifications and Experience for Risk Management
Trainees (X1)</h3>
      </div>
      <div className="acc-content current" style={{textAlign:"left"}}>
       {show4 && <p className="px-4">
       
       <ul style={{listStyleType:"disc"}}>
       
<li>•	 Business Degree with a distinction or merit from any
recognized university.</li>
<li>•	 Master’s degree or business qualifications will be an added
advantage.</li>
<li>•	 Relevant Risk Management professional certifications will
be an added advantage.</li>
<li>•	 Candidates must be able and willing to work from any
location where IZB has presence.</li>
<li>•	 Candidates should be aged between 20 and 27 years.</li>
        

    </ul>
        </p>}
      </div>
    </li>
                </ul>
              </div>
            </div>
       
          </div>




          <div className="mt-3">
            <div className="col-xl-6">
              <div className="faq-style1__content">
                <ul className="accordion-box">
                <li className="accordion block active-block">
      <div className="acc-btn active">
        <div className="icon-outer">
            { show? <AiOutlineArrowRight/> : <AiOutlineArrowUp />

            }
        </div>
        <h3 onClick={() => setShow5(!show5)} style={{textAlign:"left"}}>Qualifications and Experience for Legal Management
Trainees (X1)</h3>
      </div>
      <div className="acc-content current" style={{textAlign:"left"}}>
       {show5 && <p className="px-4">
       
       <ul style={{listStyleType:"disc"}}>
       
       <li>•	 LLB Degree with a distinction or merit from any recognized
university.</li>
<li>•	 Master’s degree in Law qualifications will be an added
advantage.</li>
<li>•	 Must have graduated from ZIALE.</li>
<li>•	 Candidates must be able and willing to work from any
location where IZB has presence.</li>
<li>•	 Candidates should be aged between 20 and 27 years. </li>
        

    </ul>
        </p>}
      </div>
    </li>
                </ul>
              </div>
            </div>
       
          </div>




          <div className="mt-3">
            <div className="col-xl-6">
              <div className="faq-style1__content">
                <ul className="accordion-box">
                <li className="accordion block active-block">
      <div className="acc-btn active">
        <div className="icon-outer">
            { show? <AiOutlineArrowRight/> : <AiOutlineArrowUp />

            }
        </div>
        <h3 onClick={() => setShow6(!show6)} style={{textAlign:"left"}}>Qualifications and Experience for Digital Banking
Management Trainees (X2)</h3>
      </div>
      <div className="acc-content current" style={{textAlign:"left"}}>
       {show6 && <p className="px-4">
       
       <ul style={{listStyleType:"disc"}}>
       
       <li>•	 Business Degree with a distinction or merit from any
recognized university.</li>
<li>•	 Relevant Digital certification or a Master’s degree will be
an added advantage.</li>
<li>•	 Relevant IT professional certifications will be an added
advantage.</li>
<li>•	 Candidates must be able and willing to work from any
location where IZB has presence.</li>
<li>•	 Candidates should be aged between 20 and 27 years.</li>
        

    </ul>
        </p>}
      </div>
    </li>
                </ul>
              </div>
            </div>
       
          </div>



          
        </div>
        <hr className="mx-5" style={{color:"#9e1b1e",border:"2px solid #9e1b1e"}}/>






<h4 className="my-3 mx-5" style={{ color: "#9E1B1E", textAlign:"left" }}>GRADUATE TRAINEE RECRUITMENT PROGRAM</h4>
          <div className="d-flex mx-5">
          <div className="izb">
    <h5 className="mt-3 mx-5" style={{ color: "black" }}>
      Job Purpose
    </h5>
    <p className="mx-5">
    This role is an entry level trainee position for university graduates
with bachelors’ degrees, who wish to develop a career in Banking
and Customer Relationship Management within the Banking
profession and aimed at developing top quality banking and
financial services talent. The successful candidates will be
exposed to our different functions as part of the bank’s Talent
Development Program.
    </p>
  </div>
 <Link to="https://docs.google.com/forms/d/e/1FAIpQLSfgr9nsCpjsIVNHPY3tyPLh-_jbJl8z1pDAJ9grwasQPxtf4A/viewform" target="_blank">
  <button
    className="btn btn-link"
    style={{ border: "1px solid #9e1b1e", backgroundColor: "#9e1b1e", textDecoration: "none", color: "#fff", margin: "1vw", height:"2.5vw" }}
  >
    Apply Now
  </button>
  </Link>
</div>

          <div className="container">
          <div className="mt-3">
            <div className="col-xl-6">
              <div className="faq-style1__content">
                <ul className="accordion-box">
                <li className="accordion block active-block">
      <div className="acc-btn active">
        <div className="icon-outer">
            { show? <AiOutlineArrowRight/> : <AiOutlineArrowUp />

            }
        </div>
        <h3 onClick={() => setShow(!show)} style={{textAlign:"left"}}>Qualifications and Experience</h3>
      </div>
      <div className="acc-content current" style={{textAlign:"left"}}>
       {show && <p className="px-4">
       
       <ul style={{listStyleType:"disc"}}>
      <li>•	 5 O’ Level subjects with credits or better (English and
Mathematics is mandatory).</li>
<li>•	 Bachelor’s Degree with credit or better from any public and
duly accredited university.</li>
<li>•	 Candidates must be able and willing to work from any
location where IZB has presence.</li>
<li>•	 Candidates should be aged between 20 and 27 years.</li>
<li>•	 Candidates must have a business, sales and marketing
disposition.</li>


        

    </ul>
        </p>}
      </div>
    </li>
                </ul>
              </div>
            </div>
       
          </div>
     








          
        </div>
        <hr className="mx-5" style={{color:"#9e1b1e",border:"2px solid #9e1b1e"}}/>




        <h5 className="mt-3 mx-5" style={{ color: "black" }}>
      NOTE: Closing date for the Management and Graduate Trainee
Program applications is 21st July 2023.
    </h5>




        <h3 className="my-3 mx-5" style={{border:"1px solid", padding:"1vw", color:"white",background:"rgb(158, 27, 30)"}}>INTERNAL AUDIT</h3>
<h4 className="my-3 mx-5" style={{ color: "#9E1B1E", textAlign:"left" }}>1. MANAGER - INFORMATION TECHNOLOGY AUDIT (X1)</h4>
          <div className="d-flex mx-5">
          <div className="izb">
    <h5 className="mt-3 mx-5" style={{ color: "black" }}>
      Job Purpose
    </h5>
    <p className="mx-5">
      The role is responsible for performing IT internal auditing work on various IT audits, as part of the approved annual audit plan.
    </p>
  </div>
  <a href="mailto:jobs@izb.co.zm?subject=APPLICATION:%20MANAGER%20-%20INFORMATION%20TECHNOLOGY%20AUDIT%20(X1)" className="btn btn-link" style={{border: "1px solid #9e1b1e", backgroundColor: "#9e1b1e",textDecoration:"none", color:"#fff", margin:"1vw", height:"2.5vw"}}>Email Us Your Resume</a>

  

</div>



          <div className="container">
          <div className="mt-3">
            <div className="col-xl-6">
              <div className="faq-style1__content">
                <ul className="accordion-box">
                <li className="accordion block active-block">
      <div className="acc-btn active">
        <div className="icon-outer">
            { show? <AiOutlineArrowRight/> : <AiOutlineArrowUp />

            }
        </div>
        <h3 onClick={() => setShow(!show)} style={{textAlign:"left"}}>Essential Duties and Responsibilities</h3>
      </div>
      <div className="acc-content current" style={{textAlign:"left"}}>
       {show && <p className="px-4">
        <p><strong>Under the supervision of the Chief Internal Auditor, the following are among the Job Key Responsibilities:</strong></p>
       <ul style={{listStyleType:"disc"}}>
        <li>•	 Leading both basic and complex internal and enterprisewide IT, operational and regulatory audits as per audit plan.</li>
        <li>•	 Conducting audits on Applications/Network/System platform and IT general controls (i.e. Planning, fieldwork and reporting); includes but not limited to performing internal audits of projects, the development life cycle, data privacy, cyber security procedures and third-party risk management.</li>
        <li>•	 Providing consulting services and investigations to the bank’s management and staff; and providing input to development of the annual internal audit plan. </li>
        <li>•	 Maintaining all bank and professional ethical standards and
completing all internal audit work in compliance with the
IIA’s International Standards for the Professional Practice
of Internal Auditing, ISACA’s Information Systems auditing
standards</li>
        <li>•	 Making oral and written presentations to management during
and at the conclusion of the audit, discuss findings and
recommend action to improve operations, reduce costs,
and improve profits.</li>
        <li>•	 Analyzing data and operations in terms of management
controls, systems and procedures, to assess them critically
and make recommendations for changes.</li>


    </ul>
        </p>}
      </div>
    </li>
                </ul>
              </div>
            </div>
       
          </div>
          <div className="mt-3">
            <div className="col-xl-6">
              <div className="faq-style1__content">
                <ul className="accordion-box">
                <li className="accordion block active-block">
      <div className="acc-btn active">
        <div className="icon-outer">
            { show? <AiOutlineArrowRight/> : <AiOutlineArrowUp />

            }
        </div>
        <h3 onClick={() => setShow1(!show1)}  style={{textAlign:"left"}}>Required Skills and Attributes</h3>
      </div>
      <div className="acc-content current"  style={{textAlign:"left"}}>
       {show1 && <p className="px-4">
       <ul style={{listStyleType:"disc"}}>
        <li>•	 Strong command of technology and bank operating systems.</li>
        <li>•	 Teamwork, Relationship building, Collaboration, Leadership,Negotiation, Influence and Conflict Resolution.</li>
        <li>•	 Highest integrity, abide by professional standards</li>
        </ul>
        </p>}
      </div>
    </li>
                </ul>
              </div>
            </div>
       
          </div>
          <div className="mt-3">
            <div className="col-xl-6">
              <div className="faq-style1__content">
                <ul className="accordion-box">
                <li className="accordion block active-block">
      <div className="acc-btn active">
        <div className="icon-outer">
            { show? <AiOutlineArrowRight/> : <AiOutlineArrowUp />

            }
        </div>
        <h3 onClick={() => setShow2(!show2)}  style={{textAlign:"left"}}>Qualifications and Experience</h3>
      </div>
      <div className="acc-content current"  style={{textAlign:"left"}}>
       {show2 && <p className="px-4">
       <ul style={{listStyleType:"disc"}}>
          <li>•	 Grade 12 Certificate with Credit or above mandatory in Mathematics and English and any other three subjects.</li>
          <li>•	 Bachelors’ Degree in Information Technology, Management Information System, Computer Science, Information Security, Accounting, Finance or any related field. </li>
          <li>•	 Minimum Experience: 8 years of Internal or External IT audit experience.</li>
          <li>•	 CISA (Certified Information Systems Auditor) Certification.</li>
          <li>•	 Knowledge of IT best practices and frameworks (i.e. ISO 27001, COBIT, ITIL) would be considered an advantage.</li>
          <li>•	 Knowledge of Data analytics and tools such as ACL.</li>
          <li>•	 Excellent report writing and presentation skills.</li>
          <li>•	 Experience with Audit Management Software.</li>
        </ul>
        </p>}
      </div>
    </li>
                </ul>
              </div>
            </div>
       
          </div>
        </div>
        <hr className="mx-5" style={{color:"#9e1b1e",border:"2px solid #9e1b1e"}}/>





        


{/* <h3 className="my-3 mx-5" style={{border:"1px solid", padding:"1vw", color:"white",background:"rgb(158, 27, 30)"}}>INTERNAL AUDIT</h3> */}
<h4 className="my-3 mx-5" style={{ color: "#9E1B1E", textAlign:"left" }}>2. AUDIT OFFICER (X1)</h4>
          <div className="d-flex mx-5">
          <div className="izb">
    <h5 className="mt-3 mx-5" style={{ color: "black" }}>
      Job Purpose
    </h5>
    <p className="mx-5">
    The jobholder will be responsible for conducting investigations
into allegations of financial crime and irregularities and performing
internal auditing as per audit calendar.
    </p>
  </div>
  <a href="mailto:jobs@izb.co.zm?subject=APPLICATION:%20AUDIT%20OFFICER%20(X1)" className="btn btn-link" style={{border: "1px solid #9e1b1e", backgroundColor: "#9e1b1e",textDecoration:"none", color:"#fff", margin:"1vw", height:"2.5vw"}}>Email Us Your Resume</a>

</div>



          <div className="container">
          <div className="mt-3">
            <div className="col-xl-6">
              <div className="faq-style1__content">
                <ul className="accordion-box">
                <li className="accordion block active-block">
      <div className="acc-btn active">
        <div className="icon-outer">
            { show? <AiOutlineArrowRight/> : <AiOutlineArrowUp />

            }
        </div>
        <h3 onClick={() => setShow(!show)} style={{textAlign:"left"}}>Essential Duties and Responsibilities</h3>
      </div>
      <div className="acc-content current" style={{textAlign:"left"}}>
       {show && <p className="px-4">
        <p><strong>Under the supervision of the Chief Internal Auditor, the
following are among the Job Key Responsibilities:</strong></p>
       <ul style={{listStyleType:"disc"}}>
       <li>•	 Performing internal audits as per internal audit calendar</li>
<li>•	 Performing investigations across the bank at the request
of management. Document investigation findings in reports
and risk events and circulate to management for action.</li>
<li>•	 Assesing and determine need and level of an investigations
on all fraud risk events reported to the Unit.</li>
<li>•	 Independently and objectively plan and execute investigations
in accordance with professional standards. In addition, the
incumbent will have to keep a tracking action list of all audit
issues relating to Fraud risk.</li>
<li>•	 Identifying fraud prone areas for vigilance and snap checks
and recommend managements actions to mitigate identified
control weaknesses and for consequence management.</li>
<li>•	 Coordinating annual fraud risk assessments to ensure potential
threats and vulnerabilities for business processes and the
strategy are identified and actions defined for mitigation.</li>
<li>•	 Reporting all fraud cases to law enforcement agencies
and escalate fraud incidents for insurance claim within the
agreed timeframe.</li>
<li>•	 Attending to civil and criminal proceedings on behalf of
the bank.</li>
<li>•	 Conducting fraud awareness across the bank and external
customers.</li>
<li>•	 Prepare annual Fraud Risk Management Strategy for Management approval</li>
<li>•	 Create and maintain a bank-wide fraud risk register to ensure
that all identified risk factors are accounted for.</li>
<li>•	 Participate in New Product Assessments to ensure related
fraud risks are identified and adequate controls are put in
place for launch of the product</li>
<li>•	 Drive fraud rules for transaction monitoring from the
assessments.</li>

    </ul>
        </p>}
      </div>
    </li>
                </ul>
              </div>
            </div>
       
          </div>
          <div className="mt-3">
            <div className="col-xl-6">
              <div className="faq-style1__content">
                <ul className="accordion-box">
                <li className="accordion block active-block">
      <div className="acc-btn active">
        <div className="icon-outer">
            { show? <AiOutlineArrowRight/> : <AiOutlineArrowUp />

            }
        </div>
        <h3 onClick={() => setShow1(!show1)}  style={{textAlign:"left"}}>Required Skills and Attributes</h3>
      </div>
      <div className="acc-content current"  style={{textAlign:"left"}}>
       {show1 && <p className="px-4">
       <ul style={{listStyleType:"disc"}}>
       <li>•	 Interviewing techniques.</li>
<li>•	 Analysis and attention to detail.</li>
<li>•	 Business acumen.</li>
<li>•	 Problem solving.</li>
        </ul>
        </p>}
      </div>
    </li>
                </ul>
              </div>
            </div>
       
          </div>
          <div className="mt-3">
            <div className="col-xl-6">
              <div className="faq-style1__content">
                <ul className="accordion-box">
                <li className="accordion block active-block">
      <div className="acc-btn active">
        <div className="icon-outer">
            { show? <AiOutlineArrowRight/> : <AiOutlineArrowUp />

            }
        </div>
        <h3 onClick={() => setShow2(!show2)}  style={{textAlign:"left"}}>Qualifications and Experience</h3>
      </div>
      <div className="acc-content current"  style={{textAlign:"left"}}>
       {show2 && <p className="px-4">
       <ul style={{listStyleType:"disc"}}>
       <li>•	 Bachelor’s degree in business, law, accounts or related field
from a recognized and duly accredited university.</li>
<li>•	 Minimum of 5 years work experience, preferably in the
banking and financial services sector.</li>
<li>•	 Law Enforcement background and Certified Fraud Examiner
or other fraud or Forensic related certifications as an added
advantage.</li>
        </ul>
        </p>}
      </div>
    </li>
                </ul>
              </div>
            </div>
       
          </div>
        </div>
        <hr className="mx-5" style={{color:"#9e1b1e",border:"2px solid #9e1b1e"}}/>






<h3 className="my-3 mx-5" style={{border:"1px solid", padding:"1vw", color:"white",background:"rgb(158, 27, 30)"}}>INFORMATION TECHNOLOGY</h3>
<h4 className="my-3 mx-5" style={{ color: "#9E1B1E", textAlign:"left" }}>3. MANAGER ICT - PROJECT MANAGEMENT (X1)</h4>
          <div className="d-flex mx-5">
          <div className="izb">
    <h5 className="mt-3 mx-5" style={{ color: "black" }}>
      Job Purpose
    </h5>
    <p className="mx-5">
    This role will oversee and manage all aspects of ICT project
phases including proactive management of project risks and
issues and work diligently to ensure that project deliverables
match agreed-upon schedule, scope and cost dimensions.

    </p>
  </div>
  <a href="mailto:jobs@izb.co.zm?subject=APPLICATION:%20MANAGER%20ICT%20-%20PROJECT%20MANAGEMENT%20(X1)" className="btn btn-link" style={{border: "1px solid #9e1b1e", backgroundColor: "#9e1b1e",textDecoration:"none", color:"#fff", margin:"1vw", height:"2.5vw"}}>Email Us Your Resume</a>

</div>



          <div className="container">
          <div className="mt-3">
            <div className="col-xl-6">
              <div className="faq-style1__content">
                <ul className="accordion-box">
                <li className="accordion block active-block">
      <div className="acc-btn active">
        <div className="icon-outer">
            { show? <AiOutlineArrowRight/> : <AiOutlineArrowUp />

            }
        </div>
        <h3 onClick={() => setShow(!show)} style={{textAlign:"left"}}>Essential Duties and Responsibilities</h3>
      </div>
      <div className="acc-content current" style={{textAlign:"left"}}>
       {show && <p className="px-4">
        <p><strong>Under the supervision of the Head Information Technology,
the following are among the Job Key Responsibilities: </strong></p>
       <ul style={{listStyleType:"disc"}}>
       <li>•	 Drafting and revising business cases, project plans, statements
of works and vendor provided artifacts, highlighting potential
issues and dependencies and recommend ways for ensuring
success;</li>
<li>•	 Preparing presentations and briefings material, status reports
for the Senior Management Team and other stakeholder
groups.</li>
<li>•	 Ensuring that systems requirements analysis provides
sufficient formality and records for effectively communicating,
coordinating, executing and quality assuring tasks between
many onsite and offsite contributors.</li>
<li>•	 Keeping abreast of developments in the ICT field especially
in relation to emerging technologies (machine learning and
block chain) and their impact.</li>
<li>•	 Being a critical resource in product development and provision
of ICT support to all bank functions and ensures delivery of
strategic initiatives and products.</li>
    </ul>
        </p>}
      </div>
    </li>
                </ul>
              </div>
            </div>
       
          </div>
          <div className="mt-3">
            <div className="col-xl-6">
              <div className="faq-style1__content">
                <ul className="accordion-box">
                <li className="accordion block active-block">
      <div className="acc-btn active">
        <div className="icon-outer">
            { show? <AiOutlineArrowRight/> : <AiOutlineArrowUp />

            }
        </div>
        <h3 onClick={() => setShow1(!show1)}  style={{textAlign:"left"}}>Required Skills and Attributes</h3>
      </div>
      <div className="acc-content current"  style={{textAlign:"left"}}>
       {show1 && <p className="px-4">
       <ul style={{listStyleType:"disc"}}>
     

<li>•	 Highly analytical and logical thinking with proven leadership,
problem-solving, and critical thinking abilities.</li>
<li>•	 Excellent oral and written communication skills.</li>
<li>•	 Must be honest with integrity, courteous, a team player,
innovative, proactive, self-starter, and dedicated to duty.</li>

        </ul>
        </p>}
      </div>
    </li>
                </ul>
              </div>
            </div>
       
          </div>
          <div className="mt-3">
            <div className="col-xl-6">
              <div className="faq-style1__content">
                <ul className="accordion-box">
                <li className="accordion block active-block">
      <div className="acc-btn active">
        <div className="icon-outer">
            { show? <AiOutlineArrowRight/> : <AiOutlineArrowUp />

            }
        </div>
        <h3 onClick={() => setShow2(!show2)}  style={{textAlign:"left"}}>Qualifications and Experience</h3>
      </div>
      <div className="acc-content current"  style={{textAlign:"left"}}>
       {show2 && <p className="px-4">
       <ul style={{listStyleType:"disc"}}>
       <li>•	 Grade 12 Certificate with Credit or above mandatory in
Mathematics and English and any other three subjects.</li>
<li>•	 Minimum bachelor’s degree in computer science/Computer
Engineering/Information Technology/Information Systems
from a recognized university.</li>
<li>•	 Master’s Degree, ideally in Project Management, Business
Administration, Information Technology or a related subject.</li>
<li>•	 Must have a minimum of 8 years’ experience (3) years of
relevant work experience in ICT Project Management/Project
Delivery or ICT Portfolio Planning and Prioritization.</li>
<li>•	 Must have strong technical knowledge of project, programmer
and portfolio management techniques and methodologies,
preferably evidenced by a certification in good standing, and
of applying them for delivering successful organizational
change.</li>
        </ul>
        </p>}
      </div>
    </li>
                </ul>
              </div>
            </div>
       
          </div>
        </div>
        <hr className="mx-5" style={{color:"#9e1b1e",border:"2px solid #9e1b1e"}}/>







        




{/* <h3 className="my-3 mx-5" style={{border:"1px solid", padding:"1vw", color:"white",background:"rgb(158, 27, 30)"}}>IINFORMATION TECHNOLOGY</h3> */}
<h4 className="my-3 mx-5" style={{ color: "#9E1B1E", textAlign:"left" }}>4. INFORMATION TECHNOLOGY OFFICER – CORE BANKING
SYSTEMS (X3)</h4>
          <div className="d-flex mx-5">
          <div className="izb">
    <h5 className="mt-3 mx-5" style={{ color: "black" }}>
      Job Purpose
    </h5>
    <p className="mx-5">
    The role will provide technical and functional support on Flex
cube core-banking system and its related interfaces. The role
holder will provide end – user support through close coordination
with the Flex cube software vendors and create new branches,
products, features, and modules up on request. Enhance the
FLEXCUBE system using Oracle Development Workbench.

    </p>
  </div>
  <a href="mailto:jobs@izb.co.zm?subject=APPLICATION:%20INFORMATION%20TECHNOLOGY%20OFFICER%20-%20CORE%20BANKING%20SYSTEMS%20(X3)" className="btn btn-link" style={{border: "1px solid #9e1b1e", backgroundColor: "#9e1b1e",textDecoration:"none", color:"#fff", margin:"1vw", height:"2.5vw"}}>Email Us Your Resume</a>

</div>



          <div className="container">
          <div className="mt-3">
            <div className="col-xl-6">
              <div className="faq-style1__content">
                <ul className="accordion-box">
                <li className="accordion block active-block">
      <div className="acc-btn active">
        <div className="icon-outer">
            { show? <AiOutlineArrowRight/> : <AiOutlineArrowUp />

            }
        </div>
        <h3 onClick={() => setShow(!show)} style={{textAlign:"left"}}>Essential Duties and Responsibilities</h3>
      </div>
      <div className="acc-content current" style={{textAlign:"left"}}>
       {show && <p className="px-4">
        <p><strong>Under the supervision of the Senior Manager Information
Technology, the following are among the Job Key
Responsibilities: </strong></p>
       <ul style={{listStyleType:"disc"}}>
       <li>•	 Monitoring and reviewing the successful execution of End of Day (EOD), month-end, and End of Year (EOY) activities
performed by Core Systems Analysts.</li>
<li>•	 Checking and reporting daily to Core Systems & Development
Manager on any system challenges and interventions within
the set SLAs.</li>
<li>•	 Reviewing and updating procedures and manuals in line
with changes in business processes, requirements, and
Technology operating environment.</li>
<li>•	 Managing the testing and application of patches into the Core
Banking System (Flex cube) in line with patch management
and change management policies.</li>
<li>•	 Reviewing systems backup logs and checking compliance
with data management and backup policy.</li>
<li>•	 Reviewing the Technology Operations Incidents and submitting
a report with recommendations to the Senior Manager IT.</li>
<li>•	 Reviewing and authorizing the creation of Core Banking
System (Flex cube) and peripheral systems user profiles.</li>
<li>•	 Reviewing and consolidating the performance trends
regarding capacity usage, system response time, and error
rates and submits a report with recommendations to the
Senior Manager - IT.</li>
    </ul>
        </p>}
      </div>
    </li>
                </ul>
              </div>
            </div>
       
          </div>
          <div className="mt-3">
            <div className="col-xl-6">
              <div className="faq-style1__content">
                <ul className="accordion-box">
                <li className="accordion block active-block">
      <div className="acc-btn active">
        <div className="icon-outer">
            { show? <AiOutlineArrowRight/> : <AiOutlineArrowUp />

            }
        </div>
        <h3 onClick={() => setShow1(!show1)}  style={{textAlign:"left"}}>Required Skills and Attributes</h3>
      </div>
      <div className="acc-content current"  style={{textAlign:"left"}}>
       {show1 && <p className="px-4">
       <ul style={{listStyleType:"disc"}}>
     
       <li>•	 Excellent oral and written communication skills.</li>
<li>•	 Highly analytical and ability to think logically.</li>
<li>•	 Ability to work independently.</li>
<li>•	 Must be honest with integrity, courteous, team player,
proactive, innovative, self-starter, and dedicated to duty.</li>
<li>•	 Knowledge of Flexible core banking systems is a must.</li>

        </ul>
        </p>}
      </div>
    </li>
                </ul>
              </div>
            </div>
       
          </div>
          <div className="mt-3">
            <div className="col-xl-6">
              <div className="faq-style1__content">
                <ul className="accordion-box">
                <li className="accordion block active-block">
      <div className="acc-btn active">
        <div className="icon-outer">
            { show? <AiOutlineArrowRight/> : <AiOutlineArrowUp />

            }
        </div>
        <h3 onClick={() => setShow2(!show2)}  style={{textAlign:"left"}}>Qualifications and Experience</h3>
      </div>
      <div className="acc-content current"  style={{textAlign:"left"}}>
       {show2 && <p className="px-4">
       <ul style={{listStyleType:"disc"}}>
       <li>•	 Grade 12 Certificate with Credit or above mandatory in
Mathematics and English and any other three subjects.</li>
<li>•	 Minimum bachelor’s degree in computer science / Computer
Engineering / Information Technology / Information Systems
from a recognized university.</li>
<li>•	 At least 5 years in the ICT field in a banking environment.</li>
<li>•	 Relevant IT Certification.</li>
        </ul>
        </p>}
      </div>
    </li>
                </ul>
              </div>
            </div>
       
          </div>
        </div>
        <hr className="mx-5" style={{color:"#9e1b1e",border:"2px solid #9e1b1e"}}/>




        <h3 className="my-3 mx-5" style={{border:"1px solid", padding:"1vw", color:"white",background:"rgb(158, 27, 30)"}}>CORPORATE SERVICES AND ADMINISTRATION</h3>
<h4 className="my-3 mx-5" style={{ color: "#9E1B1E", textAlign:"left" }}>5. PROCUREMENT AND ADMINISTRATION MANAGER (X1)</h4>
          <div className="d-flex mx-5">
          <div className="izb">
    <h5 className="mt-3 mx-5" style={{ color: "black" }}>
      Job Purpose
    </h5>
    <p className="mx-5">
    This role is responsible for the acquisition of goods and services
for the bank and ensures that they are delivered in accordance
with agreed standards and cost as well as management of
contracts to ensure that the Bank’s interest is protected in the
procurement of goods and services.

    </p>
  </div>
  <a href="mailto:jobs@izb.co.zm?subject=APPLICATION:%20PROCUREMENT%20AND%20ADMINISTRATION%20MANAGER%20(X1)" className="btn btn-link" style={{border: "1px solid #9e1b1e", backgroundColor: "#9e1b1e",textDecoration:"none", color:"#fff", margin:"1vw", height:"2.5vw"}}>Email Us Your Resume</a>

</div>



          <div className="container">
          <div className="mt-3">
            <div className="col-xl-6">
              <div className="faq-style1__content">
                <ul className="accordion-box">
                <li className="accordion block active-block">
      <div className="acc-btn active">
        <div className="icon-outer">
            { show? <AiOutlineArrowRight/> : <AiOutlineArrowUp />

            }
        </div>
        <h3 onClick={() => setShow(!show)} style={{textAlign:"left"}}>Essential Duties and Responsibilities</h3>
      </div>
      <div className="acc-content current" style={{textAlign:"left"}}>
       {show && <p className="px-4">
        <p><strong>Under the supervision of the Procurement Committee
on procurement matters and Head of Administration on
administrative matters, the following are among the Job Key
Responsibilities: </strong></p>
       <ul style={{listStyleType:"disc"}}>
       <li>•	 Driving strategic sourcing through management of all strategic
tenders and management of strategic vendors.</li>
<li>•	 Creating and managing the procurement budget including
forecasting procurement needs and expenses</li>
<li>•	 Identifying, delivering, managing and recording cost saving
initiatives.</li>
<li>•	 Contracting administration for all contracts with suppliers
to drive continual quality and cost improvements.</li>
<li>•	 Identifying and researching potential new suppliers to be
added to the supplier list and keep a pre-qualified list of
approved vendors for the respective Unit.</li>
<li>•	 Managing the end-to-end vendor acquisition process</li>
<li>•	 Conducting tender evaluations in accordance with the
procurement procedures and legal guidelines</li>
<li>•	 Negotiating with suppliers to ensure that Commercial Terms
of all contracts bank-wide have adequately covered the
banks interest and are in line with Bank Policy.</li>
<li>•	 Coordinating Management Tender Committee meetings and
ensuring records of minutes of meetings held are drafted
and filled.</li>
<li>•	 Providing guidance to Business Units on all procurement
related matters in accordance with Procurement Policy.</li>
<li>•	 Ensuring timely supply and management of all fast moving
back stock ensuring adequate measurement of re-order
levels and lead times</li>
<li>•	 Engaging and conducting stakeholder strategic meetings
monthly with Heads of Departments.</li>
<li>•	 Maintaining records of Lease Agreements for leased properties
and other service Level Agreements for vendors.</li>
<li>•	 Ensuring proper management and maintenance of the
bank’s properties and facilities and recommend ongoing
improvements to Management.</li>
<li>•	 Planning, Coordinating and Managing of Bank’s expansion
programmes including other strategic projects and initiatives.</li>
    </ul>
        </p>}
      </div>
    </li>
                </ul>
              </div>
            </div>
       
          </div>
          <div className="mt-3">
            <div className="col-xl-6">
              <div className="faq-style1__content">
                <ul className="accordion-box">
                <li className="accordion block active-block">
      <div className="acc-btn active">
        <div className="icon-outer">
            { show? <AiOutlineArrowRight/> : <AiOutlineArrowUp />

            }
        </div>
        <h3 onClick={() => setShow1(!show1)}  style={{textAlign:"left"}}>Required Skills and Attributes</h3>
      </div>
      <div className="acc-content current"  style={{textAlign:"left"}}>
       {show1 && <p className="px-4">
       <ul style={{listStyleType:"disc"}}>
     
       <li>•	Proven understanding of Supply Chain Management
procedures and logistics.</li>
<li>•	 Good interpersonal, communication, negotiation, data analytics, decision making skills.</li>
<li>•	 Sound Strategic planning abilities.</li>


        </ul>
        </p>}
      </div>
    </li>
                </ul>
              </div>
            </div>
       
          </div>
          <div className="mt-3">
            <div className="col-xl-6">
              <div className="faq-style1__content">
                <ul className="accordion-box">
                <li className="accordion block active-block">
      <div className="acc-btn active">
        <div className="icon-outer">
            { show? <AiOutlineArrowRight/> : <AiOutlineArrowUp />

            }
        </div>
        <h3 onClick={() => setShow2(!show2)}  style={{textAlign:"left"}}>Qualifications and Experience</h3>
      </div>
      <div className="acc-content current"  style={{textAlign:"left"}}>
       {show2 && <p className="px-4">
       <ul style={{listStyleType:"disc"}}>
       <li>• Grade 12 Certificate with Credit or above mandatory in
Mathematics and English and any other three subjects.</li>
<li>•	 University Degree in Purchasing & Supply Management or
any other relevant field.</li>
<li>•	 Minimum 8 years’ work experience in strategic sourcing.</li>
<li>•	 Demonstrated understating of Supply Chain Management
procedures and logistics.</li>
<li>•	 Member of the Zambia Institute of Purchasing and Supply
(ZIPS).</li>
        </ul>
        </p>}
      </div>
    </li>
                </ul>
              </div>
            </div>
       
          </div>
        </div>
        <hr className="mx-5" style={{color:"#9e1b1e",border:"2px solid #9e1b1e"}}/>


















        {/* <h3 className="my-3 mx-5" style={{border:"1px solid", padding:"1vw", color:"white",background:"rgb(158, 27, 30)"}}>CORPORATE SERVICES AND ADMINISTRATION</h3> */}
<h4 className="my-3 mx-5" style={{ color: "#9E1B1E", textAlign:"left" }}>6. BRAND MARKETING & COMMUNICATIONS MANAGER (X1)</h4>
          <div className="d-flex mx-5">
          <div className="izb">
    <h5 className="mt-3 mx-5" style={{ color: "black" }}>
      Job Purpose
    </h5>
    <p className="mx-5">
    This role is responsible for overseeing the Bank’s overall
marketing and communications strategy

    </p>
  </div>
  <a href="mailto:jobs@izb.co.zm?subject=APPLICATION:%20BRAND%20MARKETING%20AND%20COMMUNICATIONS%20MANAGER%20(X1)" className="btn btn-link" style={{border: "1px solid #9e1b1e", backgroundColor: "#9e1b1e",textDecoration:"none", color:"#fff", margin:"1vw", height:"2.5vw"}}>Email Us Your Resume</a>

</div>



          <div className="container">
          <div className="mt-3">
            <div className="col-xl-6">
              <div className="faq-style1__content">
                <ul className="accordion-box">
                <li className="accordion block active-block">
      <div className="acc-btn active">
        <div className="icon-outer">
            { show? <AiOutlineArrowRight/> : <AiOutlineArrowUp />

            }
        </div>
        <h3 onClick={() => setShow(!show)} style={{textAlign:"left"}}>Essential Duties and Responsibilities</h3>
      </div>
      <div className="acc-content current" style={{textAlign:"left"}}>
       {show && <p className="px-4">
        <p><strong>Under the supervision of the Head of Administration the
following are among the Job Key Responsibilities: </strong></p>
       <ul style={{listStyleType:"disc"}}>
       <li>• Identifying target markets and develop strategies to
communicate with and appeal to them, conduct research
into attaining customer insights</li>
<li>•	 Providing input into the development of the Bank’s brand
and develop comprehensive brand guidelines to ensure
effective brand management.</li>
<li>•	 Formulating and implementing a long-term Retail and Corporate
marketing and communication Strategy for pushing Banks
products to the target market segments.</li>
<li>•	 Ensuring a consistent brand message is delivered across
all communication channels and to all segments.</li>
<li>•	 Developing relationships with key media to secure and grow
media coverage both online and offline.</li>
<li>•	 Planning, coordinating and implementing marketing and
promotional campaigns in order to enhance IZB’s image in
the market and increase brand awareness.</li>
<li>•	 Monitoring and analyzing market trends and recommending
changes to marketing and business development strategies
based on analysis and feedback.</li>
<li>•	 Developing value propositions aligned to customer expectations
and maintaining effective internal communications to
ensure that all relevant Bank functions are kept informed
of marketing objectives.</li>
<li>•	 Recommending supportive activities for introducing
products and channels on the market in consultation with
the marketing division.</li>
<li>•	 Developing incentive programs for the increase of sales
through the use of market research results and also monitoring
competitor activities.</li>
    </ul>
        </p>}
      </div>
    </li>
                </ul>
              </div>
            </div>
       
          </div>
          <div className="mt-3">
            <div className="col-xl-6">
              <div className="faq-style1__content">
                <ul className="accordion-box">
                <li className="accordion block active-block">
      <div className="acc-btn active">
        <div className="icon-outer">
            { show? <AiOutlineArrowRight/> : <AiOutlineArrowUp />

            }
        </div>
        <h3 onClick={() => setShow1(!show1)}  style={{textAlign:"left"}}>Required Skills and Attributes</h3>
      </div>
      <div className="acc-content current"  style={{textAlign:"left"}}>
       {show1 && <p className="px-4">
       <ul style={{listStyleType:"disc"}}>
     
       <li>• Highly conversant and proficient with Microsoft packages.</li>
<li>•	 Good communications, interpersonal and organizational skills.</li>
<li>•	 Presentation and Facilitation skills.</li>
<li>•	 Strong propensity towards Aesthetics.</li>
<li>•	 Decision making and judgment.</li>


        </ul>
        </p>}
      </div>
    </li>
                </ul>
              </div>
            </div>
       
          </div>
          <div className="mt-3">
            <div className="col-xl-6">
              <div className="faq-style1__content">
                <ul className="accordion-box">
                <li className="accordion block active-block">
      <div className="acc-btn active">
        <div className="icon-outer">
            { show? <AiOutlineArrowRight/> : <AiOutlineArrowUp />

            }
        </div>
        <h3 onClick={() => setShow2(!show2)}  style={{textAlign:"left"}}>Qualifications and Experience</h3>
      </div>
      <div className="acc-content current"  style={{textAlign:"left"}}>
       {show2 && <p className="px-4">
       <ul style={{listStyleType:"disc"}}>
       <li>• Grade 12 Certificate with Credit or above mandatory in
Mathematics and English and any other three subjects.</li>
<li>•	 Must possess a university degree with a credit or better in
any business-related field from a reputable university.</li>
<li>•	 Master’s Degree or equivalent Professional Banking & Finance
certification will be an added advantage.</li>
<li>•	 Minimum Experience: 8+ years, with 3 years’ experience in
leading Marketing Strategy.</li>
<li>•	 Must possess relevant sales and marketing accreditations
and certifications, most notably membership to the Zambia
Institute of Marketing.</li>
        </ul>
        </p>}
      </div>
    </li>
                </ul>
              </div>
            </div>
       
          </div>
        </div>
        <hr className="mx-5" style={{color:"#9e1b1e",border:"2px solid #9e1b1e"}}/>















<h3 className="my-3 mx-5" style={{border:"1px solid", padding:"1vw", color:"white",background:"rgb(158, 27, 30)"}}>OPERATIONS/ DIGITAL BANKING </h3>
<h4 className="my-3 mx-5" style={{ color: "#9E1B1E", textAlign:"left" }}>7. MANAGER – DIGITAL BANKING (X1)</h4>
          <div className="d-flex mx-5">
          <div className="izb">
    <h5 className="mt-3 mx-5" style={{ color: "black" }}>
      Job Purpose
    </h5>
    <p className="mx-5">
    This role oversees the digital unit responsible for digital channels,
innovations, operations and Digital Sales to grow and retain a
portfolio of Retail and Digital banking customers and to achieve
the Retail and Digital business targets. (Agency Banking, Digital
Acquisition and Merchant Sales).

    </p>
  </div>
  <a href="mailto:jobs@izb.co.zm?subject=APPLICATION:%20MANAGER%20-%20DIGITAL%20BANKING%20(X1)" className="btn btn-link" style={{border: "1px solid #9e1b1e", backgroundColor: "#9e1b1e",textDecoration:"none", color:"#fff", margin:"1vw", height:"2.5vw"}}>Email Us Your Resume</a>
  

</div>



          <div className="container">
          <div className="mt-3">
            <div className="col-xl-6">
              <div className="faq-style1__content">
                <ul className="accordion-box">
                <li className="accordion block active-block">
      <div className="acc-btn active">
        <div className="icon-outer">
            { show? <AiOutlineArrowRight/> : <AiOutlineArrowUp />

            }
        </div>
        <h3 onClick={() => setShow(!show)} style={{textAlign:"left"}}>Essential Duties and Responsibilities</h3>
      </div>
      <div className="acc-content current" style={{textAlign:"left"}}>
       {show && <p className="px-4">
        <p><strong>Under the supervision of the Head – Operations the following
are among the Job Key Responsibilities: </strong></p>
       <ul style={{listStyleType:"disc"}}>
       <li>• Implementing of strategies for growth of Alternative Distribution
Channels of the bank.</li>
<li>•	 Driving business growth of Agency Network, Point of Sale
Merchants, Mobile Banking, Internet Banking, Digital and
Wallet Account customer banking.</li>
<li>•	 Driving growth of Retail Customer Base via Digital Solutions
and Product uptake.</li>
<li>•	 Aligning of strategy to technology trends and provides
competitive response.</li>
<li>•	 Identifying and take ownership of generating sales opportunities
through various events and marketing efforts.</li>
<li>•	 Delivering increased digital sales across IZB.</li>
<li>•	 Growing revenue in line with KPIs.</li>
<li>•	 Seeking opportunities to integrate simplify and streamline
activities through involvement of teams in the geographic
and functional matrix.</li>
<li>•	 Monitoring post-acquisition engagement level with the
following: Clients and Agents.</li>
<li>•	 Monitoring the service level efficiency of Agents and Merchants.</li>
<li>•	 Developing and enhancing digital channels for enhancing
customer experience and product and service delivery.</li>
<li>•	 Managing system infrastructure uptime as per agreed SLA.</li>
<li>•	 Overseeing and maintaining a secured IT platform for the
digital banking operations.</li>
<li>•	 Designing and developing and implementing the Digital
Banking operations, IT and sales strategy and customer
solutions.</li>
<li>•	 Effectively managing digital and IT vendors.</li>
<li>•	 Project management of technology within the digital unit.</li>
<li>•	 Supporting the client, agent and merchant acquisition sales
team through technology enablement.</li>
<li>•	 Providing support to the product team in developing innovative
digital products.</li>
    </ul>
        </p>}
      </div>
    </li>
                </ul>
              </div>
            </div>
       
          </div>
          <div className="mt-3">
            <div className="col-xl-6">
              <div className="faq-style1__content">
                <ul className="accordion-box">
                <li className="accordion block active-block">
      <div className="acc-btn active">
        <div className="icon-outer">
            { show? <AiOutlineArrowRight/> : <AiOutlineArrowUp />

            }
        </div>
        <h3 onClick={() => setShow1(!show1)}  style={{textAlign:"left"}}>Qualifications and Experience</h3>
      </div>
      <div className="acc-content current"  style={{textAlign:"left"}}>
       {show1 && <p className="px-4">
       <ul style={{listStyleType:"disc"}}>
     
       <li>• Grade 12 Certificate with Credit or above mandatory in
Mathematics and English and any other three subjects.</li>
<li>•	 Must possess a university degree with a credit or better in
any business-related field from a reputable university.</li>
<li>•	 Master’s Degree or equivalent in a technological or related
program will be an added advantage.</li>
<li>•	 Minimum experience of 8 years preferably with a Mobile
Network Operator or financial institutions, with 3 years at
senior Management level.</li>
<li>•	 Must be tech savvy</li>

        </ul>
        </p>}
      </div>
    </li>
                </ul>
              </div>
            </div>
       
          </div>
       
        </div>
        <hr className="mx-5" style={{color:"#9e1b1e",border:"2px solid #9e1b1e"}}/>

        <h5 className="mt-3 mx-5" style={{ color: "black", textAlign:"left" }}>
        APPLICATION PROCESS: Applications for the above positions should be submitted
via email, clearly indicating the position being applied for
in the subject line to jobs@izb.co.zm. Applications should
include clearly scanned copies of the Curriculum Vitae,
Certifications and other supporting documents. Please note
that physical applications will NOT be accepted.
    </h5>
        <h5 className="mt-3 mx-5" style={{ color: "black", textAlign:"left" }}>
      NOTE: Closing date for all applications is 21st July 2023.
    </h5>
        
      {/* <AllAcountsSection /> */}
      <CustomerCare />
      <OurStores />
    </>
  );
};

export default Career;