import React from "react";
import Breadcrumb from "../../Components/Breadcrumb/Breadcrumb";
import AllAcountsSection from "../../Components/AllAccountsSection/AllAcountsSection";
import SavePlusAccountComponent from "../../Components/SavePlusAccount/SavePlusAccountComponent";
import ContainsArea from "../../Components/ContainsArea/ContainsArea";


import {
  AiOutlineArrowRight,
  AiOutlineArrowUp,
  AiFillCaretRight,
} from "react-icons/ai";
import { accountContains } from "../../Data/ContainAreaData";
import { useState } from "react";
import { useEffect } from "react";
console.log(accountContains);

const IndoHomeLoan = () => {
  const BackgroundBrundcrumb = "https://techrowth.s3.eu-north-1.amazonaws.com/images/IZB/IZB+Website+Skin/Personal_Loan/Image_1.png";
const img1 = "https://techrowth.s3.eu-north-1.amazonaws.com/images/IZB/IZB+Website+Skin/Personal+Acounts/6.png";
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const accountContent =
    "Looking to buy, build or renovate. Spruce up your property portfolio with an IZB home loan. Whether you would like a new kitchen or want to give your lounge a new lease of life, you could get more from your home.";
  const data = [
    "Eligibility",
    "Purpose",
    "security",
    "customers contribution",
    "Repayment Period",
  ];
  const [show, setShow] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
      <Breadcrumb Heading="Indo Home Loan" img={BackgroundBrundcrumb} show={true} btnTxt="Apply Now"/>
      <SavePlusAccountComponent
        heading="Indo Home Loan"
        subHeading="Know About"
        btnTxt="Apply Now"
        content={accountContent}
        img={img1}
        logo={true}
      />
      <ContainsArea title={accountContains} />
      <section>
        <div className="container">
          <div className="row">
            <div className="col-xl-12 justify-content-center">
              <div className="faq-style1__content">
                <ul className="accordion-box mt-5">
                  <li className="accordion block active-block">
                    <div
                      className="acc-btn active"
                      style={{
                        backgroundColor: "#9E1B1E",
                        backgroundSize: "20px",
                      }}
                    >
                      <div className="icon-outer text-white">
                        {show ? <AiOutlineArrowRight /> : <AiOutlineArrowUp />}
                      </div>
                      <h3 className="text-white" onClick={() => setShow(!show)}>
                        Eligibility
                      </h3>
                    </div>
                    <div className="acc-content current">
                      {show && (
                        <p className="px-5">
                          <AiFillCaretRight className="me-1" />
                          Salaried and unsalaried having a legal identity with a regular source of income
                        </p>
                      )}
                      {show && (
                        <p className="px-5">
                          <AiFillCaretRight className="me-1" />
                          Parents, Spouse & Children can be accepted as co-borrowers in order to improve the entitlement/repayment capacity of the borrower
                        </p>
                      )}
                    </div>
                  </li>
                  <li className="accordion block active-block">
                    <div
                      className="acc-btn active"
                      style={{
                        backgroundColor: "#9E1B1E",
                        backgroundSize: "20px",
                      }}
                    >
                      <div className="icon-outer text-white">
                        {show ? <AiOutlineArrowRight /> : <AiOutlineArrowUp />}
                      </div>
                      <h3 className="text-white" onClick={() => setShow(!show)}>
                        Purpose
                      </h3>
                    </div>
                    <div className="acc-content current">
                      {show && (
                        <p className="px-5">
                          <AiFillCaretRight className="me-1" />
                          Outright Purchase of a ready-made
                        </p>
                      )}
                      {show && (
                        <p className="px-5">
                          <AiFillCaretRight className="me-1" />
                          Purchase of a Plot and construction of a house or
                          construction of a house on an already existing plot,
                          within a maximum period of 18 months
                        </p>
                      )}
                      {show && (
                        <p className="px-5">
                          <AiFillCaretRight className="me-1" />
                          Home Improvement – For
                          repairs/renovation/alteration/extension in the
                          existing house/ﬂat
                        </p>
                      )}
                      {show && (
                        <p className="px-5">
                          <AiFillCaretRight className="me-1" />
                          Takeover/Reﬁnance of home loans availed from other
                          ﬁnancial institutions
                        </p>
                      )}
                    </div>
                  </li>
                  <li className="accordion block active-block">
                    <div
                      className="acc-btn active"
                      style={{
                        backgroundColor: "#9E1B1E",
                        backgroundSize: "20px",
                      }}
                    >
                      <div className="icon-outer text-white">
                        {show ? <AiOutlineArrowRight /> : <AiOutlineArrowUp />}
                      </div>
                      <h3 className="text-white" onClick={() => setShow(!show)}>
                        Security
                      </h3>
                    </div>
                    <div className="acc-content current">
                      {show && (
                        <p className="px-5">
                          <AiFillCaretRight className="me-1" />
                          Legal mortgage of property purchased or developed out
                          of bank ﬁnance
                        </p>
                      )}
                    </div>
                  </li>
                  <li className="accordion block active-block">
                    <div
                      className="acc-btn active"
                      style={{
                        backgroundColor: "#9E1B1E",
                        backgroundSize: "20px",
                      }}
                    >
                      <div className="icon-outer text-white">
                        {show ? <AiOutlineArrowRight /> : <AiOutlineArrowUp />}
                      </div>
                      <h3 className="text-white" onClick={() => setShow(!show)}>
                        Customer Contribution
                      </h3>
                    </div>
                    <div className="acc-content current">
                      {show && <h5 className="px-5">FOR SALARIED BORROWER</h5>}
                      {show && (
                        <p className="px-5">
                          <AiFillCaretRight className="me-1" />
                          The Bank can finance up to 90% of the project cost. Terms and conditions apply. 
                        </p>
                      )}
                  
                     
                      {/* <hr style={{ color: "#9E1B1E" }} /> */}
                      {show && <h5 className="px-5">NON-SALARIED BORROWER</h5>}
                      {show && (
                        <p className="px-5">
                          <AiFillCaretRight className="me-1" />
                          The Bank can finance up to 80% of the project cost. Terms and conditions apply.
                        </p>
                      )}
                     
                    
                    </div>
                  </li>
                  <li className="accordion block active-block">
                    <div
                      className="acc-btn active"
                      style={{
                        backgroundColor: "#9E1B1E",
                        backgroundSize: "20px",
                      }}
                    >
                      <div className="icon-outer text-white">
                        {show ? <AiOutlineArrowRight /> : <AiOutlineArrowUp />}
                      </div>
                      <h3 className="text-white" onClick={() => setShow(!show)}>
                        Customer Benefits
                      </h3>
                    </div>
                    <div className="acc-content current">
                      {show && <h5 className="px-5">Competitive Interest Rates</h5>}
                   
                      {/* <hr style={{ color: "#9E1B1E" }} /> */}
                      {show && <h5 className="px-5">Flexible repayment period</h5>}
                     
                    </div>
                  </li>
                </ul>
              </div>
              <div
                className="my-3"
                style={{
                  backgroundColor: "#9E1B1E",
                  padding: "20px 0px 20px 60px",
                }}
              >
                <h6 className="text-white">Terms and Conditions Apply</h6>
              </div>
            </div>
          </div>
        </div>
      </section>
      <AllAcountsSection />
    </>
  );
};

export default IndoHomeLoan;
