import React,{useState} from "react";
import { Link } from "react-router-dom";

import Breadcrumb from "../../Components/Breadcrumb/Breadcrumb";
import AllAcountsSection from "../../Components/AllAccountsSection/AllAcountsSection";
// import BackgroundBrundcrumb from "../../assets/images/IZB/IZB Website Skin/Forign Business/Untitled-1.png";
import SavePlusAccountComponent from "../../Components/SavePlusAccount/SavePlusAccountComponent";
// import img1 from "../../assets/images/IZB/IZB Website Skin/Forign Business/Untitled-2.png";
import bgimg from "../../assets/images/IZB/IZB Website Skin/IZB Save Plus Account/image_6.png";
import { AiFillCaretRight, AiOutlineRight } from "react-icons/ai";
import { useEffect } from "react";
import Arrow_down from "../../assets/images/IZB/arrow_down.png";
import "./Hero.css";


const WatchLive = () => {
  const Logo = "https://techrowth.s3.eu-north-1.amazonaws.com/images/IZB/logo.png";
  const redirectToLiveStream = () => {
    const liveStreamURL = "https://www.youtube.com/live/XcaGj8b9DlY?feature=share";
    window.open(liveStreamURL, "_blank");
  };
  
  const redirectToFacebookLiveStream = () => {
    const liveStreamURL = "https://fb.watch/k_GdV6P_o3/?mibextid=v7YzmG";
    window.open(liveStreamURL, "_blank");
  };
  const [showYouTubePlayer, setShowYouTubePlayer] = useState(false);
  const [selectedButton, setSelectedButton] = useState(null);
  const [showButton2, setShowButton2] = useState(true);
  const handleWatchLive = (buttonId) => {
    setSelectedButton(buttonId);
    setShowYouTubePlayer(buttonId === "myButton2"); // Show YouTube player only for myButton2
    if (buttonId === "myButton2") {
      setShowButton2(false); // Hide myButton2
    }
  };
  const HeroBgimg = {
    // backgroundImage: `url(${HeroBackground})`,
    height: "106vh",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    overflow: "hidden",
  };
  const HeroBgimg1 = {
    // backgroundImage: `url(${HeroBackground})`,
    height: "74vh",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    overflow: "hidden",
  };
  const BackgroundBrundcrumb = "https://techrowth.s3.eu-north-1.amazonaws.com/images/IZB/IZB+Website+Skin/Forign+Business/Untitled-1.png";
const img1 = "https://techrowth.s3.eu-north-1.amazonaws.com/images/IZB/IZB+Website+Skin/Forign+Business/Untitled-2.png";
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const accountContent =
    "Savings accounts can be opened for individuals in US Dollar/Pound Sterling/South African Rand/Euro. Cheque book facility is an option available. With this product, we also offer VISA Chip & Pin Debit Card facility, which is ideal for businessment/employees travelling frequently outside Zambia, who can draw money from any of the VISA enabled ATMs around the world.";
  return (
    <>
 <div style={HeroBgimg} className="HeroSlider10">
          <div className="auto-container sliderContent h-100">
            <div className="h-75 position-relative ">
              <div className="d-flex justify-content-center align-items-center " style={{height:"89%"}}>
             
                <div className="flex-column flex-wrap align-self-xl-center text-center">
               
          
            
            

        
        <div className="youtube-player">
          <iframe
            width="1200"
            height="700"
            src="https://www.youtube.com/embed/XcaGj8b9DlY"
            title="YouTube Live Stream"
            frameborder="0"
            allow="fullscreen"
          ></iframe>
        </div>
    </div>
              
               
              </div>
            </div>
          </div>
        </div>
    
        <div style={HeroBgimg1} className="HeroSlider11">
          <div className="auto-container sliderContent1 h-100">
            <div className="h-75 position-relative ">
              <div className=" justify-content-center align-items-center " style={{height:"100%",fontSize:"13px"}}>
                <div className="flex-column flex-wrap align-self-xl-center text-center">
             
        <div className="youtube-player  my-5" style={{marginTop: "20rem!important"}}>

          <iframe
            width="370"
            height="300"
            src="https://www.youtube.com/embed/XcaGj8b9DlY"
            title="IZB Live Stream"
            frameborder="0"
            allow="fullscreen"
          ></iframe>
        </div>
  
                </div>
              </div>
            </div>
          </div>
        </div>
    </>
  );
};

export default WatchLive;
