import React,{useRef} from "react";
import { useEffect } from "react";
import Breadcrumb1 from "../../Components/Breadcrumb1/Breadcrumb1";
import AllAcountsSection from "../../Components/AllAccountsSection/AllAcountsSection";


// import BackgroundBrundcrumb from "../../assets/images/IZB/BackgroundBrundcrumb.png";
import {
  AiOutlineArrowLeft,
  AiOutlineArrowRight,
  AiOutlineShareAlt,
} from "react-icons/ai";

import { Link } from "react-router-dom";
import { CiClock1 } from "react-icons/ci";
import { BiMessageDetail } from "react-icons/bi";
const Copperminespartnership = () => {
const BackgroundBrundcrumb = "https://techrowth.s3.eu-north-1.amazonaws.com/images/IZB/BackgroundBrundcrumb.png";
const storie1 = "https://techrowth.s3.eu-north-1.amazonaws.com/images/IZB/IZB+Website+Skin/IZB+Save+Plus+Account/storie1.png";
const storie2 = "https://techrowth.s3.eu-north-1.amazonaws.com/images/IZB/IZB+Website+Skin/IZB+Save+Plus+Account/storie2.png";
const storie3 = "https://techrowth.s3.eu-north-1.amazonaws.com/images/IZB/IZB+Website+Skin/IZB+Save+Plus+Account/storie3.png";
const storie4 = "https://techrowth.s3.eu-north-1.amazonaws.com/images/Thejas.png";
const storie5 = "https://techrowth.s3.eu-north-1.amazonaws.com/images/Thejas_2.png";
const storie6 = "https://techrowth.s3.eu-north-1.amazonaws.com/images/Thejas_1.png";
const page1 = "https://techrowth.s3.eu-north-1.amazonaws.com/images/WhatsApp-Image-2023-02-23-at-4.23.12-PM.jpeg";
const arroww = "https://techrowth.s3.eu-north-1.amazonaws.com/images/chevron_right.svg";
const page2 ="https://techrowth.s3.eu-north-1.amazonaws.com/images/REGISTRATION-OF-VENDORSSUPPLIERS-2023--768x1086.jpg";
const page21 = "https://techrowth.s3.eu-north-1.amazonaws.com/images/REGISTRATION-OF-VENDORSSUPPLIERS-2023-p2-768x1086.jpg";
const page3 ="https://techrowth.s3.eu-north-1.amazonaws.com/images/Indo-Konkola-Copper-Mines-Partnership.jpg";

  const sliderRef = useRef(null);
  console.log(sliderRef.current);
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Breadcrumb1 Heading="Indo / Konkola Copper Mines Partnership" Heading1 ="Indo Zambia Bank  |  June 27, 2022" img={BackgroundBrundcrumb} />
      <div className="text-center" style={{marginTop:"5rem",marginBottom:"5rem"}}>
        <img src={page3} alt="" />
       <p className="container" style={{fontSize:"20px",textAlign:"justify"}}><strong>CHINGOLA, 23rd June 2022:</strong> Konkola Copper Mines Plc (KCM) and Indo Zambia Bank have held exploratory discussions to establish a strong business partnership, which would unlock bridge financing to the mining company by the bank to pave the way for growth.

KCM Provisional Liquidator Celine Nair, who is also the acting Administrator General and Official Receiver of Zambia, held business talks with Indo Zambia Bank Managing Director Kowdichar Shashidhar and other senior bank officials during a visit to KCM in Chingola. The Provisional Liquidator’s team comprised her agent Jason Kazilimani, the acting Chief Executive Officer Enock Mponda and other senior management officials.
The discussions centred on creating synergies to ensure potential structured financing of mining operations, possible lending by the bank to employees of KCM and other facilities that would assist in unlocking some of the untapped mining activities of Konkola Copper Mines.

Indo Zambia Bank expressed their desire to explore and partner with KCM in the short to medium term. The bank is also willing to explore possibilities of extending credit lines and other facilities.
“KCM is looking at doing business with Indo Zambia Bank through a win-win situation. We would like to create a strong mutually beneficial business partnership with Indo Zambia Bank,” Ms Nair said.
Ms Nair said her office would continue to strengthen relationships with lending institutions to in order to create a strong platform for take-off by a new investor in KCM for continuity in mining operations. The government of Zambia is looking for an investor to take over the running of KCM.

“The global market is presenting opportunities for entities like KCM and we as Indo Zambia Bank would like to be part of the support to unlock such opportunities,” the Indo Zambia Managing Director, Mr Shashidar said.

The two parties agreed to explore pooled support to Community initiatives such as tree planting to aid a green economy, which is the thrust of the New Dawn Government for environmental promotion activities, and also in the areas of promoting various sports disciplines.</p>

      </div>
      <section>
      <div className="container">
        <div className="row">
          <div className="container">
            <div className="heading ">
              <h2 className="text-left" style={{color:"#9E1B1E",fontWeight:"450"}}>More to Explore</h2>
             
            </div>
            <div
              className="py-4"
              style={{ float: "right"}}
            >
         
            </div>
          </div>

          {/* <Slider ref={sliderRef} {...settings}>  */}
          
          
          
        
          <div className="col-xl-4 col-lg-4 h-100">
            <div
              className="single-blog-style1 wow fadeInUp"
              data-wow-delay="00ms"
              data-wow-duration="1500ms"
            >
              <div className="img-holder">
                <div className="inner">
                  <img src={storie3} alt="" />
                  <a href="/Memorandumofunderstanding">
                    <div className="overlay-icon"></div>
                  </a>
                </div>
              </div>
              <div className="text-holder">
                <h3 className="blog-title mt-4">
                  <a>
                  Memorandum of Understanding with Sarago Motors
                  </a><br/>
                  <span style={{fontSize: "15px", color: "gray"}}>June 15, 2022</span>
                </h3>
                <p>
                Previous Next Indo Zambia bank Signs a Memorandum of Understanding with Sarago Motors for the Extension of Auto Finance loans to individuals and corporate customers
                </p><br/>
                <Link
                to="/Memorandumofunderstanding">
             <h5 style={{fontWeight:"500", color:"#9E1B1E", textAlign:"right"}}>Read More <img src={arroww} alt="" width="8%"/></h5></Link>
             <hr style={{ color:"#9E1B1E", margin:"0rem 0"}}/>
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-lg-4">
            <div
              className="single-blog-style1 wow fadeInUp"
              data-wow-delay="00ms"
              data-wow-duration="1500ms"
            >
              <div className="img-holder">
                <div className="inner">
                  <img src={storie5} alt="" />
                  <a href="/Businessindabaexhibition">
                    <div className="overlay-icon"></div>
                  </a>
                </div>
              </div>
              <div className="text-holder">
                <h3 className="blog-title mt-4">
                  <a>Business Indaba Exhibition</a><br/>
                  <span style={{fontSize: "15px", color: "gray"}}>June 7, 2022</span>
                </h3>
                <p className="" style={{marginBottom:"1.6rem"}}>
                Business Indaba Exhibition
                </p>
                <Link
                to="/Businessindabaexhibition">
             <h5 style={{fontWeight:"500", color:"#9E1B1E", textAlign:"right"}}>Read More <img src={arroww} alt="" width="8%"/></h5></Link>
             <hr style={{ color:"#9E1B1E", margin:"0rem 0"}}/>
            
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-lg-4 h-100">
            <div
              className="single-blog-style1 wow fadeInUp"
              data-wow-delay="00ms"
              data-wow-duration="1500ms"
            >
              <div className="img-holder">
                <div className="inner">
                  <img src={storie6} alt="" />
                  <a href="/Europeanunionexhibition">
                    <div className="overlay-icon"></div>
                  </a>
                </div>
              </div>
              <div className="text-holder">
                <h3 className="blog-title mt-4">
                  <a >
                  European Union (EU) Exhibition
                  </a><br/>
                  <span style={{fontSize: "15px", color: "gray"}}>June 6, 2022</span>
                </h3>
                <p>
                European Union (EU) Exhibition
                </p><br/>
                <Link
                to="/Europeanunionexhibition">
             <h5 style={{fontWeight:"500", color:"#9E1B1E", textAlign:"right"}}>Read More <img src={arroww} alt="" width="8%"/></h5></Link>
             <hr style={{ color:"#9E1B1E", margin:"0rem 0"}}/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
      <AllAcountsSection />
    </>
  );
};

export default Copperminespartnership;
