import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import Navbar from "./Components/Navbar/Navbar";
import Homepage from "./Pages/Homepage/Homepage";
import AboutUs from "./Pages/AboutUs/AboutUs";
import Footer from "./Components/Footer/Footer";
import BoardOfDirectors from "./Pages/BoardOfDirectors/BoardOfDirectors";
import ExecutiveManagement from "./Pages/BoardOfDirectors/BoardOfDirectors1";
import FinancialPerformance from "./Pages/FinancialPerformance/FinancialPerformance";
import WhyBankWithUs from "./Pages/WhyBankWithUs/WhyBankWithUs";
import SavePlusAccount from "./Pages/SavePlusAccount/SavePlusAccount";
import ClassicSavingAcc from "./Pages/ClassicSavingAcc/ClassicSavingAcc";
import PrivilageSavingAcc from "./Pages/PrivilageSavingAcc/PrivilegeSaving";
import TisungoSaving from "./Pages/TisungoSaving/TisungeSavingsAcc";
import PersonalBanking from "./Pages/PersonalBanking/PersonalBanking";
import TonseSavingAcc from "./Pages/TonseSavingAcc/TonseSavingAcc";
import IndoHomeLoan from "./Pages/IndoHomeLoan/IndoHomeLoan";
import IndoPersonalLoan from "./Pages/IndoPersonalLoan/IndoPersonalLoan";
import IndoVehicleLoan from "./Pages/IndoVehicleLoan/IndoVehicleLoan";
import BumiFixedDepositAccount from "./Pages/BhumiFixedDepositAccount/BumiFixedDepositAccount";
import IndoFastServe from "./Pages/IndoFastServe/IndoFastServe";
import PrivilegeCurrentAcc from "./Pages/PrivilegeCurrentAcc/PrivilegeCurrentAcc";
import ClassicCurrentAccount from "./Pages/ClassicCurrentAccount/ClassicCurrentAccount";
import SMECurrentAcc from "./Pages/SMECurrentAcc/SMECurrentAccount";
import MSMELoan from "./Pages/MSMELoan/MSMELoan";
import TermLoan from "./Pages/TermLoan/TermLoan";
import AutoFinance from "./Pages/AutoFinance/AutoFinance";
import AgroFinance from "./Pages/AgroFinance/AgroFinance";
import EquityRelease from "./Pages/EquityRelease/EquityRelease";
import BusinessFinance from "./Pages/BusinessFinance/BusinessFinance";
import WorkingCapitalFinance from "./Pages/WorkingCapitalFinance/WorkingCapitalFinance";
import LeaseRentalDiscounting from "./Pages/LeaseRentalDiscounting/LeaseRentalDiscounting";
import InternationalBankingForeignBusiness from "./Pages/InternationalBankingForeignBusiness/InternationalBankingForeignBusiness";
import ForexSavings from "./Pages/ForexSavings/ForexSavings";
import ForexRates from "./Pages/ForexRates/ForexRates";
import Career from "./Pages/Career/Career";
import ForexRates1 from "./Pages/ForexRates/ForexRates1";
import WatchLive from "./Pages/WatchLive/WatchLive"
import ForexCurrent from "./Pages/ForexCurrent/ForexCurrent";
import MobileBanking from "./Pages/MobileBanking/MobileBanking";
import WalletBanking from "./Pages/WalletBanking/WalletBanking";
import NetBanking from "./Pages/NetBanking/NetBanking";
import ContactlessCard from "./Pages/ContactlessCard/ContactlessCard";
import EEZY from "./Pages/EEZY/EEZY";
import BusinessBanking from "./Pages/BusinessBanking/BusinessBanking";
import FAQS from "./Pages/FAQS/FAQS";
import Feedback from "./Pages/Feedback/Feedback";
import Disclaimer from "./Pages/IndoVehicleLoan/Disclaimer1";
import PrivacyPolicy from "./Pages/PrivacyPolicy/PrivacyPolicy";
import DigitalBankingPrivacyPolicy from "./Pages/PrivacyPolicy/DigitalBankingPrivacyPolicy"
import Stories from "./Pages/Stories/Stories";
import Monetarypolicerate from "./Pages/Monetarypolicerate/Monetarypolicerate"
import Monetarypoliceratenovember from "./Pages/Monetarypoliceratenovember/Monetarypoliceratenovember"
import Invitationtotender from "./Pages/Invitationtotender/Invitationtotender"
import Indopremierbanking from "./Pages/Indopremierbanking/Indopremierbanking"
import Officiallaunchofizbhq from "./Pages/Officiallaunchofizbhq/Officiallaunchofizbhq"
import Workerscopensation from "./Pages/Workerscopensation/Workerscopensation"
import Tenderforfitoutworks from "./Pages/Tenderforfitoutworks/Tenderforfitoutworks"
import VendorsSuppliers2024 from "./Pages/VendorsSuppliers2024/VendorsSuppliers2024"
import SaleOfProperties from "./Pages/SaleOfProperties/SaleOfProperties";
import TendorForConsultancy from "./Pages/TendorForConsultancy/TendorForConsultancy";
import Propertiesforsale from "./Pages/Propertiesforsale/Propertiesforsale";
import Propertiesforsale2024 from "./Pages/Propertiesforsale2024/Propertiesforsale";
import Propertiesforsalejune from "./Pages/Propertiesforsalejune/Propertiesforsale";
import AACreditRatings from "./Pages/AACreditRating/AACreditRatings";
import RequestforProposal from "./Pages/RequestforProposal/RequestforProposal";

import TermsandCondition from "./Pages/PrivacyPolicy/TermsandCondition";
import Registrationofvendores from "./Pages/Registrationofvendores/Registrationofvendores"
import Copperminespartnership from "./Pages/Copperminespartnership/Copperminespartnership"
import Memorandumofunderstanding from "./Pages/Memorandumofunderstanding/Memorandumofunderstanding"
import Businessindabaexhibition from "./Pages/Businessindabaexhibition/Businessindabaexhibition"
import Europeanunionexhibition from "./Pages/Europeanunionexhibition/Europeanunionexhibition"
import ContactForm from "./Pages/ContactForm1/ContactForm"
import PremierBanking from "./Pages/PremierBanking/PremierBanking"
import Thankyou from "./Pages/Thankyou";
import AnnualReport2023 from "./Pages/AnnualReport/AnnualReport2023";
import Invitationn from "./Pages/AnnualReport/Invitationn";
import TenderInvitation from "./Pages/AACreditRating/TenderInvitation";
import TenderInvitations from "./Pages/AACreditRating/TenderInvitations";


function App() {
  return (
    <>
      <BrowserRouter>
        <Navbar />
        <Routes>
          <Route path="/" exact element={<Homepage />} />
          <Route path="/AboutUs" element={<AboutUs />} />
          <Route path="/BoardOfDirectors" element={<BoardOfDirectors />} />
          <Route path="/ExecutiveManagement" element={<ExecutiveManagement />} />
          <Route path="/FinancialPerformance" element={<FinancialPerformance />} />
          <Route path="/WhyBankWithUs" element={<WhyBankWithUs />} />
          <Route path="/SavePlusAccount" element={<SavePlusAccount />} />
          <Route path="/PersonalBanking" element={<PersonalBanking />} />
          <Route path="/ClassicSavingAcc" element={<ClassicSavingAcc />} />
          <Route path="/PrivilageSavingAcc" element={<PrivilageSavingAcc />} />
          <Route path="/TisungoSaving" element={<TisungoSaving />} />
          <Route path="/TonseSavingAcc" element={<TonseSavingAcc />} />
          <Route path="/IndoHomeLoan" element={<IndoHomeLoan />} />
          <Route path="/IndoPersonalLoan" element={<IndoPersonalLoan />} />
          <Route path="/IndoVehicleLoan" element={<IndoVehicleLoan />} />
          <Route path="/BumiFixedDepositAccount" element={<BumiFixedDepositAccount />}/>
          <Route path="/BusinessBanking" element={<BusinessBanking />} />
          <Route path="/IndoFastServe" element={<IndoFastServe />} />
          <Route path="/PrivilegeCurrentAcc" element={<PrivilegeCurrentAcc />} />
          <Route path="/ClassicCurrentAccount" element={<ClassicCurrentAccount />} />
          <Route path="/SMECurrentAcc" element={<SMECurrentAcc />} />
          <Route path="/MSMELoan" element={<MSMELoan />} />
          <Route path="/FAQS" element={<FAQS />} />
          <Route path="/Feedback-Complaint" element={<Feedback />} />
          <Route path="/TermLoan" element={<TermLoan />} />
          <Route path="/AutoFinance" element={<AutoFinance />} />
          <Route path="/AgroFinance" element={<AgroFinance />} />
          <Route path="/EquityRelease" element={<EquityRelease />} />
          <Route path="/BusinessFinance" element={<BusinessFinance />} />
          <Route path="/WorkingCapitalFinance" element={<WorkingCapitalFinance />} />
          <Route path="/LeaseRentalDiscounting" element={<LeaseRentalDiscounting />} />
          <Route path="/InternationalBankingForeignBusiness" element={<InternationalBankingForeignBusiness />} />
          <Route path="/ForexSavings" element={<ForexSavings />} />
          <Route path="/ForexRates" element={<ForexRates />} />
          <Route path="/Career" element={<Career />} />
          <Route path="/Thankyou" element={<Thankyou />} />
          <Route path="/ForexRates1" element={<ForexRates1 />} />
          <Route path="/WatchLive" element={<WatchLive />} />
          <Route path="/ForexCurrent" element={<ForexCurrent />} />
          <Route path="/MobileBanking" element={<MobileBanking />} />
          <Route path="/WalletBanking" element={<WalletBanking />} />
          <Route path="/NetBanking" element={<NetBanking />} />
          <Route path="/ContactlessCard" element={<ContactlessCard />} />
          <Route path="/EEZY" element={<EEZY />} />
          <Route path="/Disclaimer" element={<Disclaimer />} />
          <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
          <Route path="/DigitalBankingPrivacyPolicy" element={<DigitalBankingPrivacyPolicy />} />

          <Route path="/TermsandCondition" element={<TermsandCondition />} />
          <Route path="/Stories" element={<Stories />} />
          <Route path="/Monetarypolicerate" element={<Monetarypolicerate />} />
          <Route path="/Monetarypoliceratenovember" element={<Monetarypoliceratenovember />} />
          <Route path="/Invitationtotender" element={<Invitationtotender />} />
          <Route path="/Indopremierbanking" element={<Indopremierbanking />} />
          <Route path="/Officiallaunchofizbhq" element={<Officiallaunchofizbhq />} />
          <Route path="/Workerscopensation" element={<Workerscopensation />} />
          <Route path="/Tenderforfitoutworks" element={<Tenderforfitoutworks />} />
          <Route path="/VendorsSuppliers2024" element={<VendorsSuppliers2024 />} />
          <Route path="/SaleOfProperties" element={<SaleOfProperties />} />
          <Route path="/TendorForConsultancy" element={<TendorForConsultancy />} />
          <Route path="/Propertiesforsale" element={<Propertiesforsale />} />
          <Route path="/Propertiesforsale2024" element={<Propertiesforsale2024 />} />
          <Route path="/Propertiesforsalejune" element={<Propertiesforsalejune />} />
          <Route path="/AAcreditratings" element={<AACreditRatings />} />
          <Route path="/AnnualReport" element={<AnnualReport2023/>} />
          <Route path="/Invitationn" element={<Invitationn/>} />
          <Route path="/TenderInvitations" element={<TenderInvitations/>} />
          

          <Route path="/RequestforProposal" element={<RequestforProposal />} />

          <Route path="/Registrationofvendores" element={<Registrationofvendores />} />
          <Route path="/Copperminespartnership" element={<Copperminespartnership />} />
          <Route path="/Memorandumofunderstanding" element={<Memorandumofunderstanding />} />
          <Route path="/Businessindabaexhibition" element={<Businessindabaexhibition />} />
          <Route path="/Europeanunionexhibition" element={<Europeanunionexhibition />} />
          <Route path="/ContactForm" element={<ContactForm />} />
          <Route path="/PremierBanking" element={<PremierBanking />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </>
  );
}

export default App;
