import React from "react";
import { useEffect } from "react";
import Breadcrumb from "../../Components/Breadcrumb/Breadcrumb";
import AllAcountsSection from "../../Components/AllAccountsSection/AllAcountsSection";
import { newData } from "./PrivacyData";
import PrivacyAccordion from "./PrivacyAccordion1";
// import BackgroundBrundcrumb from "../../assets/images/IZB/BackgroundBrundcrumb.png";

const PrivacyPolicy = () => {
  const BackgroundBrundcrumb = "https://techrowth.s3.eu-north-1.amazonaws.com/images/New/Rectangle+2.png";

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Breadcrumb Heading="Terms and Conditions of IZB INDO Wallet Banking" img={BackgroundBrundcrumb} />
      <div className="container mt-5">
        <div className="faq-style1__content">
          <ul className="accordion-box">
          <p style={{fontSize:"20px", margin:"2vw"}}>These are the terms and conditions upon which you may use the Indo Wallet services provided by Indo Zambia Bank Limited (IZB), the acceptance of which constitutes a binding contract between yourself and IZB. When you register as an Indo Wallet Customer by completing the registration process through USSD, App or online you agree to abide by these conditions of use. You therefore need to read and fully understand the conditions of use and if you do not agree, please do not proceed to register for and/or use the Indo Wallet services.</p>
        
             <PrivacyAccordion  />
          
          </ul>
        </div>
      </div>
      <br/>
      <div className="col-xl-12 justify-content-center"></div>
      <AllAcountsSection />
    </>
  );
};

export default PrivacyPolicy;
