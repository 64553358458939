import React, { useEffect, useState } from "react";
import { BsWhatsapp } from "react-icons/bs";
import { AiFillCaretDown, AiOutlinePhone } from "react-icons/ai";
import { GiHamburgerMenu } from "react-icons/gi";
import $ from "jquery";

import { Link } from "react-router-dom";
import { data } from "./NavData";
import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";



      const Navbar = () => {
        // const [isMenuOpen, setMenuOpen] = useState(false);

        const [activeIndex, setActiveIndex] = useState(null);

        const Logo = "https://techrowth.s3.eu-north-1.amazonaws.com/images/IZB/logo.png";
        const Phone = "https://techrowth.s3.eu-north-1.amazonaws.com/images/IZB/PhoneCall.png";
        const PlayStore = "https://techrowth.s3.eu-north-1.amazonaws.com/images/IZB/PlayStore.png";
        const apple = "https://techrowth.s3.eu-north-1.amazonaws.com/images/IZB/apple.png";
        const notification = "https://techrowth.s3.eu-north-1.amazonaws.com/images/IZB/Bell_red.svg";

useEffect(() => {
  if ($(".mobile-nav__toggler1").length) {
    $(".mobile-nav__toggler1").on("click", function (e) {
      e.preventDefault();
      $(".mobile-nav__wrapper").toggleClass("expanded");
      $("body").toggleClass("locked");
    });

    $(".mobile-nav__content").on("click", 'a[href^="/"]', function (e) {
      e.preventDefault();
      const href = $(this).attr("href");
      $(".mobile-nav__wrapper").removeClass("expanded");
      $("body").removeClass("locked");
      setTimeout(() => {
        window.location.href = href;
      }, 50);
    });
  }
}, []);
const [menuOpen, setMenuOpen] = useState(true);

const closeMenu = () => {
  setMenuOpen(false);
};
  return (
    <>
      <header className="main-header main-header-style1">
        {/*Start Main Header Style1 Top*/}
        <div className="main-header-style1-top py-2">
          <div className="container-fluid">
            <div className="outer-box justify-content-between">
            
              <div className="main-header-style1-top__left">
                <div
                  className="looking-banking-box"
                  style={{ paddingLeft: "40px" }}
                >
                  <Link to="/">
                    <img src={Logo} width={300} />
                  </Link>
                </div>
                {/* <div className="ms-4 ps-4">
                  <div className="input-group">
                    <div className="form-outline">
                      <input
                        style={{
                          backgroundColor: "#F0F0F0",
                          borderRadius: "21px",
                          color:"#000!important"
                        }}
                        id="search-input"
                        type="search"
                        placeholder="Search"
                        className="form-control pe-2"
                      />
                    </div>
                  </div>
                </div> */}
              </div>

              <div className="main-header-style1-top__right ">
                <div className="header-menu-style1">
                  <div className="btn-box">
                    <div className="get-app-box">
                      <ul className="clearfix">
                        <li className="p-0 text-center">
                          <small className="p-0 m-0">Toll-Free</small>
                          <a
                            className="px-1 py-1 rounded"
                            style={{ backgroundColor: "#9E1B1E" }}
                          >
                            <div className="icon">
                              <img
                                src={Phone}
                                width={25}
                                className="style2 text"
                                alt=""
                              />
                            </div>
                            <div className="text px-2">
                              <h4 className="text-white">8002</h4>
                            </div>
                          </a>
                        </li>
                        <li>
                          <div
                            className="d-flex gap-4"
                            style={{ marginTop: "30px" }}
                          >
                            <p>
                              <AiOutlinePhone size={19} className="mx-1 mb-1" />
                              +260 2113 89900
                            </p>
                            <p>
                              <BsWhatsapp size={19} className="me-1 mb-1" />
                              +260 7717 98616
                            </p>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div style={{ marginRight: "35px", marginLeft: "35px" }}>
                  <a href="https://play.google.com/store/apps/details?id=com.izb&hl=en&gl=US" className="search-toggler" target="_blank">
                    <img src={PlayStore} />
                  </a>
                  <a href="https://apps.apple.com/in/app/izb-retail-banking/id1507943733" className="search-toggler" target="_blank">
                    <img src={apple} />
                  </a>
                </div>
              </div>
         
            </div>
          </div>
        </div>
        {/*End Main Header Style1 Top*/}
        <nav className="main-menu main-menu-style1">
          <div className="main-menu__wrapper clearfix">
            <div className="container">
              <div className="main-menu__wrapper-inner w-100">
                <div className="main-menu-style1-left w-100">
                  <div className="main-menu-box w-100">
                    <div className="mobile-nav__toggler w-100">
                    <div className="mobile-nav__toggler1">
                      <GiHamburgerMenu />
                      </div>
                      <Link to="/">
                      <img src={Logo} width={270} className="ps-3" />
                      </Link>
                      <img src={notification} width={35} className="ps-3" />
                      </div>
                      
                    
                    <ul
                      className="main-menu__list justify-content-between"
                      style={{ gap: "2px" }}
                    >
                      <li className="megamenu ">
                        <Link to="/">Home</Link>
                      </li>
                      <li className="dropdown">
                        <Link to="/AboutUs">
                          About <AiFillCaretDown size={10} className="ms-1" />
                        </Link>
                        <ul>
                          <li>
                            <Link to="/AboutUs">About IZB</Link>
                          </li>
                          <li>
                            <Link to="/BoardOfDirectors">
                              Leadership & Governance
                            </Link>
                            <ul>
                              <li>
                                <Link to="/BoardOfDirectors">
                                  Board Of Directors
                                </Link>
                              </li>
                              <li>
                                <Link to="/ExecutiveManagement">
                                  Executive Management
                                </Link>
                              </li>
                            </ul>
                          </li>
                          <li>
                            <Link to="/FinancialPerformance">
                              Financial Performance
                            </Link>
                          </li>
                          <li>
                            <Link to="/WhyBankWithUs">Why Bank with us</Link>
                          </li>
                          <li>
                            <a to="/BoardOfDirectors">CSR</a>
                          </li>
                        </ul>
                      </li>
                      <li className="dropdown">
                        <Link to="/PersonalBanking">
                          Personal Banking{" "}
                          <AiFillCaretDown size={10} className="ms-1" />
                        </Link>
                        <ul>
                          <li className="dropdown">
                            <a href="#">Saving Accounts</a>
                            <ul>
                              <li>
                                <Link to="/SavePlusAccount">
                                  Save Plus Bank A/C
                                </Link>
                              </li>
                              <li>
                                <Link to="/ClassicSavingAcc">
                                  Classic Saving Account
                                </Link>
                              </li>
                              <li>
                                <Link to="/PrivilageSavingAcc">
                                  Privilege Savings Account
                                </Link>
                              </li>
                              <li>
                                <Link to="/TisungoSaving">
                                  Tisunge Savings Account
                                </Link>
                              </li>
                              <li>
                                <Link to="/TonseSavingAcc">
                                  Tonse Savings Account
                                </Link>
                              </li>
                            </ul>
                          </li>
                          <li>
                            <Link to="/PremierBanking">
                            Premier Banking
                            </Link>
                          </li>
                          <li>
                            <a href="#">Credit</a>

                            <ul>
                              <li>
                                <Link to="/IndoHomeLoan">Indo Home Loan</Link>
                              </li>
                              <li>
                                <Link to="/IndoPersonalLoan">
                                  Personal Loan
                                </Link>
                              </li>
                              <li>
                                <Link to="/IndoVehicleLoan">
                                  Indo Vehicle Loan
                                </Link>
                              </li>
                            </ul>
                          </li>
                          <li>
                            <Link to="/BumiFixedDepositAccount">
                              Bumi Fixed Deposit A/C
                            </Link>
                          </li>
                          <li>
                            <Link to="/IndoFastServe">Indo Fast Serve</Link>
                          </li>
                        </ul>
                      </li>

                      <li className="dropdown">
                        <Link to="/BusinessBanking">
                          Business Banking{" "}
                          <AiFillCaretDown size={10} className="ms-1" />
                        </Link>
                        <ul>
                          <li>
                            <a href="#">Current Account</a>
                            <ul>
                              <li>
                                <Link to="/PrivilegeCurrentAcc">
                                  Privilege Current Account
                                </Link>
                              </li>

                              <li>
                                <Link to="/ClassicCurrentAccount">
                                  Classic Current Account
                                </Link>
                              </li>

                              <li>
                                <Link to="/SMECurrentAcc">
                                  SME Current Account
                                </Link>
                              </li>
                            </ul>
                          </li>
                          <li>
                            <a href="#">Credit</a>

                            <ul>
                              <li>
                                <Link to="/MSMELoan">MSME Loan</Link>
                              </li>

                              <li>
                                <Link to="/TermLoan">Term Loan</Link>
                              </li>

                              <li>
                                <Link to="/AutoFinance">Auto Finance</Link>
                              </li>
                              <li>
                                <Link to="/AgroFinance">Agro Finance</Link>
                              </li>
                              <li>
                                <Link to="/EquityRelease">Equity Release</Link>
                              </li>
                              <li>
                                <Link to="/BusinessFinance">Business Loan</Link>
                              </li>
                              <li>
                                <Link to="/WorkingCapitalFinance">
                                  Working Capital Finance
                                </Link>
                              </li>
                              <li>
                                <Link to="/LeaseRentalDiscounting">
                                  Lease Rental Discounting
                                </Link>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </li>
                      <li className="dropdown">
                        <Link to="#">
                          {" "}
                          Forex & Treasury{" "}
                          <AiFillCaretDown size={10} className="ms-1" />
                        </Link>
                        <ul>
                          <li>
                            <a href="/ForexRates">Forex Rates</a>
                          </li>
                          <li>
                            <Link to="/InternationalBankingForeignBusiness">
                              Forex Business
                            </Link>
                          </li>
                          <li>
                            <Link to="/ForexSavings">Forex Savings</Link>
                          </li>
                          <li>
                            <Link to="/ForexCurrent">Forex Current</Link>
                          </li>
                        </ul>
                      </li>
                      <li className="dropdown">
                        <a href="#">
                          E-Services{" "}
                          <AiFillCaretDown size={10} className="ms-1" />
                        </a>
                        <ul>
                          <li>
                            <Link to="/MobileBanking">Indo Mobile Banking</Link>
                          </li>
                          <li>
                            <Link to="/NetBanking">Indo Net Banking</Link>
                          </li>
                          <li>
                            <Link to="/ContactlessCard">IZB Cards</Link>
                          </li>
                          <li>
                            <a href="https://epay.izb.co.zm/login/">
                              ZRA/NAPSA/E-Payment
                            </a>
                          </li>
                          <li>
                            <Link to="/EEZY">Indo EEZY</Link>
                          </li>
                          <li>
                            <Link to="/WalletBanking">Indo Wallet Banking</Link>
                          </li>
                        </ul>
                      </li>
                    <img src={notification} width={30} />

                      <li className="dropdown">
                        <a
                          href="#"
                          className="text-white rounded"
                          style={{ backgroundColor: "#9E1B1E" }}
                        >
                          Indo Net Banking
                        </a>
                        <ul>
                          <li>
                            <a href="https://ebanking.izb.co.zm:7004/Retail/#/landing">
                              Retail
                            </a>
                          </li>
                          <li>
                            <a href="https://ebanking.izb.co.zm:7004/CorporateBanking/#/landing">
                              Corporate
                            </a>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </nav>
        {menuOpen && (
        <div className="mobile-nav__wrapper">
       
          <div className="mobile-nav__overlay mobile-nav__toggler" />
          
          <div className="mobile-nav__content">
            <span className="mobile-nav__close mobile-nav__toggler">
              <i className="fas fa-plus" onClick={closeMenu}  />
            </span>
            <div className="logo-box">
              <Link to="/" aria-label="logo image">
                <img src={Logo} width={200} alt="" />
              </Link>
              
            </div>

            <div style={{ textAlign:"center" }}>
            <p   style={{ backgroundColor: "#FFF" }}>Download Mobile App</p>
              
                  <a href="https://play.google.com/store/apps/details?id=com.izb&hl=en&gl=US" className="search-toggler" target="_blank">
                    <img src={PlayStore}  style={{ marginTop:"0.5rem", marginBottom:"0.5rem" }}/>
                  </a>
                  <a href="https://apps.apple.com/in/app/izb-retail-banking/id1507943733" className="search-toggler" target="_blank">
                    <img src={apple} style={{ marginTop:"0.5rem", marginBottom:"0.5rem" }}/>
                  </a>
                </div>
            <div className="mb-3 mx-0">
              <Sidebar>
                <Menu>
                  <MenuItem component={<Link to="/" />}   style={{ backgroundColor: "#FFF5E7", borderBottom: "2px solid #DFC0C0" }}>Home</MenuItem>

                  <SubMenu label="About Us" style={{ backgroundColor: "#FFF5E7", borderBottom: "2px solid #DFC0C0" }}>
                    <MenuItem component={<Link to="/AboutUs" />}  style={{ backgroundColor: "#FFF5E7", borderBottom: "2px solid #DFC0C0" }} >
                      About IZB
                    </MenuItem>

                    <SubMenu label="Leadership & Governance" style={{ backgroundColor: "#FFF5E7", borderBottom: "2px solid #DFC0C0" }}>
                      <MenuItem component={<Link to="/BoardOfDirectors" />} style={{ backgroundColor: "#FFF5E7", borderBottom: "2px solid #DFC0C0" }}>
                        Board of Directors
                      </MenuItem>

                      <MenuItem component={<Link to="/ExecutiveManagement" />} style={{ backgroundColor: "#FFF5E7", borderBottom: "2px solid #DFC0C0" }}>
                        Executive Management
                      </MenuItem>
                    </SubMenu>

                    <MenuItem component={<Link to="/FinancialPerformance" />} style={{ backgroundColor: "#FFF5E7", borderBottom: "2px solid #DFC0C0" }}>
                      Financial Performance
                    </MenuItem>

                    <MenuItem component={<Link to="/WhyBankWithUs" />} style={{ backgroundColor: "#FFF5E7", borderBottom: "2px solid #DFC0C0" }}>
                      Why Bank with us
                    </MenuItem>
                    <MenuItem component={<Link to="/" />} style={{ backgroundColor: "#FFF5E7", borderBottom: "2px solid #DFC0C0" }}>CSR</MenuItem>
                  </SubMenu>
                  <SubMenu
                    
                    label="Personal Banking"  style={{ backgroundColor: "#FFF5E7", borderBottom: "2px solid #DFC0C0" }}
                  >
                    <SubMenu label="Saving Accounts" style={{ backgroundColor: "#FFF5E7", borderBottom: "2px solid #DFC0C0" }}>
                      <MenuItem component={<Link to="/SavePlusAccount" />} style={{ backgroundColor: "#FFF5E7", borderBottom: "2px solid #DFC0C0" }}>
                        Save Plus Bank A/C
                      </MenuItem>

                      <MenuItem component={<Link to="/ClassicSavingAcc" />} style={{ backgroundColor: "#FFF5E7", borderBottom: "2px solid #DFC0C0" }}>
                        Classic Saving Account
                      </MenuItem>

                      <MenuItem component={<Link to="/PrivilageSavingAcc" />} style={{ backgroundColor: "#FFF5E7", borderBottom: "2px solid #DFC0C0" }}>
                        Privilege Savings Account
                      </MenuItem>

                      <MenuItem component={<Link to="/TisungoSaving" />} style={{ backgroundColor: "#FFF5E7", borderBottom: "2px solid #DFC0C0" }}>
                        Tisunge Savings Account
                      </MenuItem>

                      <MenuItem component={<Link to="/TonseSavingAcc" />} style={{ backgroundColor: "#FFF5E7", borderBottom: "2px solid #DFC0C0" }}>
                        Tonse Savings Account
                      </MenuItem>
                    </SubMenu>
                    <MenuItem
                      component={<Link to="/PremierBanking" />} style={{ backgroundColor: "#FFF5E7", borderBottom: "2px solid #DFC0C0" }}
                    >
                      Premier Banking
                    </MenuItem>
                    <SubMenu label="Credit" style={{ backgroundColor: "#FFF5E7", borderBottom: "2px solid #DFC0C0" }}>
                      <MenuItem component={<Link to="/IndoHomeLoan" />} style={{ backgroundColor: "#FFF5E7", borderBottom: "2px solid #DFC0C0" }}>
                        Indo Home Loan
                      </MenuItem>

                      <MenuItem component={<Link to="/IndoPersonalLoan" />} style={{ backgroundColor: "#FFF5E7", borderBottom: "2px solid #DFC0C0" }}>
                        Personal Loan
                      </MenuItem>

                      <MenuItem component={<Link to="/IndoVehicleLoan" />} style={{ backgroundColor: "#FFF5E7", borderBottom: "2px solid #DFC0C0" }}>
                        Indo Vehicle Loan
                      </MenuItem>
                    </SubMenu>

                    <MenuItem
                      component={<Link to="/BumiFixedDepositAccount" />} style={{ backgroundColor: "#FFF5E7", borderBottom: "2px solid #DFC0C0" }}
                    >
                      Bumi Fixed Deposit A/C
                    </MenuItem>

                    <MenuItem component={<Link to="/IndoFastServe" />} style={{ backgroundColor: "#FFF5E7", borderBottom: "2px solid #DFC0C0" }}>
                      Indo Fast Serve
                    </MenuItem>
                  </SubMenu>
                  <SubMenu
                    
                    label="Businesss Banking" style={{ backgroundColor: "#FFF5E7", borderBottom: "2px solid #DFC0C0" }}
                  >
                    <SubMenu label="Current Account" style={{ backgroundColor: "#FFF5E7", borderBottom: "2px solid #DFC0C0" }}>
                      <MenuItem component={<Link to="/PrivilegeCurrentAcc" />} style={{ backgroundColor: "#FFF5E7", borderBottom: "2px solid #DFC0C0" }}>
                        Privilege Current Account
                      </MenuItem>

                      <MenuItem
                        component={<Link to="/ClassicCurrentAccount" />} style={{ backgroundColor: "#FFF5E7", borderBottom: "2px solid #DFC0C0" }}
                      >
                        Classic Current Account
                      </MenuItem>

                      <MenuItem component={<Link to="/SMECurrentAcc" />} style={{ backgroundColor: "#FFF5E7", borderBottom: "2px solid #DFC0C0" }}>
                        SME Current Account
                      </MenuItem>
                    </SubMenu>
                    <SubMenu label="Credit" style={{ backgroundColor: "#FFF5E7", borderBottom: "2px solid #DFC0C0" }}>
                      <MenuItem component={<Link to="/MSMELoan" />} style={{ backgroundColor: "#FFF5E7", borderBottom: "2px solid #DFC0C0" }}>
                        MSME Loan
                      </MenuItem>

                      <MenuItem component={<Link to="/TermLoan" />} style={{ backgroundColor: "#FFF5E7", borderBottom: "2px solid #DFC0C0" }}>
                        Term Loan
                      </MenuItem>

                      <MenuItem component={<Link to="/AutoFinance" />} style={{ backgroundColor: "#FFF5E7", borderBottom: "2px solid #DFC0C0" }}>
                        Auto Finance
                      </MenuItem>
                    </SubMenu>

                    <MenuItem component={<Link to="/AgroFinance" />} style={{ backgroundColor: "#FFF5E7", borderBottom: "2px solid #DFC0C0" }}>
                      Agro Finanace
                    </MenuItem>

                    <MenuItem component={<Link to="/EquityRelease" />} style={{ backgroundColor: "#FFF5E7", borderBottom: "2px solid #DFC0C0" }}>
                      Equity Release
                    </MenuItem>

                    <MenuItem component={<Link to="/BusinessFinance" />} style={{ backgroundColor: "#FFF5E7", borderBottom: "2px solid #DFC0C0" }}>
                      Business Loan
                    </MenuItem>

                    <MenuItem component={<Link to="/WorkingCapitalFinance" />} style={{ backgroundColor: "#FFF5E7", borderBottom: "2px solid #DFC0C0" }}>
                      Working Capital Finance
                    </MenuItem>

                    <MenuItem component={<Link to="/LeaseRentalDiscounting" />} style={{ backgroundColor: "#FFF5E7", borderBottom: "2px solid #DFC0C0" }}>
                      Lease Rental Discounting
                    </MenuItem>
                  </SubMenu>
                  <SubMenu label="Forex & Treasury" style={{ backgroundColor: "#FFF5E7", borderBottom: "2px solid #DFC0C0" }}>
                    <MenuItem component={<Link to="/ForexRates" />} style={{ backgroundColor: "#FFF5E7", borderBottom: "2px solid #DFC0C0" }}>Forex Rates</MenuItem>

                    <MenuItem
                      component={
                        <Link to="/InternatioalBankingForeignBusiness" />
                      } style={{ backgroundColor: "#FFF5E7", borderBottom: "2px solid #DFC0C0" }}
                    >
                      Forex Business
                    </MenuItem>

                    <MenuItem component={<Link to="/ForexSavings" />} style={{ backgroundColor: "#FFF5E7", borderBottom: "2px solid #DFC0C0" }}>
                      Forex Savings
                    </MenuItem>

                    <MenuItem component={<Link to="/ForexCurrent" />} style={{ backgroundColor: "#FFF5E7", borderBottom: "2px solid #DFC0C0" }}>
                      Forex Current
                    </MenuItem>
                  </SubMenu>
                  <SubMenu label="E-Services" style={{ backgroundColor: "#FFF5E7", borderBottom: "2px solid #DFC0C0" }}>
                    <MenuItem component={<Link to="/MobileBanking" />} style={{ backgroundColor: "#FFF5E7", borderBottom: "2px solid #DFC0C0" }}>
                      Indo Mobile Banking
                    </MenuItem>

                    <MenuItem component={<Link to="/NetBanking" />} style={{ backgroundColor: "#FFF5E7", borderBottom: "2px solid #DFC0C0" }}>
                      Indo Net Banking
                    </MenuItem>

                    <MenuItem component={<Link to="/ContactlessCard" />} style={{ backgroundColor: "#FFF5E7", borderBottom: "2px solid #DFC0C0" }}>
                      IZB Cards
                    </MenuItem>

                    <MenuItem
                      component={<a href="https://epay.izb.co.zm/login/" />} style={{ backgroundColor: "#FFF5E7", borderBottom: "2px solid #DFC0C0" }}
                    >
                      ZRA/NAPSA/E-Payment
                    </MenuItem>
            
                    <MenuItem component={<Link to="/EEZY" />}  style={{ backgroundColor: "#FFF5E7", borderBottom: "2px solid #DFC0C0" }}>
                      Indo EEZY
                    </MenuItem>
                    <MenuItem component={<Link to="/WalletBanking" />} style={{ backgroundColor: "#FFF5E7", borderBottom: "2px solid #DFC0C0" }}>
                      Indo Wallet Banking
                    </MenuItem>
                  </SubMenu>
                  <SubMenu label="Indo Net Banking" style={{ backgroundColor: "#FFF5E7", borderBottom: "2px solid #DFC0C0" }}>
                    <MenuItem
                      component={
                        <a href="https://ebanking.izb.co.zm:7004/Retail/#/landing" />
                      }  style={{ backgroundColor: "#FFF5E7", borderBottom: "2px solid #DFC0C0" }}
                    >
                      Retail
                    </MenuItem>

                    <MenuItem
                      component={
                        <a href="https://ebanking.izb.co.zm:7004/CorporateBanking/#/landing" />
                      }  style={{ backgroundColor: "#FFF5E7", borderBottom: "2px solid #DFC0C0" }}
                    >
                      Corporate
                    </MenuItem>
                  </SubMenu>
                </Menu>
              </Sidebar>
            </div>

            <div className="mobile-nav__social">
              <a
                href="https://mobile.twitter.com/IndoZambiaBank"
                className="fab fa-twitter"
              />
              <a
                href="https://www.facebook.com/IndoZambiaBank?_rdc=1&_rdr#"
                className="fab fa-facebook-square"
              />
              <a
                href="https://www.linkedin.com/company/indo-zambia-bank/"
                className="fab fa-linkedin"
              />
              <a
                href="https://www.instagram.com/indo_zambia_bank/"
                className="fab fa-instagram"
              />
            </div>
          </div>
        </div>
          )}

        {/*End Main Header Style1 Bottom*/}
      </header>
    </>
  );
};

export default Navbar;
