import React from "react";
import Breadcrumb from "../../Components/Breadcrumb/Breadcrumb";
import AllAcountsSection from "../../Components/AllAccountsSection/AllAcountsSection";
// import BackgroundBrundcrumb from "../../assets/images/IZB/IZB Website Skin/Forign Business/Untitled-1.png";
import SavePlusAccountComponent from "../../Components/SavePlusAccount/SavePlusAccountComponent";
// import img1 from "../../assets/images/IZB/IZB Website Skin/Forign Business/Untitled-2.png";
import bgimg from "../../assets/images/IZB/IZB Website Skin/IZB Save Plus Account/image_6.png";
import { AiFillCaretRight, AiOutlineRight } from "react-icons/ai";
import { useEffect, useState } from "react";
import Arrow_down from "../../assets/images/IZB/arrow_down.png";
import axios from 'axios';
import AdvisorsVideo from "../../Components/AdvisorsVideo/AdvisorsVideo";
import AllAccounts from "../../Components/AllAccounts/AllAccounts";
import ContactForm from "../../Components/ContactForm/ContactForm";
import CustomerCare from "../../Components/CustomerCare/CustomerCare";
import OurStores from "../../Components/OurStories/OurStores";

const ForexRates = () => {
  const BackgroundBrundcrumb = "https://techrowth.s3.eu-north-1.amazonaws.com/images/Forex_Savings_Banner.png";
const img1 = "https://techrowth.s3.eu-north-1.amazonaws.com/images/Forex_Savings.png";
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [rates, setRates] = useState(null);
  const [currentDate, setCurrentDate] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [buyRates, setBuyRates] = useState(null);
  const [sellRates, setSellRates] = useState(null);

  useEffect(() => {
    const fetchForexRates = async () => {
      const appId = 'YOUR_APP_ID'; // Replace with your actual App ID
      const url = `https://openexchangerates.org/api/latest.json?app_id=${appId}`;

      try {
        const response = await axios.get(url);
        const { rates } = response.data;
        setBuyRates(rates);
        setSellRates(rates);
      } catch (error) {
        console.error('Error fetching forex rates:', error);
      }
    };

    fetchForexRates();
  }, []);

  useEffect(() => {
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', timeZone: 'Africa/Lusaka' };
    const currentDate = new Date().toLocaleDateString(undefined, options);
    setCurrentDate(currentDate);
  }, []);




  const accountContent =
    "Savings accounts can be opened for individuals in US Dollar/Pound Sterling/South African Rand/Euro. Cheque book facility is an option available. With this product, we also offer VISA Chip & Pin Debit Card facility, which is ideal for businessment/employees travelling frequently outside Zambia, who can draw money from any of the VISA enabled ATMs around the world.";
  return (
    <>
      <Breadcrumb
        Heading="Forex Rates"
        img={BackgroundBrundcrumb}
        show={true}
        btnTxt="Open an Account"
      />
   <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
  <div className="my-5" style={{ textAlign: "center" }}>
    <h2 className="my-5" style={{border:"1px solid", padding:"2vw", color:"white",background:"rgb(158, 27, 30)"}}>Indicative Forex Rates</h2>
    {currentDate && <h4 className="my-3" style={{    color: "rgb(72, 72, 72)"}}>Updated at: {currentDate}</h4>}
    {buyRates && sellRates && (
      <table className="my-3" style={{ margin: "0 auto", textAlign: "center", borderCollapse: "collapse", border: "1px solid black" }}>
  <thead>
    <tr>
      <th style={{ border: "1px solid black", padding: "2vw 6vw", background:"rgb(158, 27, 30)", color:"white" }}>Currency</th>
      <th style={{ border: "1px solid black", padding: "2vw 6vw", background:"rgb(158, 27, 30)", color:"white" }}>Buy</th>
      <th style={{ border: "1px solid black", padding: "2vw 6vw", background:"rgb(158, 27, 30)", color:"white" }}>Selling</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td style={{ border: "1px solid black", padding: "2vw 6vw",  fontWeight:"500", color:"rgb(158, 27, 30)" }}>USD</td>
      <td style={{ border: "1px solid black", padding: "2vw 6vw" }}>{buyRates.USD}</td>
      <td style={{ border: "1px solid black", padding: "2vw 6vw" }}>{sellRates.USD}</td>
    </tr>
    <tr>
      <td style={{ border: "1px solid black", padding: "2vw 6vw",  fontWeight:"500", color:"rgb(158, 27, 30)" }}>GBP</td>
      <td style={{ border: "1px solid black", padding: "2vw 6vw" }}>{buyRates.GBP}</td>
      <td style={{ border: "1px solid black", padding: "2vw 6vw" }}>{sellRates.GBP}</td>
    </tr>
    <tr>
      <td style={{ border: "1px solid black", padding: "2vw 6vw",  fontWeight:"500", color:"rgb(158, 27, 30)" }}>EUR</td>
      <td style={{ border: "1px solid black", padding: "2vw 6vw" }}>{buyRates.EUR}</td>
      <td style={{ border: "1px solid black", padding: "2vw 6vw" }}>{sellRates.EUR}</td>
    </tr>
    <tr>
      <td style={{ border: "1px solid black", padding: "2vw 6vw",  fontWeight:"500", color:"rgb(158, 27, 30)" }}>ZAR</td>
      <td style={{ border: "1px solid black", padding: "2vw 6vw" }}>{buyRates.ZAR}</td>
      <td style={{ border: "1px solid black", padding: "2vw 6vw" }}>{sellRates.ZAR}</td>
    </tr>
  </tbody>
</table>

    )}

    <h4 className="my-5" style={{border:"1px solid", padding:"2vw", color:"white",background:"rgb(158, 27, 30)"}}>The above rates are indicative and subject to change without notice.</h4>
  </div>
</div>

     

<AdvisorsVideo />
      <AllAccounts />
      <ContactForm />
      <CustomerCare />
      <OurStores />
    </>
  );
};

export default ForexRates;