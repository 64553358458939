import React,{useRef} from "react";
import { useEffect } from "react";
import Breadcrumb1 from "../../Components/Breadcrumb1/Breadcrumb1";
import AllAcountsSection from "../../Components/AllAccountsSection/AllAcountsSection";


// import BackgroundBrundcrumb from "../../assets/images/IZB/BackgroundBrundcrumb.png";
import {
  AiOutlineArrowLeft,
  AiOutlineArrowRight,
  AiOutlineShareAlt,
} from "react-icons/ai";

import { Link } from "react-router-dom";
import { CiClock1 } from "react-icons/ci";
import { BiMessageDetail } from "react-icons/bi";
const Monetarypolicerate = () => {
const BackgroundBrundcrumb = "https://techrowth.s3.eu-north-1.amazonaws.com/images/IZB/BackgroundBrundcrumb.png";
const storie1 = "https://techrowth.s3.eu-north-1.amazonaws.com/images/11.png";
const storie2 = "https://techrowth.s3.eu-north-1.amazonaws.com/images/12.png";
const storie3 = "https://techrowth.s3.eu-north-1.amazonaws.com/images/12.png";
const storie21 = "https://techrowth.s3.eu-north-1.amazonaws.com/q3twge.jpg";

const storie4 = "https://techrowth.s3.eu-north-1.amazonaws.com/images/13.png";
const storie5 = "https://techrowth.s3.eu-north-1.amazonaws.com/images/14.png";
const page1 = "https://techrowth.s3.eu-north-1.amazonaws.com/images/14.png"
const arroww = "https://techrowth.s3.eu-north-1.amazonaws.com/images/chevron_right.svg";

  const sliderRef = useRef(null);
  console.log(sliderRef.current);
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Breadcrumb1 Heading="INDO PREMIER BANKING" Heading1 ="Indo Zambia Bank  |  July 15, 2023" img={BackgroundBrundcrumb} />
      <div className="text-center" style={{marginTop:"5rem",marginBottom:"5rem"}}>
        <img src={page1} alt="" />
        <p style={{fontSize:"20px", margin:"2vw"}}>We cordially invite you to the Indo Premier Banking Suite. 
At Indo Zambia Bank (IZB), we understand and appreciate that you, our valued customers, require banking solutions tailored to your unique needs and supported by personalized customer experience. That’s why we have taken this initiative to invite you. At our Indo Premier Banking Suite, you will enjoy exclusive benefits that meet your needs.
</p>

<div
              className="py-4 text-center"
           
            >
              <Link
                to="/PremierBanking"
                className="text-white rounded"
                style={{ backgroundColor: "#9E1B1E", padding:"1rem"}}
              >
                Know More
              </Link>
            </div>
      </div>
      <section>
      <div className="container">
        <div className="row">
          <div className="container">
            <div className="heading ">
              <h2 className="text-left" style={{color:"#9E1B1E",fontWeight:"450"}}>More to Explore</h2>
             
            </div>
            <div
              className="py-4"
              style={{ float: "right"}}
            >
         
            </div>
          </div>

          {/* <Slider ref={sliderRef} {...settings}>  */}
          
          <div className="col-xl-4 col-lg-4">
            <div
              className="single-blog-style1 wow fadeInUp"
              data-wow-delay="00ms"
              data-wow-duration="1500ms"
            >
                <a href="/Workerscopensation">
              <div className="img-holder">
                <div className="inner">
                  <img src={storie21} alt="" />
           
                    <div className="overlay-icon"></div>
              
                </div>
              </div>
              </a>
              <div className="text-holder">
                <h3 className="blog-title mt-4">
             
                  <a>IZB - Workers Compensation Fund Control Board Zambia E-Workers Smart Pay Launch
</a><br/>

                  <span style={{fontSize: "15px", color: "gray"}}>October 20, 2023</span>
                </h3>
                <p className="" style={{marginBottom:"0rem"}}>
                Indo Zambia Bank is proud to announce the launch of the provision of the E-Workers on our INDO Internet Banking platform .This INDO Net Banking platform ...
                </p><br/>
                <Link
                to="/Workerscopensation">
             <h5 style={{fontWeight:"500", color:"#9E1B1E", textAlign:"right"}}>Read More <img src={arroww} alt="" width="8%"/></h5></Link>
             <hr style={{ color:"#9E1B1E", margin:"0rem 0"}}/>
            
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-lg-4">
            <div
              className="single-blog-style1 wow fadeInUp"
              data-wow-delay="00ms"
              data-wow-duration="1500ms"
            >
             <a href="/Officiallaunchofizbhq">
              <div className="img-holder">
                <div className="inner">
                  <img src={storie2} alt="" />
           
                    <div className="overlay-icon"></div>
              
                </div>
              </div>
              </a>
              <div className="text-holder">
                <h3 className="blog-title mt-4">
                  <a>The Official Launch Of The New Indo House Corporate Head Office</a><br/>
                  <span style={{fontSize: "15px", color: "gray"}}>July 6, 2023</span>
                </h3>
                <p className="" style={{marginBottom:"0rem"}}>
                The Board, Management and Staff of the Indo Zambia Bank wish to extend their sincere gratitude to the President of the Republic of Zambia ...
                </p><br/>
                <Link
                to="/Officiallaunchofizbhq">
             <h5 style={{fontWeight:"500", color:"#9E1B1E", textAlign:"right"}}>Read More <img src={arroww} alt="" width="8%"/></h5></Link>
             <hr style={{ color:"#9E1B1E", margin:"0rem 0"}}/>
            
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-lg-4">
            <div
              className="single-blog-style1 wow fadeInUp"
              data-wow-delay="00ms"
              data-wow-duration="1500ms"
            >
                 <a href="/Monetarypolicerate">
              <div className="img-holder">
                <div className="inner">
                  <img src={storie1} alt="" />
                
                    <div className="overlay-icon"></div>
                 
                </div>
              </div>
              </a>
              <div className="text-holder">
                <h3 className="blog-title mt-4">
                  <a>Monetary Policy Rate</a><br/>
                  <span style={{fontSize: "15px", color: "gray"}}>Aug 28, 2023</span>
                </h3>
                <p className="" style={{marginBottom:"0rem"}}>
                We wish to notify our esteemed customers that the Bank of Zambia increased the Monetary Policy Rate (MPR) from 9.50% to 10%...
                </p><br/>
                <Link
                to="/Monetarypolicerate">
             <h5 style={{fontWeight:"500", color:"#9E1B1E", textAlign:"right"}}>Read More <img src={arroww} alt="" width="8%"/></h5></Link>
             <hr style={{ color:"#9E1B1E", margin:"0rem 0"}}/>
            
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
      <AllAcountsSection />
    </>
  );
};

export default Monetarypolicerate;
