import React,{useRef} from "react";
import { useEffect } from "react";
import Breadcrumb1 from "../../Components/Breadcrumb1/Breadcrumb1";
import AllAcountsSection from "../../Components/AllAccountsSection/AllAcountsSection";


// import BackgroundBrundcrumb from "../../assets/images/IZB/BackgroundBrundcrumb.png";
import {
  AiOutlineArrowLeft,
  AiOutlineArrowRight,
  AiOutlineShareAlt,
} from "react-icons/ai";

import { Link } from "react-router-dom";
import { CiClock1 } from "react-icons/ci";
import { BiMessageDetail } from "react-icons/bi";
const Memorandumofunderstanding = () => {
const BackgroundBrundcrumb = "https://techrowth.s3.eu-north-1.amazonaws.com/images/IZB/BackgroundBrundcrumb.png";
const storie1 = "https://techrowth.s3.eu-north-1.amazonaws.com/images/IZB/IZB+Website+Skin/IZB+Save+Plus+Account/storie1.png";
const storie2 = "https://techrowth.s3.eu-north-1.amazonaws.com/images/IZB/IZB+Website+Skin/IZB+Save+Plus+Account/storie2.png";
const storie3 = "https://techrowth.s3.eu-north-1.amazonaws.com/images/IZB/IZB+Website+Skin/IZB+Save+Plus+Account/storie3.png";
const storie4 = "https://techrowth.s3.eu-north-1.amazonaws.com/images/Thejas.png";
const storie5 = "https://techrowth.s3.eu-north-1.amazonaws.com/images/Thejas_2.png";
const storie6 = "https://techrowth.s3.eu-north-1.amazonaws.com/images/Thejas_1.png";
const page1 = "https://techrowth.s3.eu-north-1.amazonaws.com/images/WhatsApp-Image-2023-02-23-at-4.23.12-PM.jpeg";
const arroww = "https://techrowth.s3.eu-north-1.amazonaws.com/images/chevron_right.svg";
const page2 ="https://techrowth.s3.eu-north-1.amazonaws.com/images/REGISTRATION-OF-VENDORSSUPPLIERS-2023--768x1086.jpg";
const page21 = "https://techrowth.s3.eu-north-1.amazonaws.com/images/REGISTRATION-OF-VENDORSSUPPLIERS-2023-p2-768x1086.jpg";
const page3 ="https://techrowth.s3.eu-north-1.amazonaws.com/images/Indo-Konkola-Copper-Mines-Partnership.jpg";
const page4 ="https://techrowth.s3.eu-north-1.amazonaws.com/images/Group-1502.png";


  const sliderRef = useRef(null);
  console.log(sliderRef.current);
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Breadcrumb1 Heading="Memorandum Of Understanding With Sarago Motors" Heading1 ="Indo Zambia Bank  |  June 15, 2022" img={BackgroundBrundcrumb} />
      <div className="text-center" style={{marginTop:"5rem",marginBottom:"5rem"}}>
        <img src={page4} alt="" className="mb-4"/>
       <p className="container mt-4" style={{fontSize:"20px",textAlign:"justify"}}>Indo Zambia bank Signs a Memorandum of Understanding with Sarago Motors for the Extension of Auto Finance loans to individuals and corporate customers such as private business houses, partnership firms and proprietorships.</p>

      </div>
      <section>
      <div className="container">
        <div className="row">
          <div className="container">
            <div className="heading ">
              <h2 className="text-left" style={{color:"#9E1B1E",fontWeight:"450"}}>More to Explore</h2>
             
            </div>
            <div
              className="py-4"
              style={{ float: "right"}}
            >
         
            </div>
          </div>

          {/* <Slider ref={sliderRef} {...settings}>  */}
          
          
          
        
       
          <div className="col-xl-4 col-lg-4">
            <div
              className="single-blog-style1 wow fadeInUp"
              data-wow-delay="00ms"
              data-wow-duration="1500ms"
            >
              <div className="img-holder">
                <div className="inner">
                  <img src={storie5} alt="" />
                  <a href="/Businessindabaexhibition">
                    <div className="overlay-icon"></div>
                  </a>
                </div>
              </div>
              <div className="text-holder">
                <h3 className="blog-title mt-4">
                  <a>Business Indaba Exhibition</a><br/>
                  <span style={{fontSize: "15px", color: "gray"}}>June 7, 2022</span>
                </h3>
                <p className="" style={{marginBottom:"1.6rem"}}>
                Business Indaba Exhibition
                </p>
                <Link
                to="/Businessindabaexhibition">
             <h5 style={{fontWeight:"500", color:"#9E1B1E", textAlign:"right"}}>Read More <img src={arroww} alt="" width="8%"/></h5></Link>
             <hr style={{ color:"#9E1B1E", margin:"0rem 0"}}/>
            
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-lg-4 h-100">
            <div
              className="single-blog-style1 wow fadeInUp"
              data-wow-delay="00ms"
              data-wow-duration="1500ms"
            >
              <div className="img-holder">
                <div className="inner">
                  <img src={storie6} alt="" />
                  <a href="/Europeanunionexhibition">
                    <div className="overlay-icon"></div>
                  </a>
                </div>
              </div>
              <div className="text-holder">
                <h3 className="blog-title mt-4">
                  <a>
                  European Union (EU) Exhibition
                  </a><br/>
                  <span style={{fontSize: "15px", color: "gray"}}>June 6, 2022</span>
                </h3>
                <p>
                European Union (EU) Exhibition
                </p><br/>
                <Link
                to="/Europeanunionexhibition">
             <h5 style={{fontWeight:"500", color:"#9E1B1E", textAlign:"right"}}>Read More <img src={arroww} alt="" width="8%"/></h5></Link>
             <hr style={{ color:"#9E1B1E", margin:"0rem 0"}}/>
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-lg-4">
            <div
              className="single-blog-style1 wow fadeInUp"
              data-wow-delay="00ms"
              data-wow-duration="1500ms"
            >
                   <a href="/Monetarypolicerate">
              <div className="img-holder">
                <div className="inner">
                  <img src={storie1} alt="" />
                
                    <div className="overlay-icon"></div>
                 
                </div>
              </div>
              </a>
              <div className="text-holder">
                <h3 className="blog-title my-4">
                  <a>Monetary policy rate</a><br/>
                  <span style={{fontSize: "15px", color: "gray"}}>February 24, 2023</span>
                </h3>
                <p className="" style={{marginBottom:"1.5rem", marginTop:"2rem"}}>
                  Share This Post Share on facebook Share on linkedin Share on
                  twitter Share onemail More To Explore Indo Zambia Bank, a
                  proud sponsor of... 
                </p><br/>
                <Link
                to="/Monetarypolicerate">
             <h5 style={{fontWeight:"500", color:"#9E1B1E", textAlign:"right"}}>Read More <img src={arroww} alt="" width="8%"/></h5></Link>
             <hr style={{ color:"#9E1B1E", margin:"0rem 0"}}/>
            
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
      <AllAcountsSection />
    </>
  );
};

export default Memorandumofunderstanding;
